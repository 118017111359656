import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { Link } from "react-router-dom";
import axios from "axios";
import { Base_url } from "../../utils/Base_url";

const HomeBanner = () => {
  const [sliders, setSliders] = useState([]);
  useEffect(() => {
    const fetchSlider = async () => {
      try {
        const response = await axios.get(`${Base_url}/slider/get`);

        console.log(response, "response");

        setSliders(response?.data?.data);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchSlider();
  }, []);

  return (
    <div className="bg-white">
      <Swiper
        key={sliders.length}
        modules={[Autoplay, Navigation, Pagination]}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
          pauseOnMouseEnter: false, // Prevents pausing when hovered
        }} // Proper autoplay config
        loop={true}
        pagination={{ clickable: true }}
        // navigation={true}
        className=" sm:h-[70vh] h-[40vh] w-full mx-auto"
      >
        {sliders?.map((item, index) => {
          return (
            <SwiperSlide>
              <Link
                to={`${item?.link}`}
                className=" w-full h-full flex  items-center justify-center   bg-cover bg-center bg-no-repeat"
                style={{ backgroundImage: `url(${item?.image})` }}
              >
                {item?.hasText === true ? (
                  <div className="sm:px-20 px-10 w-full max-w-screen-lg">
                    <div className="grid md:grid-cols-2 grid-cols-1 text-start">
                      <div className="text-black bg-white flex  justify-center mx-auto items-center rounded-full  w-[360px] h-[360px]">
                        <div className=" w-96 pl-14 mx-auto">
                          <h4 className="md:text-4xl text-3xl font-bold my-3 leading-snug">
                            {item?.title}
                          </h4>
                          <p className=" text-base">{item?.subTitle}</p>
                          <div className="mt-6">
                            <div className="py-2.5 px-6 bg-slate-900 text-white rounded-md inline-block font-semibold">
                              {item?.buttonName}
                            </div>
                          </div>
                        </div>
                        <div></div>
                      </div>
                    </div>
                  </div>
                ) : null}
              </Link>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};

export default HomeBanner;
