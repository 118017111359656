// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {
  getAuth,
  GoogleAuthProvider,
  FacebookAuthProvider,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyAWge_F7yNlbIHTxhyChX3jWPIKkqOMh-4",
  authDomain: "shop-zone-8f703.firebaseapp.com",
  projectId: "shop-zone-8f703",
  storageBucket: "shop-zone-8f703.firebasestorage.app",
  messagingSenderId: "980955415181",
  appId: "1:980955415181:web:0fd37930c28c614fb2d8c4",
  measurementId: "G-951SLBKXQE"
};




// Initialize Firebase
const app = initializeApp(firebaseConfig);

const auth = getAuth(app);
const provider = new GoogleAuthProvider();
const providerFacebook = new FacebookAuthProvider();

export { auth, provider, providerFacebook, RecaptchaVerifier, signInWithPhoneNumber };