import axios from "axios";
import React, { useEffect, useState } from "react";
import { Base_url } from "../../utils/Base_url";
import Footer from "../../components/footer";
import { Link } from "react-router-dom";
import Navbar from "../../components/Header/Navbar";
import BottomHeader from "../../components/Header/BottomHeader";
import BrandCard from "../../components/cards/BrandCard";

const Designers = () => {
  const [designers, setDesigners] = useState([]);

  useEffect(() => {
    axios
      .get(`${Base_url}/category/getCategoryBrand`)
      .then((res) => {
        console.log(res);
        
        setDesigners(res?.data?.data);
      })
      .catch((error) => {
        console.error("Error fetching designers:", error);
      });
  }, []);

  return (
    <>
      <Navbar />
      <BottomHeader />

      <div className="container mx-auto pt-1">
        <ul className=" flex gap-2 items-center">
          <li>
            <Link className=" text-secondary" to={"/"}>
              Home
            </Link>
          </li>
          /<li>Brands</li>
        </ul>
        <div>
          {designers?.map((category, index) => (
            <div key={index} className="mb-16">
              <h2 className="text-2xl text-center font-bold mb-8">{category?.name}</h2>
              {/* <hr /> */}
              <div className="grid grid-cols-3 text-center sm:grid-cols-4 md:grid-cols-6 lg:grid-cols-8 gap-5 my-4">
              {category?.brand?.map((brand, idx) => (

                              <Link   to={`/shop?product=undefined&limit=10&page=1&sortBy=&categoryIds=&subCategoryIds=&subSubCategoryIds=&brandIds=[${brand?.id}]&colorName=&minPrice=&maxPrice=&priceComparison=`}  className="  border bg-gray-50 hover:bg-gray-100 cursor-pointer rounded-[12px] flex justify-center items-center px-8 py-1 h-36">
                                {brand?.image ? (
                                  <img
                                    src={brand?.image}
                                    className="w-full h-full object-contain"
                                    alt=""
                                  />
                                ) : null}
                                <span>{brand?.name}</span>
                                 </Link>

                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Designers;
