import React, { useEffect, useState } from "react";
import Button from "../Button";
import { Link } from "react-router-dom";
import axios from "axios";
import { Base_url } from "../../utils/Base_url";

const Featureds = () => {
  const [allBlogCategory, setAllBlogCategory] = useState([]);

  useEffect(() => {
    const fetchSlider = async () => {
      try {
        const response = await axios.get(`${Base_url}/blogCategory/get`);

        console.log(response, "response");

        setAllBlogCategory(response?.data?.data);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchSlider();
  }, []);
  return (
    <div className=" container px-4 py-10 mx-auto">
      <div className=" flex items-center justify-between">
        <h4 className=" text-black font-medium text-xl">Blog Categories</h4>
      </div>

      <div className="grid mt-4 item1 col-span-2  xl:grid-cols-3  grid-cols-1 gap-5">
        {allBlogCategory?.map((item, index) => {
          return (
            <div className=" relative">
              <img src={item?.image} alt=""  className=" mx-auto" />

              <div className="  p-3 flex  flex-col justify-between items-center">
                <p className=" text-md text-center py-3">{item?.subTitle}</p>
                <Link to={`/sustainability/${item?.id}`}>
                  <Button
                    label={"Learn More"}
                    className={
                      " uppercase  text-black  text-sm font-bold py-2 hover:bg-[#272727] hover:text-white border-[#272727] border"
                    }
                  />
                </Link>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Featureds;
