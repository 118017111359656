import React from "react";
import { MdClose } from "react-icons/md";
import Modal from "../../components/modal";

const AfterPayModal = ({ isModalOpen, setIsModalOpen }) => {
  return (
    <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} className="max-w-xl">
      <div className="p-5">
        {/* Header Section */}
        <div className="flex justify-between items-center">
          <h2 className="text-lg font-semibold">Afterpay</h2>
          <MdClose className="cursor-pointer text-gray-600 hover:text-gray-900" size={25} onClick={() => setIsModalOpen(false)} />
        </div>
        <hr className="my-3" />

        {/* Content Section */}
        <div className="text-center">
          <h3 className="text-xl font-bold text-blue-600">Shop Now. Enjoy Now. Pay Over Time. Interest Free.</h3>
          <p className="mt-2 text-gray-600">In four simple payments available instantly at checkout.</p>
          <p className="mt-1 text-blue-500 font-medium">Available for orders under <span className="font-bold">$2000</span></p>
        </div>

        {/* Payment Details */}
        <div className="mt-4 space-y-4 text-center text-gray-700">
          <div>
            <h4 className="font-semibold">Select Afterpay as your payment method</h4>
            <p>Use your existing payment card</p>
          </div>

          <div>
            <h4 className="font-semibold">Complete your checkout in seconds</h4>
            <p>No long forms, instant approval online</p>
          </div>

          <div>
            <h4 className="font-semibold">Pay over 4 equal payments</h4>
            <p>Pay fortnightly, enjoy your purchase straight away!</p>
          </div>
        </div>

        {/* Requirements */}
        <div className="mt-5 bg-gray-100 p-4 rounded-lg text-gray-700">
          <h4 className="font-semibold">All you need is:</h4>
          <ul className="list-disc list-inside mt-2 text-sm">
            <li>An Australian payment card</li>
            <li>To be over 18 years of age</li>
            <li>To live in Australia</li>
          </ul>
        </div>

        {/* Terms & Conditions */}
        <div className="mt-5 text-center">
          <p className="text-sm text-gray-600">
            To see Afterpay’s complete terms, visit:
            <a href="https://www.afterpay.com.au/terms" className="text-blue-600 font-medium" target="_blank" rel="noopener noreferrer">
              {" "}afterpay.com.au/terms
            </a>
          </p>
        </div>
      </div>
    </Modal>
  );
};

export default AfterPayModal;
