import React from 'react'
import Faqs from '../../components/Faqs'

const PickUp = () => {
  return (
    <>
    <div className='   px-4 mx-auto'>

    <div className=''>
        <img src='https://s7ap1.scene7.com/is/image/bigw/Pickup_HB_210201?$cms-max-image-threshold$&fmt=webp-alpha&wid=1075&fit=hfit%2C1&scale=0.5&qlt=88' className=' w-full' alt='' />
    </div>

    <div>
        <h2 className=' text-center py-3 text-2xl'>Free In store Pick up - Service Desk</h2>
        <p>Order Pick ups are FREE and available across all our stores. Order before 12pm for In store Pick up after 4pm, or order after 12pm for next day collection. </p>

         <h3 className=' pt-2'>Just follow these 3 simple steps:</h3>
         
       <div className='  max-w-xl mt-6 gap-4 mx-auto flex flex-col'>
       <div className=' flex items-center rounded-md bg-[#F7F7F7] p-3 gap-3'>
            <div className='  w-28 h-28 '>
               <img src='https://s7ap1.scene7.com/is/image/bigw/W2534_ABT_Icons_230126_Order?$cms-max-image-threshold$&fmt=webp&wid=86&fit=hfit%2C1&scale=0.5&qlt=88' className=' w-full h-full'  alt='' />
            </div>
            <div>
                <p className=' font-medium'>1. Order Online and select Service Desk</p>
            </div>
         </div>
         <div className=' flex items-center rounded-md bg-[#F7F7F7] p-3 gap-2'>
         <div className='  w-28 h-28 '>
               <img src='https://s7ap1.scene7.com/is/image/bigw/Pick%20up%20-%20Message1x?$cms-max-image-threshold$&fmt=webp-alpha&wid=86&fit=hfit%2C1&scale=0.5&qlt=88'  className=' w-full h-full' alt='' />
            </div>
            <div>
                <p className=' font-medium'>2. Wait for an email/SMS notifying your order is ready for Pick up.</p>
            </div>
         </div>
         <div className=' flex items-center rounded-md bg-[#F7F7F7] p-3 gap-2'>
         <div className='  w-28 h-28 '>
               <img src='https://s7ap1.scene7.com/is/image/bigw/W2534_ABT_Icons_230126_PickUp_ServiceDesk?$cms-max-image-threshold$&fmt=webp&wid=86&fit=hfit%2C1&scale=0.5&qlt=88'  className=' w-full h-full' alt='' />
            </div>
            <div>
                <p className=' font-medium'>3. Pick up your order at the Service Desk.</p>
            </div>
         </div>
       </div>
    </div>
    <Faqs/>
    </div>
    
    
    </>
   
  )
}

export default PickUp