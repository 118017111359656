import React from "react";
import { Link, NavLink, Outlet } from "react-router-dom";
import Navbar from "../../components/Header/Navbar";
import Footer from "../../components/footer";
import BottomHeader from "../../components/Header/BottomHeader";

const DeliveryPickup = () => {

  
  return (
    <>
      <Navbar />
      <BottomHeader />

      <div className=" px-4 pt-2">
      Home / In store Pick up
      </div>

      <div className="flex flex-row container mx-auto py-10">
        
        <div className=" w-3/12 pb-8">
          <ul className="flex flex-col    justify-between w-full leading-8">

          {/* <NavLink
                to="#"

                 className={' text-xl underline text-blue-700 pb-3'}
                
              >
                Shopping made easier
              </NavLink> */}
         
            {/* <NavLink
                to="/delivery-pickup/same-day-delivery"
                className={({ isActive }) =>
                  isActive
                    ? "  py-2  hover:underline text-primary border-t   w-full"
                    : "  py-2  hover:underline  text-black  border-t  w-full"
                }
              >
                Same day delivery
              </NavLink> */}

              <NavLink
                to="/delivery-pickup/standard-delivery"
                className={({ isActive }) =>
                  isActive
                    ? "  py-2 hover:underline border-t   text-primary  w-full"
                    : "  py-2 hover:underline  border-t   text-black  w-full"
                }
              >
                Standard delivery
              </NavLink>

              
              <NavLink
                to="/delivery-pickup/returns"
                className={({ isActive }) =>
                  isActive
                    ? "  py-2 hover:underline text-primary border-t   w-full"
                    : "  py-2 hover:underline  text-black border-t    w-full"
                }
              >
                Returns
              </NavLink>
              {/* <NavLink
                to="/delivery-pickup/pick-up"
                className={({ isActive }) =>
                  isActive
                    ? "  py-2 hover:underline text-primary  border-t   w-full"
                    : "  py-2  hover:underline text-black  border-t  w-full"
                }
              >
                Direct to boot Pick up
              </NavLink> */}

<NavLink
                to="/delivery-pickup/pick-up"
                className={({ isActive }) =>
                  isActive
                    ? "  py-2 hover:underline  text-primary border-t  border-b  w-full"
                    : "  py-2  hover:underline  text-black  border-t  border-b w-full"
                }
              >
                Pick up
              </NavLink>
             
              <NavLink
                to="/delivery-pickup/pick-up"
                className={({ isActive }) =>
                  isActive
                    ? "  py-2 hover:underline  text-primary border-t  border-b  w-full"
                    : "  py-2  hover:underline  text-black  border-t  border-b w-full"
                }
              >
                Delivery offers
              </NavLink>
             
          </ul>
        </div>
        <div className="  w-9/12 px-4">
          <Outlet />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default DeliveryPickup;
