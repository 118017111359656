import React, { useEffect, useState } from "react";
import { FaAngleRight, FaHeart, FaRegHeart } from "react-icons/fa";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import ProductSlider from "../../components/sliders/productSlider";
import Product from "../../components/cards/Product";
import Accordion from "../../components/Accordion/Accordion";
import Button from "../../components/Button";
import Input from "../../components/Input";
import { FiSearch } from "react-icons/fi";
import { MdClose } from "react-icons/md";
import axios from "axios";
import CategoryCard from "../../components/cards/CategoryCard";
import Navbar from "../../components/Header/Navbar";
import Footer from "../../components/footer";
import { Base_url } from "../../utils/Base_url";
import BottomHeader from "../../components/Header/BottomHeader";

const AllNewArrivals = () => {
  const { id } = useParams();
  const [allCategory, setAllCategory] = useState([]);
  const [brands, setBrands] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const page = queryParams.get("page");
  const sortBy = queryParams.get("sortBy");
  const brandIds = queryParams.get("brandIds");
  const categoryIds = queryParams.get("categoryIds");
  const subCategoryIds = queryParams.get("subCategoryIds");
  const subSubCategoryIds = queryParams.get("subSubCategoryIds");
  const colorName = queryParams.get("colorName");
  const minnPrice = queryParams.get("minPrice");
  const maxxPrice = queryParams.get("maxPrice");
  const priceComparison = queryParams.get("priceComparison");

  const [uniqueCategory, setUniqueCategory] = useState([]);
  const [selCategory, setSelCategory] = useState(
    categoryIds ? categoryIds : []
  );
  const [toggleCategory, setToggleCategory] = useState(false);

  const [uniqueSubCategory, setUniqueSubCategory] = useState([]);
  const [selSubCategory, setSelSubCategory] = useState(
    subCategoryIds ? subCategoryIds : []
  );
  const [toggleSubCategory, setToggleSubCategory] = useState(false);

  const [uniqueSubSubCategory, setUniqueSubSubCategory] = useState([]);
  const [selSubSubCategory, setSelSubSubCategory] = useState(
    subSubCategoryIds ? subSubCategoryIds : []
  );
  const [toggleSubSubCategory, setToggleSubSubCategory] = useState(false);

  const [uniqueBrand, setUniqueBrands] = useState([]);
  const [selBrand, setSelBrand] = useState(brandIds ? brandIds : []);
  const [toggleBrand, setToggleBrand] = useState(false);

  const [uniqueColor, setUniqueColors] = useState([]);
  const [selColor, setSelColor] = useState(colorName ? colorName : []);
  const [toggleColor, setToggleColor] = useState(false);

  const [minPrice, setMinPrice] = useState(minnPrice ? minnPrice : "");
  const [maxPrice, setMaxPrice] = useState(maxxPrice ? maxxPrice : "");
  const [minGPrice, setMinGPrice] = useState(0);
  const [maxGPrice, setMaxGPrice] = useState(0);
  const [togglePrice, setTogglePrice] = useState(false);
  const [allProduct, setProducts] = useState([]);
  const [getCategory, setGetCategory] = useState({});
  const [getData, setGetData] = useState({});

  const [currentPage, setCurrentPage] = useState(page ? page : 1);
  const [totalPages, setTotalPages] = useState(1);
  const [sorted, setSorted] = useState(sortBy ? sortBy : "");

  const getProData = async (query) => {
    axios
      .get(`${Base_url}/product/getNewArrival${query}`)
      .then((res) => {
        console.log(res?.data.uniqueBrands, "==============>>>>>>>>>sub");
        navigate(query);
        setBrands(res?.data?.uniqueBrands);
        setMinGPrice(res?.data?.finalMinPrice);
        setMaxGPrice(res?.data?.finalMaxPrice);
        setUniqueColors(res?.data?.uniqueColors);
        setUniqueBrands(res?.data?.uniqueBrands);
        setUniqueCategory(res?.data?.uniqueCategories);
        setUniqueSubCategory(res?.data?.uniqueSubCategories);
        setUniqueSubSubCategory(res?.data?.uniqueSubSubCategories);
        setAllCategory(res?.data?.uniqueSubCategories);
        setProducts(res?.data?.data);
        setGetData(res?.data);
        setTotalPages(res?.data?.totalPages);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    getProData(
      `?product=${id}&limit=${10}&page=${currentPage}&sortBy=${sorted}&brandIds=${
        selBrand.length > 0
          ? selBrand[0] === "["
            ? selBrand
            : JSON.stringify(selBrand)
          : ""
      }&categoryIds=${
        selCategory.length > 0
          ? selCategory[0] === "["
            ? selCategory
            : JSON.stringify(selCategory)
          : ""
      }&subCategoryIds=${
        selSubCategory?.length > 0
          ? selSubCategory[0] === "["
            ? selSubCategory
            : JSON.stringify(selSubCategory)
          : ""
      }&subSubCategoryIds=${
        selSubSubCategory?.length > 0
          ? selSubSubCategory[0] === "["
            ? selSubSubCategory
            : JSON.stringify(selSubSubCategory)
          : ""
      }&colorName=${
        selColor.length > 0
          ? selColor[0] === "["
            ? selColor
            : JSON.stringify(selColor)
          : ""
      }&minPrice=${minPrice ? minPrice : ""}&maxPrice=${
        maxPrice ? maxPrice : ""
      }&priceComparison=${priceComparison ? priceComparison : ""}`
    );

    axios
      .get(`${Base_url}/category/get/${id}`)
      .then((res) => {
        console.log(res, "=====dffd=========>>>>>>>>>sub");

        setGetCategory(res?.data?.data);
      })
      .catch((error) => {});
  }, []);

  console.log(page, "pppppppppppppppp");

  const handlePageChange = (newpage) => {
    console.log(newpage);

    if (newpage >= 1 && newpage <= totalPages) {
      setCurrentPage(newpage);
      getProData(
        `?product=${id}&limit=${10}&page=${newpage}&sortBy=${sorted}&brandIds=${
          selBrand.length > 0
            ? selBrand[0] === "["
              ? selBrand
              : JSON.stringify(selBrand)
            : ""
        }&categoryIds=${
          selCategory.length > 0
            ? selCategory[0] === "["
              ? selCategory
              : JSON.stringify(selCategory)
            : ""
        }&subCategoryIds=${
          selSubCategory?.length > 0
            ? selSubCategory[0] === "["
              ? selSubCategory
              : JSON.stringify(selSubCategory)
            : ""
        }&subSubCategoryIds=${
          selSubSubCategory?.length > 0
            ? selSubSubCategory[0] === "["
              ? selSubSubCategory
              : JSON.stringify(selSubSubCategory)
            : ""
        }&colorName=${
          selColor.length > 0
            ? selColor[0] === "["
              ? selColor
              : JSON.stringify(selColor)
            : ""
        }&minPrice=${minPrice ? minPrice : ""}&maxPrice=${
          maxPrice ? maxPrice : ""
        }&priceComparison=${priceComparison ? priceComparison : ""}`
      );
    }
  };

  const [isMenuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  return (
    <>
      <Navbar />
      <BottomHeader />

    
      <div className=" container mx-auto ">
       
        <div className=" container  md:px-5  px-3 mx-auto pb-10 pt-3">
          <div className=" bg-white  py-3 z-40 sticky top-0 flex justify-between items-center">
            <div>
              <h6 className="h6">
                {} listings {getData?.count} items
              </h6>

              {getData?.filtercount > 0 ? (
                <button
                  onClick={() => {
                    navigate(`/categories/${id}`);
                    window.location.reload();
                  }}
                >
                  Clear All
                </button>
              ) : null}
            </div>

            <div className=" flex items-center gap-2">
              <div className=" md:block hidden ">
                <select
                  className="  border py-1  bg-lightGray  uppercase  text-primary placeholder:text-primary "
                  onChange={(e) => {
                    setSorted(e.target.value);

                    setCurrentPage(1);

                    getProData(
                      `?product=${id}&limit=${10}&page=${currentPage}&sortBy=${
                        e.target.value
                      }&brandIds=${
                        selBrand.length > 0
                          ? selBrand[0] === "["
                            ? selBrand
                            : JSON.stringify(selBrand)
                          : ""
                      }&categoryIds=${
                        selCategory.length > 0
                          ? selCategory[0] === "["
                            ? selCategory
                            : JSON.stringify(selCategory)
                          : ""
                      }&subCategoryIds=${
                        selSubCategory?.length > 0
                          ? selSubCategory[0] === "["
                            ? selSubCategory
                            : JSON.stringify(selSubCategory)
                          : ""
                      }&subSubCategoryIds=${
                        selSubSubCategory?.length > 0
                          ? selSubSubCategory[0] === "["
                            ? selSubSubCategory
                            : JSON.stringify(selSubSubCategory)
                          : ""
                      }&colorName=${
                        selColor.length > 0
                          ? selColor[0] === "["
                            ? selColor
                            : JSON.stringify(selColor)
                          : ""
                      }&minPrice=${minPrice ? minPrice : ""}&maxPrice=${
                        maxPrice ? maxPrice : ""
                      }&priceComparison=${
                        priceComparison ? priceComparison : ""
                      }`
                    );
                  }}
                >
                  <option className="">Sort by: default</option>
                  <option value={"A-Z"}>Name (A-Z)</option>
                  <option value={"Z-A"}>Name (Z-A)</option>
                  <option value={"high-to-low"}>$ High - Low</option>
                  <option value={"low-to-high"}>$ Low - High</option>
                </select>
              </div>

              <Button
                label={"filter"}
                onClick={toggleMenu}
                className={
                  "bg-black  block md:hidden uppercase text-xs py-1 font-bold  text-white"
                }
              />
            </div>
          </div>

          <div className=" flex  pt-4 gap-12">
            <div
              className={` sm:w-3/12 w-8/12   bg-white ${
                isMenuOpen
                  ? "block  fixed  text-center lg:p-5 p-0 top-0 right-0  z-50 left-0 w-[60%] h-full bg-white"
                  : "w-[20%] hidden lg:block  bg-white"
              }`}
            >
              <div className=" p-2 border-t lg:hidden  border-b flex justify-between  items-center">
                <MdClose
                  size={20}
                  className=" cursor-pointer"
                  onClick={() => setMenuOpen(false)}
                />
                <p className="h5">filter</p>
                <span className=" text-gray-500 border-b">Clear All</span>
              </div>
              <h1 className=" text-black  hidden md:block text-2xl pb-4">
                Filter <small>({getData?.filtercount})</small>
              </h1>
              <div className="h-full pb-12  sm:overflow-y-hidden overflow-y-scroll">
                {/*   Brand */}
                {uniqueBrand.length > 0 ? (
                  <div className=" mx-auto ">
                    <Accordion
                      title="Brand"
                      isOpen={toggleBrand}
                      toggleAccordion={() => {
                        setToggleBrand(!toggleBrand);
                      }}
                    >
                      <div className=" text-center  px-4">
                        {uniqueBrand?.map((item, index) => {
                          return (
                            <>
                              <div
                                key={index}
                                className=" flex gap-1 items-center"
                              >
                                <Input
                                  checked={selBrand.includes(item.id)}
                                  onChange={() => {
                                    if (selBrand.includes(item.id)) {
                                      const selsu =
                                        selBrand[0] === "["
                                          ? JSON.parse(selBrand)
                                          : selBrand;

                                      const getFilter = selsu?.filter(
                                        (i) =>
                                          i?.toString() !== item.id?.toString()
                                      );

                                      setCurrentPage(1);

                                      getProData(
                                        `?product=${id}&limit=${10}&page=${1}&sortBy=${sorted}&categoryIds=${
                                          selCategory.length > 0
                                            ? selCategory[0] === "["
                                              ? selCategory
                                              : JSON.stringify(selCategory)
                                            : ""
                                        }&subCategoryIds=${
                                          selSubCategory.length > 0
                                            ? selSubCategory[0] === "["
                                              ? selSubCategory
                                              : JSON.stringify(selSubCategory)
                                            : ""
                                        }&subSubCategoryIds=${
                                          selSubSubCategory?.length > 0
                                            ? selSubSubCategory[0] === "["
                                              ? selSubSubCategory
                                              : JSON.stringify(
                                                  selSubSubCategory
                                                )
                                            : ""
                                        }&brandIds=${
                                          getFilter?.length > 0
                                            ? JSON.stringify(getFilter)
                                            : ""
                                        }&colorName=${
                                          selColor.length > 0
                                            ? selColor[0] === "["
                                              ? selColor
                                              : JSON.stringify(selColor)
                                            : ""
                                        }&minPrice=${
                                          minPrice ? minPrice : ""
                                        }&maxPrice=${
                                          maxPrice ? maxPrice : ""
                                        }&priceComparison=${
                                          priceComparison ? priceComparison : ""
                                        }`
                                      );

                                      setSelBrand(getFilter);
                                    } else {
                                      const getAll = [...selBrand, item.id];

                                      setCurrentPage(1);

                                      getProData(
                                        `?product=${id}&limit=${10}&page=${1}&sortBy=${sorted}&categoryIds=${
                                          selCategory.length > 0
                                            ? selCategory[0] === "["
                                              ? selCategory
                                              : JSON.stringify(selCategory)
                                            : ""
                                        }&subCategoryIds=${
                                          selSubCategory.length > 0
                                            ? selSubCategory[0] === "["
                                              ? selSubCategory
                                              : JSON.stringify(selSubCategory)
                                            : ""
                                        }&subSubCategoryIds=${
                                          selSubSubCategory?.length > 0
                                            ? selSubSubCategory[0] === "["
                                              ? selSubSubCategory
                                              : JSON.stringify(
                                                  selSubSubCategory
                                                )
                                            : ""
                                        }&brandIds=${
                                          getAll?.length > 0
                                            ? JSON.stringify(getAll)
                                            : ""
                                        }&colorName=${
                                          selColor.length > 0
                                            ? selColor[0] === "["
                                              ? selColor
                                              : JSON.stringify(selColor)
                                            : ""
                                        }&minPrice=${
                                          minPrice ? minPrice : ""
                                        }&maxPrice=${
                                          maxPrice ? maxPrice : ""
                                        }&priceComparison=${
                                          priceComparison ? priceComparison : ""
                                        }`
                                      );

                                      setSelBrand(getAll);
                                    }
                                  }}
                                  type={"checkbox"}
                                  className={""}
                                />
                                <span className=" text-sm">{item?.name}</span>
                              </div>
                            </>
                          );
                        })}
                      </div>
                    </Accordion>
                  </div>
                ) : null}

                {/*   category */}
                {uniqueCategory.length > 0 ? (
                  <div className=" mx-auto ">
                    <Accordion
                      title="Category"
                      isOpen={toggleCategory}
                      toggleAccordion={() => {
                        setToggleCategory(!toggleCategory);
                      }}
                    >
                      <div className=" text-center  px-4">
                        {uniqueCategory?.map((item, index) => {
                          return (
                            <>
                              <div
                                key={index}
                                className=" flex gap-1 items-center"
                              >
                                <Input
                                  checked={selCategory.includes(item.id)}
                                  onChange={() => {
                                    if (selCategory.includes(item.id)) {
                                      const selsu =
                                        selCategory[0] === "["
                                          ? JSON.parse(selCategory)
                                          : selCategory;

                                      const getFilter = selsu?.filter(
                                        (i) =>
                                          i?.toString() !== item.id?.toString()
                                      );

                                      setCurrentPage(1);

                                      getProData(
                                        `?product=${id}&limit=${10}&page=${1}&sortBy=${sorted}&brandIds=${
                                          selBrand.length > 0
                                            ? selBrand[0] === "["
                                              ? selBrand
                                              : JSON.stringify(selBrand)
                                            : ""
                                        }&subCategoryIds=${
                                          selSubCategory.length > 0
                                            ? selSubCategory[0] === "["
                                              ? selSubCategory
                                              : JSON.stringify(selSubCategory)
                                            : ""
                                        }&subSubCategoryIds=${
                                          selSubSubCategory?.length > 0
                                            ? selSubSubCategory[0] === "["
                                              ? selSubSubCategory
                                              : JSON.stringify(
                                                  selSubSubCategory
                                                )
                                            : ""
                                        }&categoryIds=${
                                          getFilter?.length > 0
                                            ? JSON.stringify(getFilter)
                                            : ""
                                        }&colorName=${
                                          selColor.length > 0
                                            ? selColor[0] === "["
                                              ? selColor
                                              : JSON.stringify(selColor)
                                            : ""
                                        }&minPrice=${
                                          minPrice ? minPrice : ""
                                        }&maxPrice=${
                                          maxPrice ? maxPrice : ""
                                        }&priceComparison=${
                                          priceComparison ? priceComparison : ""
                                        }`
                                      );

                                      setSelCategory(getFilter);
                                    } else {
                                      const getAll = [...selCategory, item.id];

                                      setCurrentPage(1);

                                      getProData(
                                        `?product=${id}&limit=${10}&page=${1}&sortBy=${sorted}&brandIds=${
                                          selBrand.length > 0
                                            ? selBrand[0] === "["
                                              ? selBrand
                                              : JSON.stringify(selBrand)
                                            : ""
                                        }&subCategoryIds=${
                                          selSubCategory.length > 0
                                            ? selSubCategory[0] === "["
                                              ? selSubCategory
                                              : JSON.stringify(selSubCategory)
                                            : ""
                                        }&subSubCategoryIds=${
                                          selSubSubCategory?.length > 0
                                            ? selSubSubCategory[0] === "["
                                              ? selSubSubCategory
                                              : JSON.stringify(
                                                  selSubSubCategory
                                                )
                                            : ""
                                        }&categoryIds=${
                                          getAll?.length > 0
                                            ? JSON.stringify(getAll)
                                            : ""
                                        }&colorName=${
                                          selColor.length > 0
                                            ? selColor[0] === "["
                                              ? selColor
                                              : JSON.stringify(selColor)
                                            : ""
                                        }&minPrice=${
                                          minPrice ? minPrice : ""
                                        }&maxPrice=${
                                          maxPrice ? maxPrice : ""
                                        }&priceComparison=${
                                          priceComparison ? priceComparison : ""
                                        }`
                                      );

                                      setSelCategory(getAll);
                                    }
                                  }}
                                  type={"checkbox"}
                                  className={""}
                                />
                                <span className=" text-sm">{item?.name}</span>
                              </div>
                            </>
                          );
                        })}
                      </div>
                    </Accordion>
                  </div>
                ) : null}

                {/*  sub category */}
                {uniqueSubCategory.length > 0 ? (
                  <div className=" mx-auto ">
                    <Accordion
                      title="SubCategory"
                      isOpen={toggleSubCategory}
                      toggleAccordion={() => {
                        setToggleSubCategory(!toggleSubCategory);
                      }}
                    >
                      <div className=" text-center  px-4">
                        {uniqueSubCategory?.map((item, index) => {
                          return (
                            <>
                              <div
                                key={index}
                                className=" flex gap-1 items-center"
                              >
                                <Input
                                  checked={selSubCategory.includes(item.id)}
                                  onChange={() => {
                                    if (selSubCategory.includes(item.id)) {
                                      const selsu =
                                        selSubCategory[0] === "["
                                          ? JSON.parse(selSubCategory)
                                          : selSubCategory;

                                      const getFilter = selsu?.filter(
                                        (i) =>
                                          i?.toString() !== item.id?.toString()
                                      );

                                      setCurrentPage(1);

                                      getProData(
                                        `?product=${id}&limit=${10}&page=${1}&sortBy=${sorted}&brandIds=${
                                          selBrand.length > 0
                                            ? selBrand[0] === "["
                                              ? selBrand
                                              : JSON.stringify(selBrand)
                                            : ""
                                        }&categoryIds=${
                                          selCategory.length > 0
                                            ? selCategory[0] === "["
                                              ? selCategory
                                              : JSON.stringify(selCategory)
                                            : ""
                                        }&subSubCategoryIds=${
                                          selSubSubCategory?.length > 0
                                            ? selSubSubCategory[0] === "["
                                              ? selSubSubCategory
                                              : JSON.stringify(
                                                  selSubSubCategory
                                                )
                                            : ""
                                        }&subCategoryIds=${
                                          getFilter?.length > 0
                                            ? JSON.stringify(getFilter)
                                            : ""
                                        }&colorName=${
                                          selColor.length > 0
                                            ? selColor[0] === "["
                                              ? selColor
                                              : JSON.stringify(selColor)
                                            : ""
                                        }&minPrice=${
                                          minPrice ? minPrice : ""
                                        }&maxPrice=${
                                          maxPrice ? maxPrice : ""
                                        }&priceComparison=${
                                          priceComparison ? priceComparison : ""
                                        }`
                                      );

                                      setSelSubCategory(getFilter);
                                    } else {
                                      const getAll = [
                                        ...selSubCategory,
                                        item.id,
                                      ];

                                      setCurrentPage(1);

                                      getProData(
                                        `?product=${id}&limit=${10}&page=${1}&sortBy=${sorted}&brandIds=${
                                          selBrand.length > 0
                                            ? selBrand[0] === "["
                                              ? selBrand
                                              : JSON.stringify(selBrand)
                                            : ""
                                        }&categoryIds=${
                                          selCategory.length > 0
                                            ? selCategory[0] === "["
                                              ? selCategory
                                              : JSON.stringify(selCategory)
                                            : ""
                                        }&subSubCategoryIds=${
                                          selSubSubCategory?.length > 0
                                            ? selSubSubCategory[0] === "["
                                              ? selSubSubCategory
                                              : JSON.stringify(
                                                  selSubSubCategory
                                                )
                                            : ""
                                        }&subCategoryIds=${
                                          getAll?.length > 0
                                            ? JSON.stringify(getAll)
                                            : ""
                                        }&colorName=${
                                          selColor.length > 0
                                            ? selColor[0] === "["
                                              ? selColor
                                              : JSON.stringify(selColor)
                                            : ""
                                        }&minPrice=${
                                          minPrice ? minPrice : ""
                                        }&maxPrice=${
                                          maxPrice ? maxPrice : ""
                                        }&priceComparison=${
                                          priceComparison ? priceComparison : ""
                                        }`
                                      );

                                      setSelSubCategory(getAll);
                                    }
                                  }}
                                  type={"checkbox"}
                                  className={""}
                                />
                                <span className=" text-sm">{item?.name}</span>
                              </div>
                            </>
                          );
                        })}
                      </div>
                    </Accordion>
                  </div>
                ) : null}

                {/*  sub sub category */}
                {uniqueSubSubCategory.length > 0 ? (
                  <div className=" mx-auto ">
                    <Accordion
                      title="Sub Sub Category"
                      isOpen={toggleSubSubCategory}
                      toggleAccordion={() => {
                        setToggleSubSubCategory(!toggleSubSubCategory);
                      }}
                    >
                      <div className=" text-center  px-4">
                        {uniqueSubSubCategory?.map((item, index) => {
                          return (
                            <>
                              <div
                                key={index}
                                className=" flex gap-1 items-center"
                              >
                                <Input
                                  checked={selSubSubCategory.includes(item.id)}
                                  onChange={() => {
                                    // console.log(JSON.parse(selSubSubCategory));

                                    if (selSubSubCategory.includes(item.id)) {
                                      const selsu =
                                        selSubSubCategory[0] === "["
                                          ? JSON.parse(selSubSubCategory)
                                          : selSubSubCategory;

                                      const getFilter = selsu?.filter(
                                        (i) =>
                                          i?.toString() !== item.id?.toString()
                                      );

                                      setCurrentPage(1);

                                      getProData(
                                        `?product=${id}&limit=${10}&page=${1}&sortBy=${sorted}&brandIds=${
                                          selBrand.length > 0
                                            ? selBrand[0] === "["
                                              ? selBrand
                                              : JSON.stringify(selBrand)
                                            : ""
                                        }&categoryIds=${
                                          selCategory.length > 0
                                            ? selCategory[0] === "["
                                              ? selCategory
                                              : JSON.stringify(selCategory)
                                            : ""
                                        }&subCategoryIds=${
                                          selSubCategory?.length > 0
                                            ? selSubCategory[0] === "["
                                              ? selSubCategory
                                              : JSON.stringify(selSubCategory)
                                            : ""
                                        }&subSubCategoryIds=${
                                          getFilter?.length > 0
                                            ? JSON.stringify(getFilter)
                                            : ""
                                        }&colorName=${
                                          selColor.length > 0
                                            ? selColor[0] === "["
                                              ? selColor
                                              : JSON.stringify(selColor)
                                            : ""
                                        }&minPrice=${
                                          minPrice ? minPrice : ""
                                        }&maxPrice=${
                                          maxPrice ? maxPrice : ""
                                        }&priceComparison=${
                                          priceComparison ? priceComparison : ""
                                        }`
                                      );

                                      setSelSubSubCategory(getFilter);
                                    } else {
                                      const getAll = [
                                        ...selSubSubCategory,
                                        item.id,
                                      ];

                                      setCurrentPage(1);

                                      getProData(
                                        `?product=${id}&limit=${10}&page=${1}&sortBy=${sorted}&brandIds=${
                                          selBrand.length > 0
                                            ? selBrand[0] === "["
                                              ? selBrand
                                              : JSON.stringify(selBrand)
                                            : ""
                                        }&categoryIds=${
                                          selCategory.length > 0
                                            ? selCategory[0] === "["
                                              ? selCategory
                                              : JSON.stringify(selCategory)
                                            : ""
                                        }&subCategoryIds=${
                                          selSubCategory?.length > 0
                                            ? selSubCategory[0] === "["
                                              ? selSubCategory
                                              : JSON.stringify(selSubCategory)
                                            : ""
                                        }&subSubCategoryIds=${
                                          getAll?.length > 0
                                            ? JSON.stringify(getAll)
                                            : ""
                                        }&colorName=${
                                          selColor.length > 0
                                            ? selColor[0] === "["
                                              ? selColor
                                              : JSON.stringify(selColor)
                                            : ""
                                        }&minPrice=${
                                          minPrice ? minPrice : ""
                                        }&maxPrice=${
                                          maxPrice ? maxPrice : ""
                                        }&priceComparison=${
                                          priceComparison ? priceComparison : ""
                                        }`
                                      );

                                      setSelSubSubCategory(getAll);
                                    }
                                  }}
                                  type={"checkbox"}
                                  className={""}
                                />
                                <span className=" text-sm">{item?.name}</span>
                              </div>
                            </>
                          );
                        })}
                      </div>
                    </Accordion>
                  </div>
                ) : null}

                {/*  color */}
                {uniqueColor.length > 0 ? (
                  <div className=" mx-auto ">
                    <Accordion
                      title="Colors"
                      isOpen={toggleColor}
                      toggleAccordion={() => {
                        setToggleColor(!toggleColor);
                      }}
                    >
                      <div className=" text-center  px-4">
                        {uniqueColor?.map((item, index) => {
                          return (
                            <>
                              <div
                                key={index}
                                className=" flex gap-1 items-center"
                              >
                                <Input
                                  checked={selColor.includes(item.name)}
                                  onChange={() => {
                                    if (selColor.includes(item.name)) {
                                      const selsu =
                                        selColor[0] === "["
                                          ? JSON.parse(selColor)
                                          : selColor;

                                      const getFilter = selsu?.filter(
                                        (i) => i !== item.name
                                      );

                                      setCurrentPage(1);

                                      getProData(
                                        `?product=${id}&limit=${10}&page=${1}&sortBy=${sorted}&brandIds=${
                                          selBrand.length > 0
                                            ? selBrand[0] === "["
                                              ? selBrand
                                              : JSON.stringify(selBrand)
                                            : ""
                                        }&categoryIds=${
                                          selCategory.length > 0
                                            ? selCategory[0] === "["
                                              ? selCategory
                                              : JSON.stringify(selCategory)
                                            : ""
                                        }&subCategoryIds=${
                                          selSubCategory?.length > 0
                                            ? selSubCategory[0] === "["
                                              ? selSubCategory
                                              : JSON.stringify(selSubCategory)
                                            : ""
                                        }&subSubCategoryIds=${
                                          selSubSubCategory?.length > 0
                                            ? selSubSubCategory[0] === "["
                                              ? selSubSubCategory
                                              : JSON.stringify(
                                                  selSubSubCategory
                                                )
                                            : ""
                                        }&colorName=${
                                          getFilter.length > 0
                                            ? JSON.stringify(getFilter)
                                            : ""
                                        }&minPrice=${
                                          minPrice ? minPrice : ""
                                        }&maxPrice=${
                                          maxPrice ? maxPrice : ""
                                        }&priceComparison=${
                                          priceComparison ? priceComparison : ""
                                        }`
                                      );

                                      setSelColor(getFilter);
                                    } else {
                                      const getAll = [...selColor, item.name];

                                      console.log(getAll, "bbbbbbbbbbbbbbb");

                                      setCurrentPage(1);

                                      getProData(
                                        `?product=${id}&limit=${10}&page=${1}&sortBy=${sorted}&brandIds=${
                                          selBrand.length > 0
                                            ? selBrand[0] === "["
                                              ? selBrand
                                              : JSON.stringify(selBrand)
                                            : ""
                                        }&categoryIds=${
                                          selCategory.length > 0
                                            ? selCategory[0] === "["
                                              ? selCategory
                                              : JSON.stringify(selCategory)
                                            : ""
                                        }&subCategoryIds=${
                                          selSubCategory?.length > 0
                                            ? selSubCategory[0] === "["
                                              ? selSubCategory
                                              : JSON.stringify(selSubCategory)
                                            : ""
                                        }&subSubCategoryIds=${
                                          selSubSubCategory?.length > 0
                                            ? selSubSubCategory[0] === "["
                                              ? selSubSubCategory
                                              : JSON.stringify(
                                                  selSubSubCategory
                                                )
                                            : ""
                                        }&colorName=${
                                          getAll.length > 0
                                            ? JSON.stringify(getAll)
                                            : ""
                                        }&minPrice=${
                                          minPrice ? minPrice : ""
                                        }&maxPrice=${
                                          maxPrice ? maxPrice : ""
                                        }&priceComparison=${
                                          priceComparison ? priceComparison : ""
                                        }`
                                      );

                                      setSelColor(getAll);
                                    }
                                  }}
                                  type={"checkbox"}
                                  className={""}
                                />
                                <span className=" text-sm">{item?.name}</span>
                              </div>
                            </>
                          );
                        })}
                      </div>
                    </Accordion>
                  </div>
                ) : null}

                {/* Price Accordion */}
                <div className="mx-auto">
                  <Accordion
                    title="Price"
                    isOpen={togglePrice}
                    toggleAccordion={() => {
                      setTogglePrice(!togglePrice);
                    }}
                  >
                    <div className=" text-center justify-around  flex gap-5 px-4">
                      <div className=" flex gap-1 justify-center items-center">
                        <Input
                          type={"number"}
                          className={" w-20 border"}
                          placeholder={"$ Min"}
                          defaultValue={minPrice}
                          onChange={(e) => {
                            setMinPrice(e.target.value);

                            setCurrentPage(1);

                            getProData(
                              `?product=${id}&limit=${10}&page=${1}&sortBy=${sorted}&brandIds=${
                                selBrand.length > 0
                                  ? selBrand[0] === "["
                                    ? selBrand
                                    : JSON.stringify(selBrand)
                                  : ""
                              }&categoryIds=${
                                selCategory.length > 0
                                  ? selCategory[0] === "["
                                    ? selCategory
                                    : JSON.stringify(selCategory)
                                  : ""
                              }&subCategoryIds=${
                                selSubCategory?.length > 0
                                  ? selSubCategory[0] === "["
                                    ? selSubCategory
                                    : JSON.stringify(selSubCategory)
                                  : ""
                              }&subSubCategoryIds=${
                                selSubSubCategory?.length > 0
                                  ? selSubSubCategory[0] === "["
                                    ? selSubSubCategory
                                    : JSON.stringify(selSubSubCategory)
                                  : ""
                              }&colorName=${
                                selColor?.length > 0
                                  ? selColor[0] === "["
                                    ? selColor
                                    : JSON.stringify(selColor)
                                  : ""
                              }&minPrice=${e.target.value}&maxPrice=${
                                maxPrice ? maxPrice : ""
                              }&priceComparison=${
                                priceComparison ? priceComparison : ""
                              }`
                            );
                          }}
                        />
                      </div>

                      <div className=" flex gap-1 items-center">
                        <Input
                          type={"number"}
                          className={" w-20 border"}
                          placeholder={"$ Max"}
                          defaultValue={maxPrice}
                          onChange={(e) => {
                            setMaxPrice(e.target.value);

                            setCurrentPage(1);

                            getProData(
                              `?product=${id}&limit=${10}&page=${1}&sortBy=${sorted}&brandIds=${
                                selBrand.length > 0
                                  ? selBrand[0] === "["
                                    ? selBrand
                                    : JSON.stringify(selBrand)
                                  : ""
                              }&categoryIds=${
                                selCategory.length > 0
                                  ? selCategory[0] === "["
                                    ? selCategory
                                    : JSON.stringify(selCategory)
                                  : ""
                              }&subCategoryIds=${
                                selSubCategory?.length > 0
                                  ? selSubCategory[0] === "["
                                    ? selSubCategory
                                    : JSON.stringify(selSubCategory)
                                  : ""
                              }&subSubCategoryIds=${
                                selSubSubCategory?.length > 0
                                  ? selSubSubCategory[0] === "["
                                    ? selSubSubCategory
                                    : JSON.stringify(selSubSubCategory)
                                  : ""
                              }&colorName=${
                                selColor?.length > 0
                                  ? selColor[0] === "["
                                    ? selColor
                                    : JSON.stringify(selColor)
                                  : ""
                              }&minPrice=${minPrice ? minPrice : ""}&maxPrice=${
                                e.target.value
                              }&priceComparison=${
                                priceComparison ? priceComparison : ""
                              }`
                            );
                          }}
                        />
                      </div>
                    </div>
                    <p className="text-center">
                      You can find product price between {minGPrice} to{" "}
                      {maxGPrice}
                    </p>
                  </Accordion>
                </div>
              </div>
            </div>
            <div className=" sm:w-9/12 w-12/12">
              <div className="grid item1 mx-auto col-span-2  mt-3  grid-cols-1 sm:grid-cols-2  md:grid-cols-3 lg:grid-cols-3 gap-8">
                {allProduct?.map((item, index) => {
                  return (
                    <>
                      <Product item={item} />
                    </>
                  );
                })}
              </div>
            </div>
          </div>

          <div className="flex justify-end gap-2 items-center p-4">
            <button
              className="px-4 py-2 text-white bg-black rounded disabled:opacity-50"
              onClick={() => {
                handlePageChange(Number(currentPage) - 1);
              }}
              disabled={currentPage === 1}
            >
              Previous
            </button>
            <div className="flex items-center gap-4">
              <p className=" font-medium">
                Page {currentPage} of {totalPages}
              </p>
              <select
                className="px-2 py-1 border rounded"
                value={currentPage}
                onChange={(e) => handlePageChange(Number(e.target.value))}
              >
                {Array.from({ length: totalPages }, (_, index) => (
                  <option key={index + 1} value={index + 1}>
                    {index + 1}
                  </option>
                ))}
              </select>
            </div>
            <button
              className="px-4 py-2 text-white bg-black rounded disabled:opacity-50"
              onClick={() => {
                handlePageChange(Number(currentPage) + 1);
                console.log(page);
              }}
              disabled={currentPage === totalPages}
            >
              Next
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AllNewArrivals;
