import { Link } from "react-router-dom";

const CategoryCard = ({ url, image, name }) => {
  return (
    <Link
      to={url}
      className="flex flex-col   rounded-md items-center text-center "
    >
      {image?
      <div className="sm:w-44 w-36 sm:h-44 h-36 rounded-[12px] hover:shadow-lg transition-shadow duration-300  items-center justify-center">
        
        <img
        src={image}
        alt={name}
        className=" object-cover w-full  h-full rounded-[12px]"
      />
      </div>
      :null}
      <p className="pt-5 text-sm font-medium pb-2 text-gray-700">{name}</p>
    </Link>
  );
};

export default CategoryCard;
