import React, { useEffect, useState } from "react";
import Button from "../../../components/Button";
import axios from "axios";
import { Base_url } from "../../../utils/Base_url";
import AddAddress from "./AddAddress";
import Swal from "sweetalert2";
import EditAddress from "./EditAddress";

const Address = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [updateModalOpen, setUpdateModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [getData,setData] = useState({})
  const storedUser = localStorage.getItem("user_ID");

  // Open/Close Modal
  const openModal = () => {
    setIsModalOpen(true);
    setErrorMessage("");
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };



  const [address, setAddress] = useState([]);

  useEffect(() => {
    axios
      .get(`${Base_url}/address/user/${storedUser}`)
      .then((res) => {
        console.log(res, "address");

        setAddress(res?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);




  const removeFunction = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#A47ABF",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`${Base_url}/address/delete/${id}`)
          .then((res) => {
            if (res.status === 200) {
              Swal.fire(
                "Deleted!",
                "Your address has been deleted.",
                "success"
              );

              axios
              .get(`${Base_url}/address/user/${storedUser}`)
              .then((res) => {
                console.log(res, "address");
        
                setAddress(res?.data?.data);
              })
              .catch((error) => {
                console.log(error);
              });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });
  };


  return (
    <div>
       <AddAddress setAddress={setAddress} isModalOpen={isModalOpen}  setIsModalOpen={setIsModalOpen} />
       <EditAddress  setAddress={setAddress} getData={getData} isModalOpen={updateModalOpen}  setIsModalOpen={setUpdateModalOpen} />
      <div className="flex justify-between items-center">
        <div>
          <h3 className="h3">Address</h3>
        </div>
        <div>
          <Button
            onClick={openModal}
            label={"+ Add new address"}
            className="border border-black py-2 font-extrabold uppercase text-xs text-black bg-white"
          />
        </div>
      </div>

      <div className="flex md:flex-row flex-col w-full flex-wrap pt-4 gap-3">
        {address?.length > 0 ? (
          address?.map((item, index) => (
            <div
              key={index}
              className="     md:w-[28%] w-full h-52 border flex justify-between items-center border-t-4 border-black p-4 rounded shadow-md hover:shadow-lg transition duration-200"
            >
             <div className=" w-[45%]">
             <p className="font-medium text-gray-700">
                {`${item?.firstName } ${item?.lastName}`}
              </p>
              <p className="text-gray-700">
                {item?.businessName}
              </p>
              <p className="text-gray-700">
                {item?.country}
              </p>
              <p className="text-gray-700 text-sm">{item?.addressLine1?.slice(0,52)}...</p> 

              <h5 className=" underline">{item?.default===true?'Default Location':''}</h5>
              </div> 
              <div className=" flex flex-col gap-1 w-[50%]">
                <button onClick={()=>{setUpdateModalOpen(true)
                  setData(item)
                }} className=" border  border-black px-8 py-2  whitespace-nowrap shadow-lg font-semibold rounded-sm">Edit address</button>
                <button onClick={()=>removeFunction(item?.id)} className=" pt-4  font-medium underline">Remove address</button>
              </div>
             
            </div>
          ))
        ) : (
          <p className="text-gray-500">
            <p>You have no saved addresses.</p>
          </p>
        )}
      </div>

      
    </div>
  );
};

export default Address;
