import React, { useState, useEffect } from "react";
import axios from "axios";
import { Base_url } from "../../utils/Base_url";
const TopNav = () => {
  const [announcements, setAnnouncements] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    axios
      .get(`${Base_url}/announce/get`)
      .then((res) => {
        if (res?.data?.data?.length > 0) {
          setAnnouncements(res?.data?.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching announcements:", error);
      });
  }, []);

  useEffect(() => {
    if (announcements?.length === 0) return;

    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % announcements?.length);
    }, 3000);

    return () => clearInterval(interval);
  }, [announcements.length]);

  return (
    <div
      style={{ backgroundColor: announcements[currentIndex]?.color }}
      className={` py-2 transition-colors duration-500`}
    >
      <div className="text-center">
        {announcements.length > 0 ? (
          <>
            <h3 className="text-white text-sm">
              {announcements[currentIndex]?.title}
            </h3>
            <p className="text-white text-xs">
              {announcements[currentIndex]?.subTitle}
            </p>
          </>
        ) : (
          <p className="text-white text-xs">Loading announcements...</p>
        )}
      </div>
    </div>
  );
};

export default TopNav;
