// ProductSlider.js
import React, { useRef } from "react";
import "./ProductSlider.css";
import { LiaAngleLeftSolid, LiaAngleRightSolid } from "react-icons/lia";
import Product from "../../cards/Product";
const ProductSlider = ({ items, top }) => {
  const scrollContainerRef = useRef(null);

  const scrollLeft = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollLeft -= 200;
    }
  };

  const scrollRight = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollLeft += 200;
    }
  };

  return (
    <div className="relative container md:px-4 px-0 mx-auto">
      <div
        ref={scrollContainerRef}
        className="scroll-container  sm:pl-0 pl-8 items-center gap-4 flex productOverflow  overflow-x-auto whitespace-nowrap"
      >
        
        {items}
        
       
      </div>

     <div className=" md:block hidden">
     <button
        className={`arrow arrow-left absolute -left-4 rounded-full  flex   justify-center  items-center  top-${top?top:'28'} bg-white  hover:bg-gray-800 hover:shadow-lg w-14 h-14 hover:text-white`}
        onClick={scrollLeft}
      >
        <LiaAngleLeftSolid size={30} />
      </button>
      <button
        className={`arrow arrow-right absolute -right-4  flex  top-${top?top:'28'} rounded-full  bg-white  hover:bg-gray-800 hover:shadow-lg w-14 h-14 justify-center items-center hover:text-white`}
        onClick={scrollRight}
      >
           <LiaAngleRightSolid  size={30} className=""/>
      </button>
     </div>
    </div>
  );
};

export default ProductSlider;
