import { BsFillShieldLockFill, BsTelephoneFill } from "react-icons/bs";
import { CgSpinner } from "react-icons/cg";
import OtpInput from "otp-input-react";
import { useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import {
  RecaptchaVerifier,
  signInWithPhoneNumber,
  getAuth,
} from "firebase/auth";
import { auth } from "../../../utils/firebase.config";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const VerifyWithPhoneNumber = () => {
  const [otp, setOtp] = useState("");
  const [ph, setPh] = useState("");
  const [loading, setLoading] = useState(false);
  const [showOTP, setShowOTP] = useState(false);
  const [user, setUser] = useState(null);

  function initializeRecaptcha() {
    try {
      // Ensure we're using the auth instance from the config
      const authInstance = getAuth();
      
      // Destroy existing recaptcha verifier if it exists
      if (window.recaptchaVerifier) {
        window.recaptchaVerifier.clear();
      }

      console.log("Initializing reCAPTCHA...");
      window.recaptchaVerifier = new RecaptchaVerifier(
        authInstance,
        "recaptcha-container",
        {
          size: "invisible",
          callback: (response) => {
            console.log("reCAPTCHA solved.");
          },
          "expired-callback": () => {
            console.warn("reCAPTCHA expired. Please refresh the page.");
            toast.error("reCAPTCHA expired. Refresh the page.");
          },
        }
      );

      window.recaptchaVerifier.render().then((widgetId) => {
        window.recaptchaWidgetId = widgetId;
      });
    } catch (error) {
      console.error("Error initializing reCAPTCHA:", error);
      toast.error("Failed to initialize reCAPTCHA.");
    }
  }

  function onSignup() {
    setLoading(true);

    if (!ph) {
      toast.error("Please enter a valid phone number.");
      setLoading(false);
      return;
    }

    initializeRecaptcha();

    const appVerifier = window.recaptchaVerifier;
    const formattedPhone = "+" + ph;

    console.log("Sending OTP to:", formattedPhone);

    signInWithPhoneNumber(auth, formattedPhone, appVerifier)
      .then((confirmationResult) => {
        console.log("OTP sent successfully.");
        window.confirmationResult = confirmationResult;
        setLoading(false);
        setShowOTP(true);
        toast.success("OTP sent successfully!");
      })
      .catch((error) => {
        console.error("Failed to send OTP:", error);
        setLoading(false);
        toast.error(`Failed to send OTP: ${error.message}`);
      });
  }

  function onOTPVerify() {
    setLoading(true);

    if (!otp) {
      toast.error("Please enter the OTP.");
      setLoading(false);
      return;
    }

    window.confirmationResult
      .confirm(otp)
      .then((res) => {
        console.log("OTP verified successfully:", res);
        setUser(res.user);
        setLoading(false);
        toast.success("Phone number verified successfully!");
      })
      .catch((error) => {
        console.error("Invalid OTP:", error);
        setLoading(false);
        toast.error(`Invalid OTP: ${error.message}`);
      });
  }

  return (
    <section className="flex items-center justify-center h-screen bg-gray-100">
      <div>
        <div id="recaptcha-container"></div>
        {user ? (
          <h2 className="text-center text-green-600 font-medium text-2xl">
            👍 Login Success
          </h2>
        ) : (
          <div className="w-96 flex shadow-xl border flex-col gap-4 rounded-lg p-4 bg-white">
            {showOTP ? (
              <>
                <div className="bg-white text-emerald-500 w-fit mx-auto p-4 rounded-full">
                  <BsFillShieldLockFill size={30} />
                </div>
                <label
                  htmlFor="otp"
                  className="font-bold text-xl text-black text-center"
                >
                  Enter your OTP
                </label>
                <OtpInput
                  value={otp}
                  onChange={setOtp}
                  OTPLength={6}
                  otpType="number"
                  disabled={false}
                  autoFocus
                  className="opt-container"
                />
                <button
                  onClick={onOTPVerify}
                  className="bg-emerald-600 w-full flex gap-1 items-center justify-center py-2.5 text-white rounded hover:bg-emerald-700 transition-colors"
                >
                  {loading && (
                    <CgSpinner size={20} className="mt-1 animate-spin" />
                  )}
                  <span>Verify OTP</span>
                </button>
              </>
            ) : (
              <>
                <div className="bg-black text-white w-fit mx-auto p-4 rounded-full">
                  <BsTelephoneFill size={30} />
                </div>
                <label
                  htmlFor=""
                  className="font-bold text-xl text-black text-center"
                >
                  Verify your phone number
                </label>
                <PhoneInput
                  country={"pk"}
                  value={ph}
                  onChange={setPh}
                  inputClass="!w-full"
                />
                <button
                  onClick={onSignup}
                  className="bg-black w-full flex gap-1 items-center justify-center py-2.5 text-white rounded hover:bg-emerald-700 transition-colors"
                >
                  {loading && (
                    <CgSpinner size={20} className="mt-1 animate-spin" />
                  )}
                  <span>Send code via SMS</span>
                </button>
              </>
            )}
          </div>
        )}
        <ToastContainer />
      </div>
    </section>
  );
};

export default VerifyWithPhoneNumber;