import React, { useEffect, useRef, useState } from "react";
import Modal from "../../components/modal";
import { MdClose } from "react-icons/md";
import { Base_url } from "../../utils/Base_url";
import axios from "axios";
import { CiSearch } from "react-icons/ci";
import { IoMdClose } from "react-icons/io";
import { Link } from "react-router-dom";

const SearchProductModal = ({ isModalOpen, setIsModalOpen, closeModal }) => {
  const [filters, setFilters] = useState("");
  const [filterData, setFilterData] = useState([]);

  const handleSearchChange = async (e) => {
    const query = e.target.value;
    setFilters(query); // Update the input value
    if (query.trim() !== "") {
      try {
        const response = await axios.get(
          `${Base_url}/search/suggestion?query=${query}`
        );

        console.log(response);

        setFilterData(response?.data?.data || []); // Update state with the fetched data
      } catch (error) {
        console.error("Error fetching filters:", error);
      }
    } else {
      setFilterData([]); // Clear results when the input is empty
    }
  };

  const inputRef = useRef(null);

  useEffect(() => {
   
    inputRef.current?.focus();
  }, []);


  return (
    <div>
      <Modal
        isOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        className=" max-w-6xl w-full top-0"
      >
        <div>
          <div className=" p-3">
            <div className=" flex justify-between  w-full items-center">
              <div className=" w-11/12">
                <form className="h-11  rounded-md items-center flex  border">
                  <CiSearch className="mx-3" size={20} />
                  <input
                   ref={inputRef}
                    value={filters}
                    onChange={handleSearchChange}
                    className="w-full outline-none"
                    type="search"
                    placeholder="Search by name"
                  />
                  <button
                    type="button"
                    className="ml-auto text-white h-full bg-[#272727] px-4"
                  >
                    Search
                  </button>
                </form>
              </div>
              <div className=" cursor-pointer w-1/12">
                <IoMdClose
                  onClick={() => setIsModalOpen(false)}
                  className=" text-2xl ml-auto"
                />
              </div>
            </div>
            {/* Display search results */}
            <div className="mt-4 flex sm:flex-row flex-col  overflow-y-auto h-96">
              <div className=" w-4/12">
                <h5 className=" text-sm  font-medium pb-4">Suggestions</h5>
                <ul className="  flex flex-col gap-1">
                


{filterData?.category?.length > 0 && (
                    <>
                      <li className="pb-1 font-semibold">Categories</li>
                      {filterData.category.map((item, index) => (
                        <Link to={`/categories/${item?.id}?categoryId=1&limit=10&page=1&sortBy=&brandIds=&categoryIds=&subCategoryIds=&subSubCategoryIds=&colorName=&minPrice=&maxPrice=&priceComparison=&price=`} key={index}>
                          <h3 className="text-base">{item?.name}</h3>
                        </Link>
                      ))}
                    </>
                  )}
                </ul>

                <ul className="  flex flex-col gap-1 pt-2">
                
                  {filterData?.subCategory?.length > 0 && (
                    <>
                      <li className="pb-1 font-semibold"> Sub Categories</li>
                      {filterData.subCategory.map((item, index) => (
                        <Link to={`/sub_categories/${item?.id}?subCategoryId=1&limit=10&page=1&sortBy=&brandIds=&categoryIds=&subCategoryIds=&subSubCategoryIds=&colorName=&minPrice=&maxPrice=&priceComparison=&price=`} key={index}>
                          <h3 className="text-base">{item?.name}</h3>
                        </Link>
                      ))}
                    </>
                  )}
                  
                </ul>
                <ul className="  flex flex-col gap-1 pt-2">
               
{filterData?.subSubCategory?.length > 0 && (
                    <>
                      <li className="pb-1 font-semibold">Sub Sub Categories</li>
                      {filterData.subSubCategory.map((item, index) => (
                                               <Link to={`/sub_sub_categories/${item?.id}?subSubCategoryId=1&limit=10&page=1&sortBy=&brandIds=&categoryIds=&subCategoryIds=&subSubCategoryIds=&colorName=&minPrice=&maxPrice=&priceComparison=&price=`} key={index}>

                          <h3 className="text-base">{item?.name}</h3>
                        </Link>
                      ))}
                    </>
                  )}
                </ul>

                <ul className="  flex flex-col gap-1 pt-2">
                  {filterData?.brand?.length > 0 && (
                    <>
                      <li className="pb-1 font-semibold">Brand</li>
                      {filterData.brand.map((item, index) => (
               <Link  to={`/shop?product=undefined&limit=10&page=1&sortBy=&categoryIds=&subCategoryIds=&subSubCategoryIds=&brandIds=[${item?.id}]&colorName=&minPrice=&maxPrice=&priceComparison=`}>  <h3 className="text-base">{item?.name}</h3>
                        </Link>
                      ))}
                    </>
                  )}
                </ul>
              </div>

              <div className="sm:w-6/12 w-12/12 ">
  <h5 className="text-sm font-medium">Products</h5>
  <div className="grid sm:grid-cols-2  grid-cols-1">
    {filterData?.product && filterData.product.length > 0 ? (
      filterData.product.map((item, index) => (
        <Link
          to={`/product_details/${item?.id}`}
          key={index}
          className="p-2 border-b flex items-center gap-2"
        >
          <div>
            <div className="w-28 h-32">
              <img
                src={item?.Image1}
                className="object-contain"
                alt={item?.name || "Product image"}
              />
            </div>
          </div>
          <div>
            <h5 className="text-sm">{item?.name}</h5>
            <span>{item?.specialPrice?item?.specialPrice:item?.originalPrice}</span>
          </div>
        </Link>
      ))
    ) : (
      <div className="col-span-2 text-center  py-4 pt-11">
        <img src={require('../../assets/images/product-not-found.jpg')} className=" w-44 mx-auto" alt="" />
        {/* <p className="text-gray-500">No products found</p> */}
      </div>
    )}
  </div>
</div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default SearchProductModal;
