import React, { useEffect, useState } from "react";
import Input from "../../../components/Input";
import Option from "../../../components/Option";
import axios from "axios";
import { Base_url } from "../../../utils/Base_url";
import { toast } from "react-toastify";
import Button from "../../../components/Button";

const MyProfile = () => {
  const storedUser = localStorage.getItem("user_ID");
  const [user, setUser] = useState({});

  console.log(storedUser);
  

  useEffect(() => {
    axios
      .get(`${Base_url}/user/get/${storedUser}`)
      .then((res) => {
        setUser(res.data.data);
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
      });
  }, [storedUser]);

  const handleProfileUpdate = async (values) => {
    var params = {}
     if(values.firstName.value !==user.firstName){
       params.firstName=values.firstName.value
     }else if (values.lastName.value !==user.lastName){

       params.lastName=values.lastName.value

     }
     else if (values.username.value!==user.username){

      params.username=values.username.value

    }
    else if (values.phone.value!==user?.phone){

      params.phone=values.phone.value

    }else{
       
    }

    try {
      const res = await axios.put(
        `${Base_url}/user/update/${storedUser}`,
        params
      );

      if (res.status === 200) {
        toast.success("Profile updated successfully!");
      }
    } catch (error) {
      toast.error("Error updating profile:", error);
    }
  };

  return (
    <div className=" mx-auto p-6">
      <h1 className="text-2xl font-bold mb-6">My Account</h1>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleProfileUpdate(e.target);
        }}
        className="space-y-6"
      >
        <h4 className="text-xl font-semibold">Edit Profile</h4>

        <div className="flex flex-wrap gap-4">
          <div className="w-full md:w-1/3">
            <Input
              placeholder="First Name"
              name="firstName"
              label="First Name"
              className="border w-full"
              defaultValue={user?.firstName || ""}
            />
          </div>

          <div className="w-full md:w-1/3">
            <Input
              placeholder="Last Name"
              name="lastName"
              label="Last Name"
              className="border w-full"
              defaultValue={user?.lastName || ""}
            />
          </div>

          <div className="w-full md:w-1/3">
            <Input
              placeholder="Username"
              name="username"
              label="Username"
              className="border w-full"
              defaultValue={user?.username || ""}
            />
          </div>

          <div className="w-full md:w-1/3">
            <Input
              placeholder="Phone"
              name="phone"
              label="Phone"
              className="border w-full"
              defaultValue={user?.phone || ""}
            />
          </div>
          <div className="w-full md:w-1/3 flex justify-end">

          </div>
          <div className="w-full md:w-1/3 flex justify-end">
          <Button
            type="submit"
            label="Update Profile"
            className="bg-black text-white py-3"
          />
        </div>
          <div>
        
          </div>
        </div>

      
      </form>
    </div>
  );
};

export default MyProfile;
