import React, { useEffect, useState } from 'react'
import Faqs from '../../components/Faqs'
import axios from 'axios';
import { Base_url } from '../../utils/Base_url';
import { Link } from 'react-router-dom';

const StandardDelivery = () => {

  const [banner, setBanner] = useState([]);



  useEffect(() => {

    axios
      .get(`${Base_url}/banner/get?location=home`)
      .then((res) => {
        console.log(res?.data?.data, "==============>>>>>>>>>sub");

        setBanner(res?.data?.data);
      })
      .catch((error) => { });


  }, []);




  return (
    <>
      <div className='   px-4 mx-auto'>

        <div className=" container   mx-auto pb-10">
          <Link to={`${banner?.[0]?.link}`} className=" py-3  mx-auto">
            <img
              src={banner?.[0]?.image}
              className=" h-32 mx-auto w-full"
              alt=""
            />
          </Link>
          
        </div>

        <div>
          <h2 className=' text-center py-3 text-2xl'>Standard delivery</h2>
          <p>Our standard home delivery for most items* starts with a flat rate of $9 in metro areas, $12 in regional areas and $15 in remote areas.</p>

          <div className="p-6">
            <h2 className="text-2xl font-bold mb-4">Shipping Information</h2>
            <table className="min-w-full bg-white border border-gray-300">
              <thead>
                <tr className="bg-gray-100">
                  <th className="py-2 px-4 border-b border-gray-300 text-left">Area</th>
                  {/* <th className="py-2 px-4 border-b border-gray-300 text-left">
              Cost per Order*
            </th> */}
                  <th className="py-2 px-4 border-b border-gray-300 text-left">
                    Estimated Time Frame (Business Days)
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="hover:bg-gray-50">
                  <td className="py-2 px-4 border-b border-gray-300">Metro</td>
                  {/* <td className="py-2 px-4 border-b border-gray-300">$9</td> */}
                  <td className="py-2 px-4 border-b border-gray-300">2-4 business days</td>
                </tr>
                <tr className="hover:bg-gray-50">
                  <td className="py-2 px-4 border-b border-gray-300">Regional</td>
                  {/* <td className="py-2 px-4 border-b border-gray-300">$12</td> */}
                  <td className="py-2 px-4 border-b border-gray-300">5-9 business days</td>
                </tr>
                <tr className="hover:bg-gray-50">
                  <td className="py-2 px-4 border-b border-gray-300">Remote</td>
                  {/* <td className="py-2 px-4 border-b border-gray-300">$15</td> */}
                  <td className="py-2 px-4 border-b border-gray-300">7-10 business days</td>
                </tr>
              </tbody>
            </table>
          </div>

          <p>*Fragile and/or bulky items incur additional costs. Please see the below terms and conditions for delivery rates.

          </p>


        </div>
        <Faqs />
      </div>


    </>

  )
}

export default StandardDelivery