import axios from "axios";
import React, { useEffect, useState } from "react";
import { Base_url } from "../../utils/Base_url";
import { Link } from "react-router-dom";

const TopDeals = () => {
  // const deals = [
  //   {
  //     price: "$399",
  //     subtitle: "Save $300",
  //     title: "Dyson",
  //     description: "Dyson V8 Plus Handstick - Save over $300",
  //     image: "https://s7ap1.scene7.com/is/image/bigw/W2633-1912-Small-Appliances-Kitchenware-STSC20?$cms-max-image-threshold$&fmt=webp-alpha&wid=269&fit=hfit%2C1&scale=0.5&qlt=88",
  //   },
  //   {
  //     price: "Better than 1/2 Price",
  //     title: "Nespresso",
  //     description: "Nespresso Vertuo Pop Sale - Mini 99 Save $50",
  //     image: "https://s7ap1.scene7.com/is/image/bigw/W2633-1912-Small-Appliances-Kitchenware-STSC19?$cms-max-image-threshold$&fmt=webp-alpha&wid=269&fit=hfit%2C1&scale=0.5&qlt=88",
  //   },
  //   {
  //     price: "1/2 Price",
  //     title: "Philips",
  //     description: "Philips 1000 Series Airfryer XL Save 50%",
  //     image: "https://s7ap1.scene7.com/is/image/bigw/W2633%20-%201912%20Homepage_STSC-C1?$cms-max-image-threshold$&fmt=webp&wid=269&fit=hfit%2C1&scale=0.5&qlt=88",
  //   },

  //   {
  //     price: "1/2 Price",
  //     title: "Philips",
  //     description: "Philips 1000 Series Airfryer XL Save 50%",
  //     image: "https://s7ap1.scene7.com/is/image/bigw/W2633%20-%201912%20Homepage_STSC-C4?$cms-max-image-threshold$&fmt=webp&wid=269&fit=hfit%2C1&scale=0.5&qlt=88",
  //   },
  //   {
  //     price: "1/2 Price",
  //     title: "Philips",
  //     description: "Philips 1000 Series Airfryer XL Save 50%",
  //     image: "https://s7ap1.scene7.com/is/image/bigw/W2633---1912-Homepage_STSC-C6a?$cms-max-image-threshold$&fmt=webp-alpha&wid=269&fit=hfit%2C1&scale=0.5&qlt=88",
  //   },

  //   {
  //     price: "1/2 Price",
  //     title: "Philips",
  //     description: "Philips 1000 Series Airfryer XL Save 50%",
  //     image: "https://s7ap1.scene7.com/is/image/bigw/W2633---1912-Homepage_STSC-C6a?$cms-max-image-threshold$&fmt=webp-alpha&wid=269&fit=hfit%2C1&scale=0.5&qlt=88",
  //   },
  //   {
  //     price: "1/2 Price",
  //     title: "Philips",
  //     description: "Philips 1000 Series Airfryer XL Save 50%",
  //     image: "https://s7ap1.scene7.com/is/image/bigw/W2633-1912-Snacks-Drinks-STSC2?$cms-max-image-threshold$&fmt=webp-alpha&wid=269&fit=hfit%2C1&scale=0.5&qlt=88",
  //   },

  // ];

  const [deals, setDeals] = useState([]);

  useEffect(() => {
    const fetchDeals = async () => {
      try {
        const response = await axios.get(`${Base_url}/deal/get`);

        console.log(response, "response");

        setDeals(response?.data?.data);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchDeals();
  }, []);

  
 
  return (
    <>
      <div className="max-w-screen-xl mx-auto ">
        <div className="flex justify-between mt-7 px-3 items-center mb-5">
          <h2 className="text-3xl font-semibold text-gray-800">Top Deals</h2>
          <Link to="/deals" className="text-blue-600 hover:underline">
            Shop all Deals
          </Link>
        </div>
      </div>

      <div className="max-w-screen-xl mx-auto px-4 pb-8">
        <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-6">
          {deals.map((deal, index) => (
            <Link
              to={
                deal?.productId
                  ? `/product_details/${deal?.productId}`
                  : deal?.link
              }
              key={index}
              className="bg-white border  rounded-lg overflow-hidden"
            >
              <img
                src={deal.image}
                alt={deal.title}
                className="w-full object-cover"
              />
              <div className="p-4">
                <div className="flex justify-center items-center space-x-2 mb-2">
                  <h3 className="text-sm text-center font-semibold text-gray-800">
                    {deal.name}
                  </h3>
                </div>
                {/* <p className="text-sm text-gray-600 text-center">{deal.name}</p> */}
              </div>
            </Link>
          ))}
        </div>

        <div className="text-center mt-8">
          <Link
            to="/deals"
            className=" bg-primary text-white px-6 py-3 rounded hover:bg-primary"
          >
            Shop all Deals
          </Link>
        </div>
      </div>
    </>
  );
};

export default TopDeals;
