import React from "react";
import { MdClose } from "react-icons/md";
import Modal from "../../components/modal";

const ZipPayModal = ({ isModalOpen, setIsModalOpen }) => {
  return (
    <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} className="max-w-2xl">
      <div className="bg-white p-6 rounded-lg shadow-lg">
        {/* Header Section */}
        <div className="flex justify-between items-center">
          <h2 className="text-lg font-semibold text-purple-900">Zip - The better way to pay</h2>
          <MdClose className="cursor-pointer text-gray-600 hover:text-gray-900" size={25} onClick={() => setIsModalOpen(false)} />
        </div>
        <p className="text-gray-500 mt-1">Make it easier to pay for your purchases</p>
        <p className="text-yellow-500 font-semibold mt-1">⭐ 4.9 App store rating</p>
        <hr className="my-4" />

        {/* Zip Pay & Zip Money Sections */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {/* Zip Pay */}
          <div className="bg-purple-100 p-4 rounded-lg">
            <h3 className="text-lg font-bold text-purple-900">Zip Pay</h3>
            <ul className="mt-2 text-sm text-gray-700 space-y-1">
              <li>✔ Up to $1,000 credit limit</li>
              <li>✔ Pay nothing today</li>
              <li>✔ Always interest free*</li>
              <li>✔ Spread repayments over a month or longer</li>
              <li>✔ $9.95/month account fee or $0 if nothing owing</li>
            </ul>
            <button className="mt-3 bg-purple-600 text-white w-full py-2 rounded-lg hover:bg-purple-700 transition">
              Apply now
            </button>
          </div>

          {/* Zip Money */}
          <div className="bg-purple-100 p-4 rounded-lg">
            <h3 className="text-lg font-bold text-purple-900">Zip Money</h3>
            <ul className="mt-2 text-sm text-gray-700 space-y-1">
              <li>✔ Apply for $1,000 - $5,000 credit limit</li>
              <li>✔ Pay nothing today</li>
              <li>✔ 3-6 months interest free*</li>
              <li>✔ $9.95/month account fee or $0 if nothing owing</li>
              <li>✔ One-off establishment fee up to $99 may apply</li>
            </ul>
            <button className="mt-3 bg-purple-600 text-white w-full py-2 rounded-lg hover:bg-purple-700 transition">
              Apply now
            </button>
          </div>
        </div>

        {/* Disclaimer */}
        <div className="mt-4 text-xs text-gray-600">
          <p>
            *Zip Pay: Minimum monthly repayments required. A minimum monthly credit limit of $1,000 applies. 
          </p>
          <p className="mt-2">
            *Zip Money: Available to approved applicants. Terms & conditions apply. Interest-free terms subject to conditions.
          </p>
        </div>
      </div>
    </Modal>
  );
};

export default ZipPayModal;
