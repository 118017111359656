import React from "react";

const Returns= () => {
  return (
    <div className=" px-4 max-w-4xl mx-auto">
      <h1 className="text-3xl font-bold mb-6">Shop Zone Returns Policies</h1>

      {/* Introduction */}
      <p className="mb-6">
        There are separate returns policies that cover the different types of products we offer at Shop Zone and our partners. These are:
      </p>

      {/* Policies List */}
      <ul className="list-disc pl-6 mb-6">
        <li>Shop Zone 90 Day Change of Mind Returns Policy</li>
        <li>Trusted Partner Returns Policy</li>
        <li>Shop Zone Market Returns Policy</li>
      </ul>

      {/* 90 Day Change of Mind Returns Policy */}
      <section className="mb-8">
        <h2 className="text-2xl font-bold mb-4">90 Day Change of Mind Returns Policy</h2>
        <p className="mb-4">
          Shop Zone gives you 90 days* to return a product that you’ve changed your mind on. This policy is in addition to your rights under the Australian Consumer Law, which are described in our Faulty Goods Returns Policy.
        </p>

        <h3 className="text-xl font-semibold mb-2">Returns within 90 days</h3>
        <p className="mb-4">
          Shop Zone will happily give you a refund or exchange within 90 days of purchase, provided you have proof of purchase and the product:
        </p>
        <ul className="list-disc pl-6 mb-4">
          <li>is still in its original and saleable condition and packaging, with all accessories included</li>
          <li>has not been worn or used and, if a perishable product, is not expired</li>
          <li>is not a personalised item, digital download, pre-paid card, voucher or clearance item</li>
        </ul>
        <p className="mb-4">
          Returns can be processed at any Shop Zone store, including online orders. Refunds will only be made using the original payment method stated on your proof of purchase. Please note that delivery fees paid for online orders are non-refundable for change of mind returns.
        </p>
        <p className="mb-4">
          For lay-by returns, please refer to Shop Zone’s Lay-by Policy, available at{" "}
          <a
            href="https://shopzone.com.au/"
            className="text-blue-500 hover:underline"
            target="_blank"
            rel="noopener noreferrer"
          >
           https://shopzone.com.au/
          </a>
          . For buy now, pay later purchases such as Afterpay, please follow the returns process outlined in your parcel.
        </p>
        <p className="mb-4">
          *The Shop Zone 90 day change of mind returns policy does not apply to Shop Zone Market products.
        </p>
      </section>

      {/* Faulty Product Returns Policy */}
      <section className="mb-8">
        <h2 className="text-2xl font-bold mb-4">Faulty Product Returns Policy</h2>
        <p className="mb-4">
          Our goods and services come with guarantees that cannot be excluded under the Australian Consumer Law. For major failures with the service, you are entitled:
        </p>
        <ul className="list-disc pl-6 mb-4">
          <li>to cancel your service contract with us; and</li>
          <li>to a refund for the unused portion, or to compensation for its reduced value.</li>
        </ul>
        <p className="mb-4">
          You are also entitled to choose a refund or replacement for major failures with goods. If a failure with the goods or a service does not amount to a major failure, you are entitled to have the failure rectified in a reasonable time. If this is not done you are entitled to a refund for the goods and to cancel the contract for the service and obtain a refund of any unused portion. You are also entitled to be compensated for any other reasonably foreseeable loss or damage from a failure in the goods or service.
        </p>

        <h3 className="text-xl font-semibold mb-2">Returns of faulty products</h3>
        <p className="mb-4">
          If the problem is major, you are entitled to a replacement, repair or refund. Examples of major failure are when the product:
        </p>
        <ul className="list-disc pl-6 mb-4">
          <li>has a problem that would have stopped you buying it had you known about it;</li>
          <li>is unsafe;</li>
          <li>is significantly different from the description of the product; or</li>
          <li>doesn’t do what we said it would do,</li>
        </ul>
        <p className="mb-4">
          but does not include damage caused by misuse or returns simply due to change of mind.
        </p>
        <p className="mb-4">
          If the problem is not major, we may choose to give you a free repair instead of a replacement or refund if this is possible.
        </p>
        <p className="mb-4">
          If we cannot repair the product within a reasonable time, you can choose a refund or replacement. We reserve the right to refuse to provide a refund, repair or replacement if the problem has, for instance, arisen because the product was misused by the customer.
        </p>
      </section>

      {/* Proof of Purchase */}
      <section className="mb-8">
        <h2 className="text-2xl font-bold mb-4">Proof of Purchase</h2>
        <p className="mb-4">
          Returns must be accompanied by a receipt or other itemised, verifiable proof of purchase such as a Everyday Rewards statement. In certain circumstances, you may be required to provide further information such as photo identification for loss prevention or legislative requirements. Information collected in this manner will be stored securely in accordance with Shop Zone’s Privacy Policy. Shop Zone retains the right to refuse a refund for unverified purchases.
        </p>
      </section>

      {/* Remember to Backup Your Data */}
      <section className="mb-8">
        <h2 className="text-2xl font-bold mb-4">Remember to Backup Your Data</h2>
        <p className="mb-4">
          If you need to return a product that contains user-generated data (such as cameras, mobile phones or storage drives), please remember to backup any data in an alternative location as the repair or replacement may result in loss of data.
        </p>
      </section>

      {/* Manufacturer’s Warranties */}
      <section className="mb-8">
        <h2 className="text-2xl font-bold mb-4">Manufacturer’s Warranties</h2>
        <p className="mb-4">
          Some products come with an additional manufacturer’s warranty as indicated in the documentation provided with the relevant products. Should you wish to make any claim under a manufacturer’s warranty, you can do so directly with the relevant manufacturer. However, our friendly customer service team can also assist you with any enquiry of this nature.
        </p>
      </section>

      {/* Returning Products to Shop Zone */}
      <section className="mb-8">
        <h2 className="text-2xl font-bold mb-4">Returning Products to Shop Zone</h2>
        <p className="mb-4">
          If your purchase was made at a Shop Zone store, please approach the customer courtesy desk at your local Shop Zone store to process your product return or exchange. To find your nearest Shop Zone store,{" "}
          <a
            href="https://shopzone.com.au/"
            className="text-blue-500 hover:underline"
            target="_blank"
            rel="noopener noreferrer"
          >
            click here
          </a>
          .
        </p>
        <p className="mb-4">
          Products (excluding Shop Zone Market products) that you have purchased on bigw.com.au can be returned either:
        </p>
        <ul className="list-disc pl-6 mb-4">
          <li>
            <strong>By Post:</strong> Place the product/s in the packaging and affix the Returns Label to it. Take it to your local Post Office to be returned (fees will apply).
          </li>
          <li>
            <strong>In Store:</strong> Return the product/s to any one of our Shop Zone stores:{" "}
            
            
          </li>
        </ul>
      </section>

      {/* Damaged or Incorrect Product Delivered */}
      <section className="mb-8">
        <h2 className="text-2xl font-bold mb-4">Damaged or Incorrect Product Delivered</h2>
        <p className="mb-4">
          If your order is damaged in transit or we have shipped the incorrect product, please contact us on{" "}
          <a href="tel:1300244999" className="text-blue-500 hover:underline">
            1300 244 999
          </a>
          .
        </p>
        <p className="mb-4">
          If you have received an incorrect Shop Zone Market item that you did not order, please Contact Us immediately via My Account &gt; My Orders &gt; Wrong item received on{" "}
          <a
            href="https://www.bigw.com.au"
            className="text-blue-500 hover:underline"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://shopzone.com.au/
          </a>
          . Attach clear photos or provide a short video of the item you received, the delivery label, and the packaging. This documentation will assist us in resolving the issue quickly and accurately. Please Note: if you have checked out as a Guest, this option will not be available to you. If you need to Contact Us, please phone us on{" "}
          <a href="tel:1300244999" className="text-blue-500 hover:underline">
            1300 244 999
          </a>{" "}
          and one of our friendly team will be able to assist you.
        </p>
        <p className="mb-4">
          Once you have submitted your enquiry, the relevant Shop Zone Market Seller will be notified and provided with the documentation you have submitted.
        </p>
      </section>

      {/* Shop Zone Market Returns */}
      <section className="mb-8">
        <h2 className="text-2xl font-bold mb-4">Shop Zone Market Returns</h2>
        <h3 className="text-xl font-semibold mb-2">Shop Zone Market Sellers</h3>
        <p className="mb-4">
          Shop Zone Market is an online marketplace where you can buy products from Shop Zone Market Sellers, and they'll ship them directly to you. This means that you can access an extended range of products in one convenience place while you're shopping on Shop Zone Online.
        </p>

        <h3 className="text-xl font-semibold mb-2">Change of Mind</h3>
        <p className="mb-4">
          Change of mind returns are provided at the sole discretion of each Shop Zone Market Seller, on a case-by-case basis. The Shop Zone 90 day change of mind returns policy does not apply to Shop Zone Market products. Please check the relevant Shop Zone Market Seller's returns policy by navigating to the product and clicking on "Shop Zone Market Seller". This will take you to the relevant returns policy. If the Shop Zone Market Seller allows a change of mind return, they will ask you to follow their own policies and procedures for the return. Items that are returned without the Shop Zone Market Seller's approval will not be eligible for a refund.
        </p>

        <h3 className="text-xl font-semibold mb-2">Faulty Returns</h3>
        <p className="mb-4">
          Contact us as soon as possible by logging into your My Account &gt; My Orders &gt; Damaged items. Provide information to describe the damage/fault that will assist in achieving a speedy resolution. Once you have submitted your enquiry, the relevant Shop Zone Market Seller will be able to review and assist with providing a resolution.
        </p>

        <h3 className="text-xl font-semibold mb-2">How to Return a Shop Zone Market Item</h3>
        <p className="mb-4">
          In addition to your rights under the Australian Consumer Law, each Shop Zone Market Seller has their own policy relating to the return of their products. This can be found on the relevant product's listing page. If you need to return an item you have purchased, please click on the 'Shop Zone Market Seller' that is at the bottom of the product you need to return. You will find this in My Account &gt; My Order &gt; Product &gt; Help with this item &gt; Fill in the form selecting the option 'Returns & Refund'. Please note unfortunately, we are unable to process refunds or returns in-store for Shop Zone Market products.
        </p>
      </section>

      {/* Trusted Partner Returns */}
      <section className="mb-8">
        <h2 className="text-2xl font-bold mb-4">Trusted Partner Returns</h2>
        <h3 className="text-xl font-semibold mb-2">Trusted Partners</h3>
        <p className="mb-4">
          A Trusted Partner is a Drop Ship Vendor (DSV) whose items are sold on{" "}
          <a
            href="https://shopzone.com.au/"
            className="text-blue-500 hover:underline"
            target="_blank"
            rel="noopener noreferrer"
          >
           https://shopzone.com.au/
          </a>{" "}
          and delivered directly to customers from the Trusted Partner. Our Trusted Partner will manage and support your order, including shipping and handling, and change of mind exchanges and returns. For faulty returns or queries, please see the Faulty Returns section below.
        </p>

        <h3 className="text-xl font-semibold mb-2">Change of Mind</h3>
        <p className="mb-4">
          For change of mind returns you can contact our Trusted Partner quoting your Shop Zone online order number as reference. Trusted Partner Details are on the Delivery & Returns tab or you can contact us so we can help you resolve your query.
        </p>

        <h3 className="text-xl font-semibold mb-2">Faulty Returns</h3>
        <p className="mb-4">
          For any faulty product or returns enquiries post purchase, please contact us and quote your Shop Zone online order number so we can help resolve your enquiry.
        </p>
      </section>

      {/* Customer Care */}
      <section className="mb-8">
        <h2 className="text-2xl font-bold mb-4">Customer Care</h2>
        <p className="mb-4">
          We are committed to providing the best customer satisfaction and customer care. Please contact us should you have any queries regarding your rights under Australian Consumer Law.
        </p>
      </section>
    </div>
  );
};

export default Returns;