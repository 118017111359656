import React from "react";
import { Link } from "react-router-dom";
import Button from "../Button";

const DesignersCard = ({item}) => {
  return (
    <>
      <Link
        to={`/categories/${item?.id}`}
       
        className="scroll-item   overflow-hidden inline-block  bg-white"
      >
        <div className=" flex items-center">
          <img
            src={item?.image}
            className=" w-full  object-cover"
            alt=""
          />

         
        </div>

        <div className=" py-3 text-center">
          <h6 className="h6">{item?.name}</h6>

        </div>
      </Link>
    </>
  );
};

export default DesignersCard;
