import React, { useEffect, useState } from "react";
import CategoryCard from "../cards/CategoryCard";
import axios from "axios";
import { Base_url } from "../../utils/Base_url";

const Category = () => {
  const [allCategory, setAllCategory] = useState([
 
  ]);



  
  useEffect(()=>{
   
    const fetchSlider = async () => {
      try {
        const response = await axios.get(`${Base_url}/category/get`);

        console.log(response,'response');

        setAllCategory(response?.data?.data);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchSlider();
  },[])




  return (
    <div className="container mx-auto px-4 py-10">
      {/* Section Title */}
      <h4 className="text-black font-medium text-2xl capitalize ">
        Our top categories
      </h4>

      {/* Categories Grid */}
      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-10 my-10">
        {allCategory?.map((item, index) => (
          <CategoryCard
            key={index}
            url={`/categories/${item?.id}?categoryId=1&limit=10&page=1&sortBy=&brandIds=&categoryIds=&subCategoryIds=&subSubCategoryIds=&colorName=&minPrice=&maxPrice=&priceComparison=&price=`}
            image={item?.image}
            name={item?.name}
          />
        ))}
      </div>
    </div>
  );
};

export default Category;
