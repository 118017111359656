import React, { useEffect, useState } from "react";
import Navbar from "../../components/Header/Navbar";
import Footer from "../../components/footer";
import { Base_url } from "../../utils/Base_url";
import axios from "axios";
import { useParams } from "react-router-dom";
import BottomHeader from "../../components/Header/BottomHeader";

const SustainabilityDetails = () => {
  const { id } = useParams();
  const [allMainBlogs, setAllMainBlogs] = useState({});

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await axios.get(`${Base_url}/blog/get/${id}`);

        console.log(response, "response");

        setAllMainBlogs(response?.data?.data);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchBlogs();
  }, []);

  return (
    <div>
      <Navbar />
      <BottomHeader />
      <section className=" w-8/12 mx-auto py-12">
        <h1 className=" text-xl pb-3 text-center">{allMainBlogs?.name}</h1>
        <div className=" w-full h-96">
          <img
            src={allMainBlogs?.image}
            alt=""
            className=" w-full h-full object-cover object-center"
          />
        </div>

        <p className=" pt-3">{allMainBlogs?.descr}</p>
      </section>
      <Footer />
    </div>
  );
};

export default SustainabilityDetails;
