const Loader = () => {
  return (
    <div className="flex h-screen items-center justify-center bg-gray-100 transition-opacity duration-500 ease-in-out">
      <div className="relative">
        <div className="h-24 w-24 animate-spin rounded-full border-4 border-solid border-primary border-t-transparent"></div>
        <div className="absolute inset-0 flex items-center justify-center">
          <span className="text-primary font-semibold">Loading...</span>
        </div>
      </div>
    </div>     
  ); 
};

export default Loader;
