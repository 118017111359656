import React from 'react'
import Navbar from '../../components/Header/Navbar'
import Footer from '../../components/footer'
import BottomHeader from '../../components/Header/BottomHeader'

const AboutUs = () => {
  return (
    <div>
     <Navbar/>
     <BottomHeader/>
     
     <Footer/>
    </div>
  )
}

export default AboutUs