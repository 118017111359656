import React, { useEffect, useState } from 'react'
import Navbar from '../../components/Header/Navbar'
import Footer from '../../components/footer'
import BottomHeader from '../../components/Header/BottomHeader'
import Button from '../../components/Button'
import { Base_url } from '../../utils/Base_url'
import axios from 'axios'
import { Link } from 'react-router-dom'

const Catalogues = () => {
  // const data = [
  //   {
  //     id:1,
  //     title:'Boxing Day Sale Catalogue',
  //     image:'https://dduhxx0oznf63.cloudfront.net/images/featureimage/ipad/57672_d4dbf59badb54df.jpg'
  //   },


  //   {
  //     id:2,
  //     title:'Christmas Gifting Catalogue',
  //     image:'https://dduhxx0oznf63.cloudfront.net/images/featureimage/ipad/57592_d8d3cb0538def65.jpg'
  //   },

  //   {
  //     id:3,
  //     title:'Healthylife December Catalogue',
  //     image:'https://dduhxx0oznf63.cloudfront.net/images/featureimage/ipad/57436_c22b79a44c8637b.jpg'
  //   },

  //   {
  //     id:4,
  //     title:'Christmas Catalogue',
  //     image:'https://dduhxx0oznf63.cloudfront.net/images/featureimage/ipad/56893_6be2048f0719307.jpg'
  //   },
  //   {
  //     id:5,
  //     title:'Weekly Specials Catalogue',
  //     image:'https://dduhxx0oznf63.cloudfront.net/images/featureimage/ipad/42326_0d7feceb6e30ab6.jpg'
  //   },
  // ]




  const [data,setData] = useState([]);


  useEffect(()=>{
     
   
    const fetchDeals = async () => {
      try {
        const response = await axios.get(`${Base_url}/catalogue/get`);

        console.log(response,'response');

        setData(response?.data?.data);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchDeals();

  },[])



  return (
    <>
     <Navbar/>
     <BottomHeader/>
      <section className=' pt-2'>
     
      <div className="max-w-screen-lg  mx-auto px-4">
      <p>Home / Catalogues</p>
      <div className="grid grid-cols-1 py-6 sm:grid-cols-2 md:grid-cols-3 gap-6">
        {data.map((catalogue, index) => (
          <Link to={`/catalogues/${catalogue?.id}`}
            key={index}
            className="bg-white p-4  rounded-lg overflow-hidden border"
          >
            <img
              src={catalogue.image}
              alt={catalogue.title}
              className="w-full  object-cover"
            />
            <div className="p-4">
              <h2 className="text-lg font-semibold text-gray-800">
                {catalogue.name}
              </h2>
              <div  className=' flex justify-center items-center mt-5'>
                <div className=' w-full'>
                <Button label={'View Specials'} className={' w-full py-3 text-lg hover:bg-primary hover:text-white rounded-md border-primary  border '} />

                </div>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
      </section>
     <Footer/>
    </>
  )
}

export default Catalogues