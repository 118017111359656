import React, { useEffect, useState } from "react";
import Product from "../../components/cards/Product";
import Navbar from "../../components/Header/Navbar";
import Footer from "../../components/footer";
import axios from "axios";
import { Base_url } from "../../utils/Base_url";
import BottomHeader from "../../components/Header/BottomHeader";
import Swal from "sweetalert2";

const ForYou = () => {
  const [allProduct, setAllProduct] = useState([]);
  const storedUser = localStorage.getItem("user_ID") || undefined;

  
  useEffect(() => {
    fetchWishlistProducts();
  }, []);

  const fetchWishlistProducts = () => {
    axios
      .get(`${Base_url}/product/getWishlist/${storedUser}`)
      .then((res) => {
        console.log(res);
        setAllProduct(res?.data?.data || []);
      })
      .catch((error) => {
        console.error("Error fetching wishlist products:", error);
      });
  };

  // Function to remove a product from the wishlist
  const removeFunction = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#A47ABF",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`${Base_url}/wishlist/delete/${id}`)
          .then((res) => {
            if (res.status === 200) {
              Swal.fire("Deleted!", "Your file has been deleted.", "success");
              // Refresh the wishlist after deletion
              fetchWishlistProducts();
            }
          })
          .catch((error) => {
            console.error("Error deleting wishlist item:", error);
          });
      }
    });
  };

  // Function to refresh the wishlist
  const handleRefresh = () => {
    fetchWishlistProducts();
  };

  return (
    <>
      <Navbar />
      <BottomHeader />
      <div className="container mx-auto py-5">
        <h1 className="h2">My Wishlists</h1>
        <p className="pt-2">Wishlists have a maximum limit of 150 items.</p>

        <div className="grid item1 pb-12 mx-auto col-span-2 mt-3 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8">
          {allProduct?.map((item, index) => (
            <Product
              key={item.id}
              type="wishlist"
              setWishList={setAllProduct}
              item={item}
              onRefresh={handleRefresh}
            />
          ))}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ForYou;