import React from "react";

const Payments = () => {
 
  return (
    <div className=" container mx-auto">
      <div className="">
        <h1 className="h3">Payments</h1>
      </div>

     
    </div>
  );
};

export default Payments;
