import React, { useEffect, useRef, useState } from "react";
import Navbar from "../../components/Header/Navbar";
import Footer from "../../components/footer";
import BottomHeader from "../../components/Header/BottomHeader";
import { FaHeart, FaMinus, FaPlus, FaRegHeart } from "react-icons/fa";
import { RiDeleteBin5Line } from "react-icons/ri";
import axios from "axios";
import { Base_url } from "../../utils/Base_url";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Paypal from "../../assets/images/paypal.svg";
import afterpay_clearpay from "../../assets/images/afterpay.svg";
import klarna from "../../assets/images/klarna.jpg";
import AddAddress from "../users/address/AddAddress";
import EditAddress from "../users/address/EditAddress";
import moment from "moment";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import {
  PayPalScriptProvider,
  PayPalButtons,
  FUNDING,
} from "@paypal/react-paypal-js";
import {
  useStripe,
  useElements,
  PaymentElement,
  CardElement,
  Elements,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe(
  "pk_live_51QupCXFlL5jOrRHcfitcktIxodU1TPvSPPXXwZokbHuIoj2vE2uJMtCdXXuBE7spbZ9tx8cA75VV8cLz9lJlQMqR007LIoXgBr"
);

const StripeProviderAfterpay = ({ selectedMethod, setSelectedMethod }) => {
  const googleMapsApiKey = "AIzaSyB2I4n7I5XDIpt1Xo03y7gXVQVK9safwd0";
  const userData = useSelector((state) => state.auth.user);
  const [isBillingSameAsDelivery, setIsBillingSameAsDelivery] = useState(true);
  const navigate = useNavigate();
  const { data, isAuthenticated } = useSelector((state) => state.auth);
  console.log(isAuthenticated, "userdata");
  const storedUser = localStorage.getItem("user_ID") || undefined;
  const [topSearch, setTopSearch] = useState([]);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const paymentIntentValue = queryParams.get("payment_intent");
  const [address, setAddress] = useState([]);
  console.log(isAuthenticated, "userdata");
  const [allCart, setAllCart] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState(null);
  console.log(selectedAddress, "selectd addres");
  const [errors, setErrors] = useState({});
  console.log(paymentIntentValue);

  const handleAddressChange = (item) => {
    setSelectedAddress(item);
  };

  const handleAddressSelect = (address, setFieldValue) => {
    const addressComponents = address.value.terms;

    const addressLine = addressComponents[0].value;

    setAddressLine(addressLine);
  };

  console.log(allCart);
  useEffect(() => {
    return () => {
      const hadClientSecretId = localStorage.getItem("clientSecretId") !== null;
      const hadClientSecretKey =
        localStorage.getItem("clientSecretKey") !== null;

      localStorage.removeItem("clientSecretId");
      localStorage.removeItem("clientSecretKey");

      if (hadClientSecretId && hadClientSecretKey) {
        window.location.reload();
      }
    };
  }, []);

  useEffect(() => {
    axios
      .get(`${Base_url}/cart/getBuy?userId=${storedUser}`)
      .then((res) => {
        console.log(res);

        setAllCart(res.data.data, "all products");
      })
      .catch((error) => {});

    axios
      .get(`${Base_url}/search/get?userId=${storedUser}`)
      .then((res) => {
        console.log(res?.data?.data, "==============>>>>>>>>>sub");

        const uniqueSearches = res?.data?.data?.filter(
          (item, index, self) =>
            index ===
            self.findIndex(
              (search) =>
                search.productId === item.productId &&
                search.userId === item.userId
            )
        );

        setTopSearch(uniqueSearches);
      })
      .catch((error) => {});

    axios
      .get(`${Base_url}/address/user/${storedUser}`)
      .then((res) => {
        console.log(res);

        const fetchedAddresses = res?.data?.data || [];
        setAddress(fetchedAddresses);
        if (fetchedAddresses.length > 0) {
          setSelectedAddress(fetchedAddresses[0]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const removeFunction = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#A47ABF",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`${Base_url}/cart/delete/${id}`)
          .then((res) => {
            if (res.status === 200) {
              Swal.fire(
                "Deleted!",
                "Your product has been deleted.",
                "success"
              );

              axios
                .get(`${Base_url}/cart/getBuy?userId=${storedUser}`)
                .then((res) => {
                  console.log(res);

                  setAllCart(res.data.data, "all products");
                })
                .catch((error) => {});
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });
  };

  const [phone, setPhone] = useState(userData?.phone);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [addressLine, setAddressLine] = useState("");
  const [quantity, setQuantity] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [updateModalOpen, setUpdateModalOpen] = useState(false);
  const [getData, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const handleUpdateQuantity = async (
    type,
    id,
    quantity,
    sellPrice,
    originalPrice
  ) => {
    setLoading(true);
    const newQuantity = type === "increment" ? quantity + 1 : quantity - 1;

    if (newQuantity < 1) {
      toast.error("Quantity cannot be less than 1");
      setLoading(false);
      return;
    }

    try {
      const response = await axios.put(`${Base_url}/cart/update/${id}`, {
        quantity: newQuantity,
        totalSellPrice: Number(sellPrice) * Number(newQuantity),
        totalOriginalPrice: Number(originalPrice) * Number(newQuantity),
      });

      if (response.data.status === "ok") {
        setQuantity(newQuantity);
        // toast.success("Quantity updated successfully");

        axios
          .get(`${Base_url}/cart/getBuy?userId=${storedUser}`)
          .then((res) => {
            console.log(res);

            setAllCart(res.data.data, "all products");
          })
          .catch((error) => {});
      } else {
        toast.error("Failed to update quantity");
      }
    } catch (error) {
      console.error("Error updating quantity:", error);
      toast.error("An error occurred while updating quantity");
    } finally {
      setLoading(false);
    }
  };

  const [isLiked, setIsLiked] = useState(null);

  const handleLikeDislike = async (item) => {
    try {
      setIsLiked(!isLiked);
      const param = {
        id: item?.product?.id,
        userId: storedUser?.toString(),
      };

      const response = await axios.post(`${Base_url}/product/wishlist`, param);

      if (response.data.status === "ok") {
        toast.success(response?.data?.message);

        axios
          .get(`${Base_url}/cart/getBuy?userId=${storedUser}`)
          .then((res) => {
            console.log(res);

            setAllCart(res.data.data, "all products");
          })
          .catch((error) => {});
      }

      if (response.data.status === "fail") {
        toast.error(response?.data?.message);
        setIsLiked(!isLiked);
        navigate("/login");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const totalPrice = allCart?.reduce((acc, product) => {
    return acc + product?.totalOriginalPrice;
  }, 0);

  const totalDelivery = allCart?.reduce((acc, product) => {
    return acc + product?.deliveryCharge;
  }, 0);

  console.log("Total Price:", totalPrice);
  const validateForm = () => {
    const tempErrors = {};
    const phoneRegex = /^[+][0-9]{10,14}$/;

    if (!phone) {
      tempErrors.phone = "Enter a valid phone number.";
    }
    if (address?.length === 0) {
      tempErrors.address = " address cannot be empty.";
    }
    if (isBillingSameAsDelivery === false) {
      if (!firstName.trim()) {
        tempErrors.firstName = "First name is required.";
      }
      if (!lastName.trim()) {
        tempErrors.lastName = "Last name is required.";
      }
      if (!addressLine.trim()) {
        tempErrors.addressLine = "Billing address is required.";
      }
    }

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const getFormattedDate = (days) => {
    const today = new Date();
    const arrivalDate = new Date(today);

    arrivalDate.setDate(today.getDate() + days);
    const options = { day: "numeric", month: "long" };
    return arrivalDate.toLocaleDateString("en-US", options);
  };
  const stripe = useStripe();
  const elements = useElements();
  const handlePaymentSelection = async (method) => {
    try {
      const res = await axios.post(`${Base_url}/order/paymentIntent`, {
        userId: storedUser,
        totalBill:
          Number(totalPrice) +
          Number(totalDelivery) -
          Number(
            (Number(totalPrice) * Number(localStorage.getItem("discount"))) /
              100
          ),
        accountType: method,
      });

      console.log(res, "paymentintent====>>>>>");

      if (res.data.paymentIntent?.id && res.data.paymentIntent?.client_secret) {
        localStorage.setItem("clientSecretId", res.data.paymentIntent.id);
        localStorage.setItem(
          "clientSecretKey",
          res.data.paymentIntent.client_secret
        );
      } else {
        throw new Error("Missing payment intent data");
      }
    } catch (err) {
      console.error("Error fetching clientSecret:", err);
    }
  };

  const hasOrdersReplace2BeenCalled = useRef(false);
  const [hasOrderBeenPlaced, setHasOrderBeenPlaced] = useState(false);

  useEffect(() => {
    if (
      paymentIntentValue &&
      allCart?.length > 0 &&
      selectedAddress &&
      !hasOrderBeenPlaced
    ) {
      setHasOrderBeenPlaced(true);
      ordersReplace2();
    }
  }, [paymentIntentValue, allCart, selectedAddress, hasOrderBeenPlaced]);

  const ordersReplace = async (e) => {
    e.preventDefault();

    const isValid = validateForm();
    if (allCart?.length === 0) {
      navigate("/shop");
      return;
    }
    if (!isValid) {
      toast.error("Please fill all the fields");
      return;
    }

    setLoading(true);
    await handlePaymentSelection(selectedMethod);
    await new Promise((resolve) => setTimeout(resolve, 1000));
    const clientSecret = localStorage.getItem("clientSecretId");
    const clientSecretKey = localStorage.getItem("clientSecretKey");
    // if (!clientSecret || !clientSecretKey) {
    //   toast.error("Payment details are missing. Please try again.");
    //   setLoading(false);
    //   return;
    // }

    if (!stripe || !elements) {
      toast.error("Payment details are missing");
      setLoading(false);
      return;
    }
    try {
      const { error: submitError } = await elements.submit();
      if (submitError) {
        toast.error(submitError.message);
        setLoading(false);
        return;
      }
      const { error, paymentIntent } = await stripe.confirmPayment({
        elements,
        clientSecret: clientSecretKey,
        redirect: "if_required",
        confirmParams: {
          return_url: `https://shopzone.com.au/checkout`,
        },
      });

      if (error) {
        toast.error(error.message);
        setLoading(false);
        return;
      }

      const totalBill =
        Number(totalPrice) +
        Number(totalDelivery) -
        Number(
          (Number(totalPrice) * Number(localStorage.getItem("discount"))) / 100
        );

      const params = {
        accountType: selectedMethod,
        paymentIntentId: clientSecret,
        userId: storedUser,
        discount: localStorage.getItem("discount"),
        tax: "",
        deliveryCharges: totalDelivery,
        subTotalBill: totalPrice,
        totalBill,
        fromDeliverDay: moment(Date()).format("LL"),
        toDeliverDay: getFormattedDate(4),
        firstName: selectedAddress?.firstName,
        phone: selectedAddress?.phone,
        lastName: selectedAddress?.lastName,
        businessName: selectedAddress?.businessName,
        addressLine1: selectedAddress?.addressLine1,
        addressLine2: selectedAddress?.addressLine2,
        city: selectedAddress?.city,
        comment: selectedAddress?.comment,
        trackingNo: "",
        trackingLink: "",
        status: "pending",
      };

      const res = await axios.post(`${Base_url}/order/create`, params);

      if (res?.data?.status !== "ok") {
        throw new Error("Failed to create order");
      }
      localStorage.removeItem("clientSecretId");
      localStorage.removeItem("clientSecretKey");
      localStorage.setItem("discount", 0);

      toast.success("Order created successfully!");
      setLoading(false);

      await Promise.all(
        allCart.map((item) =>
          axios.put(`${Base_url}/cart/update/${item?.id}`, {
            orderId: res?.data?.data?.id,
          })
        )
      );

      navigate("/users/order_history");
    } catch (error) {
      console.error("Error:", error);
      toast.error(
        error.response?.data?.message ||
          "An error occurred while processing your order."
      );
      setLoading(false);
    }
  };

  const ordersReplace2 = async () => {
    const clientSecret = localStorage.getItem("clientSecretId");
    const clientSecretKey = localStorage.getItem("clientSecretKey");
    try {
      const params = {
        paymentIntentId: paymentIntentValue,
        accountType: selectedMethod,
        userId: storedUser,
        discount: localStorage.getItem("discount"),
        tax: "",
        deliveryCharges: totalDelivery,
        subTotalBill: totalPrice,
        totalBill:
          Number(totalPrice) +
          Number(totalDelivery) -
          Number(
            (Number(totalPrice) * Number(localStorage.getItem("discount"))) /
              100
          ),
        fromDeliverDay: moment(Date()).format("LL"),
        toDeliverDay: getFormattedDate(4),
        firstName: selectedAddress?.firstName,
        phone: selectedAddress?.phone,
        lastName: selectedAddress?.lastName,
        businessName: selectedAddress?.businessName,
        addressLine1: selectedAddress?.addressLine1,
        addressLine2: selectedAddress?.addressLine2,
        city: selectedAddress?.city,
        comment: selectedAddress?.comment,
        trackingNo: "",
        trackingLink: "",
        status: "pending",
      };

      axios.post(`${Base_url}/order/create`, params).then((res) => {
        if (res?.data?.status === "ok") {
          toast.success("Order created successfully!");
          setLoading(false);

          allCart?.forEach((item) => {
            const parms = {
              orderId: res?.data?.data?.id,
            };
            axios
              .put(`${Base_url}/cart/update/${item?.id}`, parms)
              .then((res) => {
                if (res?.data?.status === "ok") {
                  setLoading(false);
                  localStorage.removeItem("clientSecretId");
                  localStorage.removeItem("clientSecretKey");
                  localStorage.setItem("discount", 0);
                  navigate("/users/order_history");
                }
              })
              .catch((errors) => {
                console.log(errors);
                setLoading(false);
              });
          });
        } else {
          toast.error("Failed to create order");
          setLoading(false);
        }
      });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <>
      <Navbar />
      <BottomHeader />
      <AddAddress
        setAddress={setAddress}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        setSelectedAddress={setSelectedAddress}
      />
      <EditAddress
        setAddress={setAddress}
        isModalOpen={updateModalOpen}
        setIsModalOpen={setUpdateModalOpen}
        getData={getData}
      />

      <div className="max-w-screen-xl mx-auto py-8 px-4">
        <div className="flex justify-between items-center mb-6">
          <div>
            <Link to={"/cart"} className="text-sm text-blue-600 font-medium">
              Return to cart
            </Link>
          </div>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
          {/* Left Column */}
          <div className="lg:col-span-2">
            <div className="border border-gray-300 rounded-lg p-4 mb-6">
              <div>
                {/* <p className="text-sm font-semibold text-gray-700">
                  <span className="text-green-600 pb-2">Step 1 of 3 </span> -
                  Complete
                </p> */}
                <h2 className=" font-medium border-b pb-5 text-xl text-gray-700">
                  Review cart ( {allCart?.length} item)
                </h2>
              </div>

              {/* Item Details */}

              <h3 className="font-semibold text-gray-700 pt-3">Item Details</h3>

              <div class="space-y-6 mt-5">
                {allCart && allCart.length > 0 ? (
                  allCart?.map((item, index) => {
                    return (
                      <div class="rounded-lg border border-gray-200 bg-white p-4 shadow-sm :border-gray-700 :bg-gray-800 md:p-6">
                        <div class="space-y-4 md:flex md:items-center md:justify-between md:gap-6 md:space-y-0">
                          <Link
                            to={`/product_details/${item?.product?.id}`}
                            class="shrink-0 md:order-1"
                          >
                            <img
                              class="h-20 w-20 :hidden"
                              src={item?.product?.Image1}
                              alt="imac image"
                            />
                            <img
                              class="hidden h-20 w-20 :block"
                              src="https://flowbite.s3.amazonaws.com/blocks/e-commerce/imac-front-.svg"
                              alt="imac image"
                            />
                          </Link>

                          <label for="counter-input" class="sr-only">
                            Choose quantity:
                          </label>
                          <div class="flex items-center justify-between md:order-3 md:justify-end">
                            <div className=" flex w-32 items-center">
                              <button
                                onClick={() =>
                                  handleUpdateQuantity(
                                    "decrement",
                                    item?.id,
                                    item?.quantity,
                                    item?.product?.sellPrice,
                                    item?.product?.specialPrice
                                      ? item?.product?.specialPrice
                                      : item?.product?.originalPrice
                                  )
                                }
                                className=" flex justify-center   text-white items-center w-20 h-9  bg-primary"
                              >
                                <FaMinus />
                              </button>
                              <input
                                value={item?.quantity}
                                className=" text-center border w-full outline-none  h-9"
                              />
                              <button
                                onClick={() =>
                                  handleUpdateQuantity(
                                    "increment",
                                    item?.id,
                                    item?.quantity,
                                    item?.product?.sellPrice,
                                    item?.product?.specialPrice
                                      ? item?.product?.specialPrice
                                      : item?.product?.originalPrice
                                  )
                                }
                                className=" flex justify-center   text-white items-center w-20 h-9  bg-primary"
                              >
                                <FaPlus />
                              </button>
                            </div>
                            <div class="text-end md:order-4 md:w-32">
                              <p class="text-base font-bold text-gray-900 :text-white">
                                ${item?.totalOriginalPrice} AUD
                              </p>
                            </div>
                          </div>

                          <div class="w-full min-w-0 flex-1 space-y-4 md:order-2 md:max-w-md">
                            <a
                              href="#"
                              class="text-base font-medium text-gray-900 hover:underline :text-white"
                            >
                              {item?.product?.name}
                            </a>

                            {item?.product?.hasFreeProduct ? (
                              <p className="text-green">
                                You got one free product!
                              </p>
                            ) : null}

                            <div class="flex items-center gap-4">
                              <button
                                onClick={() => handleLikeDislike(item)}
                                type="button"
                                className={`inline-flex gap-1 items-center text-sm ${
                                  item?.product?.like?.includes(
                                    storedUser?.toString()
                                  )
                                    ? "text-black"
                                    : "text-gray-500"
                                }`}
                              >
                                {item?.product?.like?.includes(
                                  storedUser?.toString()
                                ) ? (
                                  <FaHeart size={20} />
                                ) : (
                                  <FaRegHeart size={20} />
                                )}
                                Add to Favorites
                              </button>

                              <button
                                onClick={() => removeFunction(item?.id)}
                                type="button"
                                class="inline-flex items-center gap-1 text-sm font-medium text-red-600 hover:underline :text-red-500"
                              >
                                <RiDeleteBin5Line size={20} />
                                Remove
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="flex flex-col items-center justify-center h-full p-10 text-center">
                    {/* <img
                                    src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/hero/empty-cart.svg"
                                    alt="No Products Found"
                                    className="w-40 h-40 mb-4"
                                  /> */}
                    <h2 className="text-xl font-bold text-gray-900 :text-white">
                      No Products Found
                    </h2>
                    <p className="text-sm text-gray-600 :text-gray-400">
                      It looks like your cart is empty. Start adding products
                      now!
                    </p>
                  </div>
                )}
              </div>
            </div>

            <div className="border border-gray-300 rounded-lg p-4 mb-6">
              <h3 className=" font-medium border-b pb-5 text-xl text-gray-700">
                Contact Details
              </h3>
              {/* Form Section */}

              <form>
                {/* Phone Number */}
                <div className="mb-6 pt-3">
                  <label
                    htmlFor="phone"
                    className="block text-sm font-medium text-gray-700 mb-2"
                  >
                    Phone Number
                  </label>
                  <input
                    type="text"
                    id="phone"
                    disabled
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    placeholder="Enter phone"
                    className="w-full border border-gray-300 rounded-lg py-2 px-3 text-sm outline-none focus:ring-2 focus:ring-blue-500"
                  />
                  {errors.phone && (
                    <p className="text-red pt-3 text-sm">{errors.phone}</p>
                  )}
                </div>

                {/* Delivery Address */}
                <div className="mb-6">
                  <h4 className="font-medium text-gray-700 mb-2">
                    Delivery Details
                  </h4>

                  {address.map((item, index) => (
                    <div className="flex gap-3 mb-4" key={index}>
                      <input
                        type="radio"
                        id={`address-${item.id}`}
                        name="delivery"
                        value={item.id}
                        checked={selectedAddress?.id === item.id}
                        onChange={() => handleAddressChange(item)}
                        className="w-5 h-5 border-gray-300"
                      />
                      <label
                        htmlFor={`address-${item.id}`}
                        className="text-sm text-gray-700"
                      >
                        <p className="capitalize">
                          {item?.firstName} {item?.lastName}
                        </p>
                        <p className="capitalize">{item?.businessName}</p>
                        <p className="capitalize">{item?.addressLine1}</p>
                        <p className="capitalize">{item?.country}</p>
                        <p
                          onClick={() => {
                            setUpdateModalOpen(true);
                            setData(item);
                          }}
                          className=" cursor-pointer py-2 text-black underline font-medium"
                        >
                          Edit
                        </p>
                      </label>
                    </div>
                  ))}

                  <button
                    onClick={() => setIsModalOpen(true)}
                    type="button"
                    className="text-black flex gap-3 items-center  font-medium  mt-2"
                  >
                    <FaPlus /> Add a new delivery address
                  </button>
                  {errors.address && (
                    <p className="text-red pt-3 text-sm">{errors.address}</p>
                  )}
                </div>

                {/* Billing Details */}
                <div className="mb-6">
                  <h4 className="font-medium text-gray-700 mb-2">
                    Billing Details
                  </h4>
                  <div className="flex items-center gap-3">
                    <input
                      type="checkbox"
                      id="billingSame"
                      checked={isBillingSameAsDelivery}
                      onChange={() =>
                        setIsBillingSameAsDelivery(!isBillingSameAsDelivery)
                      }
                      className="w-5 h-5 border-gray-300"
                    />
                    <label
                      htmlFor="billingSame"
                      className="text-sm text-gray-700"
                    >
                      Billing address is the same as Delivery address
                    </label>
                  </div>
                  {!isBillingSameAsDelivery && (
                    <div className="mt-4">
                      <input
                        type="text"
                        id="firstName"
                        name="firstName"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        placeholder="First Name"
                        className="w-full border border-gray-300 rounded-lg py-2 px-3 text-sm mb-2 focus:ring-2 focus:ring-blue-500"
                      />
                      {errors.firstName && (
                        <p className="text-red pb-2  text-sm">
                          {errors.firstName}
                        </p>
                      )}
                      <input
                        type="text"
                        value={lastName}
                        id="lastName"
                        name="lastName"
                        onChange={(e) => setLastName(e.target.value)}
                        placeholder="Last Name"
                        className="w-full border border-gray-300 rounded-lg py-2 px-3 text-sm mb-2 focus:ring-2 focus:ring-blue-500"
                      />
                      {errors.lastName && (
                        <p className="text-red  pb-2 text-sm">
                          {errors.lastName}
                        </p>
                      )}
                      <input
                        type="text"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        placeholder="Phone Number"
                        className="w-full border border-gray-300 rounded-lg py-2 px-3 text-sm mb-2 focus:ring-2 focus:ring-blue-500"
                        disabled
                      />
                      {errors.phone && (
                        <p className="text-red  pb-2 text-sm">{errors.phone}</p>
                      )}

                      {/* <input
                        type="text"
                        value={addressLine}
                        onChange={(e) => setAddressLine(e.target.value)}
                        placeholder="Billing Address"
                        className="w-full border border-gray-300 rounded-lg py-2 mb-2 px-3 text-sm focus:ring-2 focus:ring-blue-500"
                      /> */}
                      <GooglePlacesAutocomplete
                        apiKey={googleMapsApiKey}
                        selectProps={{
                          placeholder: "Search Billing Address",
                          onChange: (address) => handleAddressSelect(address),
                          styles: {
                            control: (provided) => ({
                              ...provided,
                              border: "1px solid #d1d5db",
                              borderRadius: "0.375rem",
                              padding: "0.5rem",
                            }),
                          },
                        }}
                      />
                      {errors.addressLine && (
                        <p className="text-red  pb-2 text-sm">
                          {errors.addressLine}
                        </p>
                      )}
                    </div>
                  )}
                </div>

                {/* Permission Checkbox */}
                <div className="mb-6">
                  <input
                    type="checkbox"
                    id="permission"
                    className="w-5 h-5 border-gray-300"
                  />
                  <label
                    htmlFor="permission"
                    className="text-sm text-gray-700 ml-3"
                  >
                    I give permission to leave items unattended when delivered.
                  </label>
                </div>
                <div className="mb-4">
                  {/* Payment Method Selection */}

                  <h1 className=" pb-3 font-semibold text-lg">
                    Payment Method
                  </h1>
                  <div className="flex flex-col gap-2">
                    <label
                      className={`w-full bg-gray-100 cursor-pointer p-4 border ${
                        selectedMethod === "card"
                          ? "border-black"
                          : "border-gray-300 "
                      }`}
                    >
                      <div className="flex  justify-between items-center gap-3">
                        <div className=" flex items-center gap-2">
                          <input
                            type="radio"
                            name="paymentMethod"
                            value="card"
                            checked={selectedMethod === "card"}
                            onChange={() => setSelectedMethod("card")}
                            className=" w-5 h-5 "
                          />
                          <span className={`text-black font-medium`}>
                            Credit card
                          </span>
                        </div>
                        <div className=" flex gap-2">
                          <svg
                            width="32"
                            height="32"
                            viewBox="0 0 35 25"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            role="img"
                            class="Icon Icon_Icon__ws1IO"
                            aria-label="Visa"
                            aria-description="Visa"
                            data-di-res-id="1f6b3843-8261d8f2"
                            data-di-rand="1741241152836"
                          >
                            <title>Visa</title>
                            <rect
                              x="0.5"
                              y="0.506"
                              width="34"
                              height="23"
                              rx="1.5"
                              fill="#172B85"
                              stroke="#D9D9D9"
                            ></rect>
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M10.625 16.265h-2.12l-1.59-6.066c-.076-.28-.236-.526-.471-.642A6.902 6.902 0 0 0 4.5 8.915V8.68h3.416a.89.89 0 0 1 .884.758l.825 4.376 2.119-5.134h2.061l-3.18 7.584Zm4.359 0H12.98l1.65-7.584h2.002l-1.65 7.584Zm4.24-5.483c.058-.408.412-.642.824-.642a3.731 3.731 0 0 1 1.944.35l.353-1.633a5.068 5.068 0 0 0-1.825-.35c-1.944 0-3.358 1.05-3.358 2.508 0 1.109 1.002 1.691 1.709 2.042.765.35 1.06.583 1 .933 0 .525-.589.758-1.177.758a5.05 5.05 0 0 1-2.061-.467l-.354 1.634a5.777 5.777 0 0 0 2.18.408c2.179.058 3.533-.992 3.533-2.567 0-1.983-2.769-2.1-2.769-2.974ZM29 16.265 27.41 8.68h-1.708a.886.886 0 0 0-.825.584l-2.944 7h2.061l.412-1.108h2.533l.235 1.108H29Zm-3.003-5.542.588 2.858h-1.649l1.06-2.858Z"
                              fill="#fff"
                            ></path>
                          </svg>
                          <svg
                            width="32"
                            height="32"
                            viewBox="0 0 35 25"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            role="img"
                            class="Icon Icon_Icon__ws1IO"
                            aria-label="Mastercard"
                            aria-description="Mastercard"
                            data-di-res-id="1f6b3843-9a8bf2e2"
                            data-di-rand="1741241152836"
                          >
                            <title>Mastercard</title>
                            <rect
                              x="0.5"
                              y="0.506"
                              width="34"
                              height="23"
                              rx="1.5"
                              fill="#fff"
                              stroke="#D9D9D9"
                            ></rect>
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M17.75 17.163a6.75 6.75 0 0 1-4.426 1.65c-3.769 0-6.824-3.091-6.824-6.904 0-3.812 3.055-6.903 6.824-6.903a6.75 6.75 0 0 1 4.426 1.65 6.75 6.75 0 0 1 4.426-1.65c3.769 0 6.824 3.09 6.824 6.903 0 3.813-3.055 6.903-6.824 6.903a6.75 6.75 0 0 1-4.426-1.649Z"
                              fill="#ED0006"
                            ></path>
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M17.75 17.163a6.92 6.92 0 0 0 2.398-5.254 6.92 6.92 0 0 0-2.398-5.254 6.75 6.75 0 0 1 4.426-1.649c3.769 0 6.824 3.09 6.824 6.903 0 3.813-3.055 6.903-6.824 6.903a6.75 6.75 0 0 1-4.426-1.649Z"
                              fill="#F9A000"
                            ></path>
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M17.75 6.655a6.92 6.92 0 0 1 2.398 5.254 6.92 6.92 0 0 1-2.398 5.254 6.92 6.92 0 0 1-2.398-5.254 6.92 6.92 0 0 1 2.398-5.254Z"
                              fill="#FF5E00"
                            ></path>
                          </svg>
                          <svg
                            width="32"
                            height="32"
                            viewBox="0 0 35 25"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            role="img"
                            class="Icon Icon_Icon__ws1IO"
                            aria-label="American Express"
                            aria-description="American Express"
                            data-di-res-id="1f6b3843-c192c65a"
                            data-di-rand="1741241152836"
                          >
                            <title>American Express</title>
                            <rect
                              x="0.5"
                              y="0.506"
                              width="34"
                              height="23"
                              rx="1.5"
                              fill="#1F72CD"
                              stroke="#D9D9D9"
                            ></rect>
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M6.681 8.506 3.5 15.753h3.808l.472-1.155h1.08l.472 1.155h4.191v-.882l.374.882h2.168l.374-.9v.9h8.718l1.06-1.125.992 1.125 4.478.01-3.191-3.613 3.19-3.644H27.28l-1.032 1.105-.962-1.105h-9.483l-.815 1.87-.833-1.87h-3.8v.852l-.423-.852H6.68ZM19.7 9.536h5.006l1.532 1.702 1.58-1.703h1.531l-2.326 2.614 2.326 2.583h-1.6l-1.531-1.722-1.589 1.722h-4.929V9.535Zm1.237 2.025v-.949h3.123l1.363 1.517L24 13.656h-3.063v-1.037h2.73v-1.058h-2.73ZM7.418 9.535h1.856l2.11 4.915V9.535h2.034l1.63 3.524 1.502-3.524h2.023v5.2h-1.231l-.01-4.074-1.795 4.074h-1.102l-1.805-4.074v4.074h-2.532l-.48-1.165H7.023l-.479 1.164H5.187l2.231-5.199Zm.048 2.957.855-2.077.854 2.077H7.466Z"
                              fill="#fff"
                            ></path>
                          </svg>
                        </div>
                      </div>

                      {selectedMethod === "card" && address?.length > 0 && (
                        <div className=" mt-6">
                          <PaymentElement />
                        </div>
                      )}
                    </label>
                    <label
                      className={`w-full bg-gray-100 p-4 cursor-pointer border ${
                        selectedMethod === "paypal"
                          ? "border-black"
                          : "border-gray-300 "
                      }`}
                    >
                      <div className="flex  border-gray-300 justify-between items-center gap-3">
                        <div className=" flex items-center gap-2">
                          <input
                            type="radio"
                            name="paymentMethod"
                            value="paypal"
                            checked={selectedMethod === "paypal"}
                            onChange={() => {
                              localStorage.removeItem("clientSecretId");
                              localStorage.removeItem("clientSecretKey");
                              setSelectedMethod("paypal")}}
                            className=" w-5 h-5"
                          />
                          <span className={`text-black font-medium `}>
                            PayPal
                          </span>
                        </div>

                        <div>
                          <img src={Paypal} alt="" />
                        </div>
                      </div>

                      {/* PayPal Payment */}
                      {selectedMethod === "paypal" && (
                        <div className=" w-full flex justify-center  pl-4 mt-8 mx-auto">
                          <div className=" w-full ">
                            <PayPalScriptProvider
                              options={{
                                clientId:
                                  "AaiAjek2ug7UzUcX5mP4GKDsJKZaGSbmn0kHFehtED8KW4ANIc3MM_EwgV1upOlK8D7zPe8L_ypWfYmp",
                                currency: "AUD",
                              }}
                            >
                              <PayPalButtons
                                fundingSource={FUNDING.PAYPAL}
                                style={{ layout: "vertical" }}
                                createOrder={(data, actions) => {
                                  return actions.order.create({
                                    purchase_units: [
                                      {
                                        amount: {
                                          value: (
                                            Number(totalPrice) +
                                            Number(totalDelivery) -
                                            Number(
                                              (Number(totalPrice) *
                                                Number(
                                                  localStorage.getItem(
                                                    "discount"
                                                  )
                                                )) /
                                                100
                                            )
                                          ).toFixed(2),
                                          currency_code: "AUD",
                                        },
                                      },
                                    ],
                                  });
                                }}
                                onApprove={async (data, actions) => {
                                  try {
                                    const details =
                                      await actions.order.capture();
                                    const newCaptureId =
                                      details?.purchase_units[0]?.payments
                                        ?.captures[0]?.id;
                                    console.log(
                                      "Captured Payment, Capture ID:",
                                      newCaptureId
                                    );

                                    if (!newCaptureId) {
                                      toast.error(
                                        "No Capture ID received. Please try again."
                                      );
                                      return;
                                    }

                                    const params = {
                                      // paymentIntentId: null,
                                      payPalId: newCaptureId,
                                      accountType: "paypal",
                                      userId: storedUser,
                                      discount:
                                        localStorage.getItem("discount"),
                                      tax: "",
                                      deliveryCharges: totalDelivery,
                                      subTotalBill: totalPrice,
                                      totalBill:
                                        Number(totalPrice) +
                                        Number(totalDelivery) -
                                        Number(
                                          (Number(totalPrice) *
                                            Number(
                                              localStorage.getItem("discount")
                                            )) /
                                            100
                                        ),
                                      fromDeliverDay: moment(Date()).format(
                                        "LL"
                                      ),
                                      toDeliverDay: getFormattedDate(4),
                                      firstName: selectedAddress?.firstName,
                                      phone: selectedAddress?.phone,
                                      lastName: selectedAddress?.lastName,
                                      businessName:
                                        selectedAddress?.businessName,
                                      addressLine1:
                                        selectedAddress?.addressLine1,
                                      addressLine2:
                                        selectedAddress?.addressLine2,
                                      city: selectedAddress?.city,
                                      comment: selectedAddress?.comment,
                                      trackingNo: "",
                                      trackingLink: "",
                                      status: "pending",
                                      paymentStatus: "Completed",
                                    };

                                    axios
                                      .post(`${Base_url}/order/create`, params)
                                      .then((res) => {
                                        if (res?.data?.status === "ok") {
                                          toast.success(
                                            "Order created successfully!"
                                          );
                                          setLoading(false);
                                          allCart?.forEach((item) => {
                                            const parms = {
                                              orderId: res?.data?.data?.id,
                                            };
                                            axios
                                              .put(
                                                `${Base_url}/cart/update/${item?.id}`,
                                                parms
                                              )
                                              .then((res) => {
                                                if (
                                                  res?.data?.status === "ok"
                                                ) {
                                                  setLoading(false);
                                                  localStorage.removeItem(
                                                    "clientSecretId"
                                                  );
                                                  localStorage.removeItem(
                                                    "clientSecretKey"
                                                  );
                                                  localStorage.setItem(
                                                    "discount",
                                                    0
                                                  );
                                                  navigate(
                                                    "/users/order_history"
                                                  );
                                                }
                                              })
                                              .catch((errors) => {
                                                console.log(errors);
                                                setLoading(false);
                                              });
                                          });
                                        } else {
                                          toast.error("Failed to create order");
                                          setLoading(false);
                                        }
                                      });
                                  } catch (error) {
                                    console.error("Error:", error);
                                    toast.error(
                                      "Payment failed. Please try again."
                                    );
                                  }
                                }}
                                onError={(err) => {
                                  console.error("PayPal error:", err);
                                  toast.error(
                                    "An error occurred with PayPal. Please try again."
                                  );
                                }}
                              />
                            </PayPalScriptProvider>
                          </div>
                        </div>
                      )}
                    </label>

                    <label
                      className={`w-full bg-gray-100 p-4 cursor-pointer border ${
                        selectedMethod === "afterpay_clearpay"
                          ? "border-black"
                          : "border-gray-300 "
                      }`}
                    >
                      <div className="flex  border-gray-300 justify-between items-center gap-3">
                        <div className=" flex items-center gap-2">
                          <input
                            type="radio"
                            name="paymentMethod"
                            value="afterpay_clearpay"
                            checked={selectedMethod === "afterpay_clearpay"}
                            onChange={() => {
                              localStorage.removeItem("clientSecretId");
                              localStorage.removeItem("clientSecretKey");
                              setSelectedMethod("afterpay_clearpay");
                            }}
                            className=" w-5 h-5"
                          />
                          <span className={`text-black font-medium `}>
                            afterpay / clearpay
                          </span>
                        </div>

                        <div>
                          <img src={afterpay_clearpay} alt="" />
                        </div>
                      </div>

                      {/* PayPal Payment */}
                      {selectedMethod === "afterpay_clearpay" && (
                        <div className=" mt-6">
                          <PaymentElement />
                        </div>
                      )}
                    </label>

                    <label
                      className={`w-full bg-gray-100 p-4 cursor-pointer border ${
                        selectedMethod === "klarna"
                          ? "border-black"
                          : "border-gray-300 "
                      }`}
                    >
                      <div className="flex  border-gray-300 justify-between items-center gap-3">
                        <div className=" flex items-center gap-2">
                          <input
                            type="radio"
                            name="paymentMethod"
                            value="klarna"
                            checked={selectedMethod === "klarna"}
                            onChange={() => {
                              localStorage.removeItem("clientSecretId");
                              localStorage.removeItem("clientSecretKey");
                              setSelectedMethod("klarna")}}
                            className=" w-5 h-5"
                          />
                          <span className={`text-black font-medium `}>
                            klarna
                          </span>
                        </div>

                        <div>
                          <img src={klarna} className=" w-16" alt="" />
                        </div>
                      </div>

                      {/* PayPal Payment */}
                      {selectedMethod === "klarna" && (
                        <div className=" mt-6">
                          <PaymentElement />
                        </div>
                      )}
                    </label>
                  </div>
                </div>

                {Object.keys(errors).length > 0 && (
                  <div className="mb-4 text-red text-sm">{/* {errors} */}</div>
                )}
                <button
                  onClick={ordersReplace}
                  className={`w-full  bg-black  cursor-pointer text-white px-4 py-2 rounded `}
                >
                  {loading ? (
                    <div className=" flex gap-2 justify-center items-center">
                      <div
                        className="h-5 w-5 border-2 border-t-2 border-white border-t-transparent rounded-full animate-spin"
                        role="status"
                      ></div>
                      loading...
                    </div>
                  ) : (
                    "Submit"
                  )}
                </button>
              </form>
            </div>
          </div>

          {/* Right Column */}
          <div className="border border-gray-300 h-48 rounded-lg p-4 bg-gray-50">
            <h3 className="font-semibold text-lg mb-4">Order Summary</h3>
            <div className="flex justify-between items-center mb-2">
              <p className="text-sm text-gray-700">
                Item subtotal ({allCart?.length} item)
              </p>
              <p className="text-sm font-medium text-gray-900">
                $ {totalPrice} AUD
              </p>
            </div>

            <div className="flex justify-between items-center mb-2">
              <p className="text-sm text-gray-700">Saving</p>
              <p className="text-sm font-medium text-gray-900">
                $
                {(Number(totalPrice) *
                  Number(localStorage.getItem("discount"))) /
                  100}{" "}
                AUD
              </p>
            </div>

            <div className="flex justify-between items-center mb-2">
              <p className="text-sm text-gray-700">Delivery</p>
              <p className="text-sm font-medium text-gray-900">
                ${totalDelivery} AUD
              </p>
            </div>

            <div className="flex justify-between items-center mt-4">
              <h4 className="font-medium text-gray-900">Total (incl. GST)</h4>
              <p className="text-lg font-bold">
                {`$${
                  Number(totalPrice) +
                  Number(totalDelivery) -
                  Number(
                    (Number(totalPrice) *
                      Number(localStorage.getItem("discount"))) /
                      100
                  )
                }`}{" "}
                AUD
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

const Checkout = ({}) => {
  const clientSecret = localStorage.getItem("clientSecretKey");
  const [selectedMethod, setSelectedMethod] = useState("card");

  return (
    <>
      <Elements
        stripe={stripePromise}
        options={
          clientSecret
            ? { clientSecret: clientSecret }
            : {
                mode: "payment",
                amount: 1000,
                currency: "aud",
                paymentMethodTypes: [selectedMethod],
              }
        }
      >
        <StripeProviderAfterpay
          selectedMethod={selectedMethod}
          setSelectedMethod={setSelectedMethod}
        />
      </Elements>
    </>
  );
};

export default Checkout;
