import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Base_url } from "../../utils/Base_url";
import { Link, useNavigate } from "react-router-dom";
import Button from "../Button";
import { toast } from "react-toastify";
import { FaMinus, FaPlus } from "react-icons/fa";
import ProductDetailsDrawer from "../cards/ProductDetailsDrawer";
import Swal from "sweetalert2";

const SideDrawer = ({ isOpen, setIsOpen }) => {
  const { productData } = useSelector((state) => state.next);

  console.log(productData);
  const User = localStorage.getItem("user_ID") || undefined;
  const [AllCart, setAllCart] = useState([]);
  const [getData, setGetData] = useState({});
  console.log(AllCart, "allCarts");

  const [selColor, setSelColor] = useState(null);
  const [openProduct, setOpenProduct] = useState(false);
  const [checkState, setCheckState] = useState(false);
  const [quantityState, setStateQuantity] = useState(false);
  const [cartDetails, setCartDetails] = useState({});

  console.log(openProduct);

  const [youAlso, setYouAlsoLike] = useState([]);

  // Fetch cart data
  const fetchCartData = () => {
    axios
      .get(`${Base_url}/cart/getBuy?userId=${User}`)
      .then((res) => {
        const cartData = res?.data?.data || [];
        setAllCart(cartData);

        const lastIndex = cartData.length > 0 ? cartData.length - 1 : null;
        const lastCategoryId =
          lastIndex !== null ? cartData[lastIndex]?.categoryId : null;

        axios
          .get(
            `${Base_url}/product/get?limit=10&categoryIds=[${
              lastCategoryId ? lastCategoryId : 2
            }]`
          )
          .then((resp) => {
            console.log(resp?.data?.data, "==============>>>>>>>>>sub");
            setYouAlsoLike(resp?.data?.data);
          })
          .catch((error) => {
            console.error("Error fetching related products:", error);
          });
      })
      .catch((error) => {
        console.error("Error fetching cart data:", error);
      });
  };

  // Fetch wishlist data
  const fetchWishlistData = () => {
    axios
      .get(`${Base_url}/product/getWishlist/${User}`)
      .then((res) => {
        setAllLikes(res?.data?.data);
      })
      .catch((error) => {
        console.error("Error fetching wishlist data:", error);
      });
  };

  useEffect(() => {
    if (isOpen) {
      fetchCartData();
      fetchWishlistData();
    }
  }, [isOpen]);

  const [allLikes, setAllLikes] = useState([]);

  useEffect(() => {
    axios
      .get(`${Base_url}/product/getWishlist/${User}`)
      .then((res) => {
        console.log(res);

        setAllLikes(res?.data?.data, "all products");
      })
      .catch((error) => {});
  }, []);

  const navigate = useNavigate();

  const [quantity, setQuantity] = useState(1);

  const [loading, setLoading] = useState(false);

  const totalPrice = AllCart?.reduce((acc, product) => {
    return acc + product?.totalOriginalPrice;
  }, 0);

  console.log("Total Price:", totalPrice);

  const handleUpdateQuantity = async (
    type,
    id,
    quantity,
    sellPrice,
    originalPrice
  ) => {
    setLoading(true);
    const newQuantity = type === "increment" ? quantity + 1 : quantity - 1;

    if (newQuantity < 1) {
      // toast.error("Quantity cannot be less than 1");
      // setLoading(false);
      // return;


      axios
      .delete(`${Base_url}/cart/delete/${id}`)
      .then((res) => {
        if (res.status === 200) {
          // Swal.fire(
          //   "Deleted!",
          //   "Your product has been deleted.",
          //   "success"
          // );
          
          axios
          .get(`${Base_url}/cart/getBuy?userId=${User}`)
          .then((res) => {
            console.log(res);

            setAllCart(res.data.data, "all products");
          })
          .catch((error) => {});
         
        }
      })
      .catch((error) => {
        console.log(error);
      });

    }

    try {
      const response = await axios.put(`${Base_url}/cart/update/${id}`, {
        quantity: newQuantity,
        totalSellPrice: Number(sellPrice) * Number(newQuantity),
        totalOriginalPrice: Number(originalPrice) * Number(newQuantity),
      });

      if (response.data.status === "ok") {
        setQuantity(newQuantity);
        // toast.success("Quantity updated successfully");

        axios
          .get(`${Base_url}/cart/getBuy?userId=${User}`)
          .then((res) => {
            console.log(res);

            setAllCart(res.data.data, "all products");
          })
          .catch((error) => {});
      } else {
        toast.error("Failed to update quantity");
      }
    } catch (error) {
      console.error("Error updating quantity:", error);
      toast.error("An error occurred while updating quantity");
    } finally {
      setLoading(false);
    }
  };

  const addFun = async (item) => {
    if (!User) {
      toast.error("Please login to your account");
      navigate("/login");
    } else {
      if (!selColor || item?.colorName?.length === 0) {
        toast.error("Please select a color before adding to cart");
        return;
      }

      try {
        setLoading(true);
        const params = {
          userId: User,
          productId: item?.id,
          categoryId: item?.category?.id,
          subCategoryId: item?.subCategory?.id,
          subSubCategoryId: item?.subSubCategory?.id,
          deliveryCharge: item?.deliveryType === "Free" ? 0 : 12,
          quantity: item?.hasFreeProduct?quantity+1:quantity,
          isBuy: true,
          totalSellPrice: Number(item?.sellPrice) * Number(quantity),
          totalOriginalPrice: Number(item?.specialPrice?item?.specialPrice:item?.originalPrice) * Number(quantity),
          color: selColor,
        };

        const response = await axios.post(`${Base_url}/cart/create`, params);

        if (response.status === 200 || response.status === 201) {
          // toast.success("Added to Cart Successfully!");
          setLoading(false);
          setStateQuantity(true);
          setOpenProduct(true);
          setIsOpen(false);

          axios
            .get(
              `${Base_url}/cart/checkProd?productId=${item?.id}&userId=${User}`
            )
            .then((res) => {
              console.log(res, "==============>>>>>>>>>sub check status");

              if (res?.data?.available === false) {
                setCheckState(false);
              } else {
                setCheckState(true);
                setCartDetails(res?.data?.data);
              }
            })
            .catch((error) => {
              console.error("Error fetching cart details:", error);
            });

            fetchCartData();
            fetchWishlistData();

            
        } else {
          setLoading(false);
          toast.error("Failed to add to cart. Please try again.");
        }
      } catch (error) {
        if (error?.response?.data?.status === "fail") {
          toast.error(error?.response?.data?.message);
          navigate("/login");
        }
        setLoading(false);
      }
    }
  };


  
  return (
    <div
    onClick={() => setIsOpen(false)}

      className={`fixed top-0 left-0 h-full  w-full bg-[rgba(0,0,0,0.3)] shadow-lg z-50 transform ${
        isOpen ? "translate-x-0" : "-translate-x-full"
      } transition-transform duration-300 ease-in-out`}
    >
      <div className=" w-80 bg-white h-full" onClick={(e)=>e.stopPropagation()}>
        {/* Drawer Header */}
        <div className="flex items-center justify-between p-4 border-b">
          <h2 className="text-lg font-semibold">Your Cart</h2>
          <button
            onClick={() => setIsOpen(false)}
            className="text-gray-500 hover:text-gray-700"
          >
            ✕
          </button>
        </div>

        <ProductDetailsDrawer
          getData={getData}
          setIsOpen={setOpenProduct}
          isOpen={openProduct}
          setRefresh={setAllCart}
        />

        {/* Drawer Content */}
        <div className="p-4 space-y-4 overflow-y-auto h-96  ">
          {/* Example Cart Items */}
          {AllCart?.map((item, index) => {
            return (
              <>
                <div className="flex items-center  space-x-4">
                  <div  className=" w-3/12">
                  <img
                    src={item?.product?.Image1}
                    alt="Product"
                    className="w-12 h-12   object-contain rounded"
                  />
                  </div>
                  <div className=" w-9/12">
                    <h4 className="font-medium text-sm">
                      {item?.product?.name}
                    </h4>
                    <p className="  pb-3 flex gap-2">
                      <span className=" flex items-center">
                        <sup className=" font-semibold"> $ </sup>{" "}
                        <span className=" text-xl font-semibold">
                          {item?.product?.specialPrice?item?.product?.specialPrice:item?.product?.originalPrice} 
                        </span>
                        <p className="  pl-0.5 font-medium text-sm">AUD</p>
                      </span>
                      <div>
                        
                        {item?.product?.specialPrice?<span className="bg-yellow-500 text-black font-bold text-[11px] px-1.5 py-1 rounded">
                  SAVE ${item?.product?.originalPrice - item?.product?.specialPrice} AUD
                </span>:null}
                      </div>
                    </p>

                    <div className=" flex w-28 items-center">
                      <button
                        onClick={() =>
                          handleUpdateQuantity(
                            "decrement",
                            item?.id,
                            item?.quantity,
                            item?.product?.sellPrice,
                            item?.product?.specialPrice?item?.product?.specialPrice:item?.product?.originalPrice
                          )
                        }
                        className=" flex justify-center   text-white items-center w-16 h-8 bg-primary"
                      >
                        <FaMinus />
                      </button>
                      <input
                        value={item?.quantity}
                        className=" text-center border w-full outline-none  h-8"
                      />
                      <button
                        onClick={() =>
                          handleUpdateQuantity(
                            "increment",
                            item?.id,
                            item?.quantity,
                            item?.product?.sellPrice,
                            item?.product?.specialPrice?item?.product?.specialPrice:item?.product?.originalPrice
                          )
                        }
                        className=" flex justify-center   text-white items-center w-16 h-8  bg-primary"
                      >
                        <FaPlus />
                      </button>
                    </div>
                  </div>
                </div>
                <hr />
              </>
            );
          })}

          {/* Add more items here */}
          <div className="flex justify-between items-center mt-4">
            <span className="font-medium text-lg text-gray-500">Total:</span>
            <span className="font-medium flex items-center  text-lg">${totalPrice}<p  className=" text-sm">AUD</p></span>
          </div>
          <div>
            <h2 className=" text-lg">You may also like:</h2>
            {youAlso?.map((item, index) => {
              return (
                <>
                  <div className="flex items-center  py-3 space-x-4">
                    <div className=" w-3/12">
                      <img
                        src={item?.Image1}
                        alt="Product"
                        className=" w-full object-cover"
                      />
                    </div>
                    <div className=" w-9/12">
                      <h4 className="font-medium text-[13px]">{item?.name}</h4>
                    
                      <p className="  pb-3 flex gap-2">
                      <span className=" flex items-center">
                        <sup className=" font-semibold"> $ </sup>{" "}
                        <span className=" text-xl font-semibold">
                          {item?.specialPrice?item?.specialPrice:item?.originalPrice}
                        </span> <p className=" font-medium text-sm pl-0.5">AUD</p>
                      </span>
                      <div>
                      {item?.specialPrice?<span className="bg-yellow-500 text-black font-bold text-[11px] px-1.5 py-1 rounded">
                  SAVE ${item?.originalPrice - item?.specialPrice} AUD
                </span>:null}
                      </div>
                    </p>
                      {checkState === true ? (
                        <>
                          <div className=" flex w-40  items-center">
                            <button
                              onClick={() =>
                                handleUpdateQuantity(
                                  "decrement",
                                  cartDetails?.id,
                                  cartDetails?.quantity
                                )
                              }
                              disabled={loading}
                              className=" flex justify-center   text-white items-center w-20 h-10  bg-primary"
                            >
                              <FaMinus />
                            </button>
                            <input
                              value={cartDetails?.quantity}
                              className=" text-center border w-full outline-none  p-2"
                            />
                            <button
                              onClick={() =>
                                handleUpdateQuantity(
                                  "increment",
                                  cartDetails?.id,
                                  cartDetails?.quantity
                                )
                              }
                              disabled={loading}
                              className=" flex justify-center   text-white items-center w-20 h-10  bg-primary"
                            >
                              <FaPlus />
                            </button>
                          </div>
                        </>
                      ) : item?.colorName?.length > 0 ? (
                        <button
                          onClick={() => {
                            // setIsOpen(false);
                            setOpenProduct(true);
                            setGetData(item);
                          }}
                          className="py-1.5 px-3 inline-block   font-medium tracking-wide align-middle duration-500 text-sm text-center hover:bg-black hover:text-white  text-black border  border-black rounded-sm"
                        >
                          {loading ? (
                            <div className="flex items-center justify-center">
                              <svg
                                className="animate-spin h-6 w-6 text-gradient-to-r from-blue-500 to-purple-500"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                              >
                                <circle
                                  className="opacity-25"
                                  cx="12"
                                  cy="12"
                                  r="10"
                                  stroke="currentColor"
                                  strokeWidth="4"
                                ></circle>
                                <path
                                  className="opacity-75"
                                  fill="currentColor"
                                  d="M4 12a8 8 0 018-8v4a4 4 0 100 8h4a8 8 0 01-8 8z"
                                ></path>
                              </svg>
                              Loading...
                            </div>
                          ) : (
                            "Select options"
                          )}
                        </button>
                      ) : (
                        <button
                          onClick={() => addFun(item)}
                          className="py-1.5 px-5 inline-block   font-medium tracking-wide align-middle duration-500 text-base text-center hover:bg-black hover:text-white  text-black border  border-black rounded-sm"
                        >
                          {loading ? (
                            <div className="flex items-center justify-center">
                              <svg
                                className="animate-spin h-6 w-6 text-gradient-to-r from-blue-500 to-purple-500"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                              >
                                <circle
                                  className="opacity-25"
                                  cx="12"
                                  cy="12"
                                  r="10"
                                  stroke="currentColor"
                                  strokeWidth="4"
                                ></circle>
                                <path
                                  className="opacity-75"
                                  fill="currentColor"
                                  d="M4 12a8 8 0 018-8v4a4 4 0 100 8h4a8 8 0 01-8 8z"
                                ></path>
                              </svg>
                              Loading...
                            </div>
                          ) : (
                            "Add to Cart"
                          )}
                        </button>
                      )}
                    </div>
                  </div>
                  <hr />
                </>
              );
            })}
          </div>
        </div>

        <div className=" p-4">
          <Link
            to="/checkout"
            class="flex w-full mt-10 items-center justify-center rounded-lg bg-[#232323] px-5 py-2.5 text-sm font-medium text-white hover:bg-[#232323] focus:outline-none focus:ring-4 focus:ring-primary-300 :bg-primary-600 :hover:bg-primary-700 :focus:ring-primary-800"
          >
            Proceed to Checkout
          </Link>
          <Link
            to="/cart"
            class="flex w-full mt-2  items-center hover:text-white justify-center rounded-lg text-[#232323]  border border-[#232323] px-5 py-2.5 text-sm font-medium  hover:bg-[#232323] focus:outline-none focus:ring-4 focus:ring-primary-300 :bg-primary-600 :hover:bg-primary-700 :focus:ring-primary-800"
          >
            View Cart
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SideDrawer;
