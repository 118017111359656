import React, { useEffect, useState } from "react";
import { CiHeart, CiSearch, CiUser } from "react-icons/ci";
import { IoBagHandleSharp } from "react-icons/io5";
import { RxHamburgerMenu } from "react-icons/rx";
import TopNav from "./TopNav";
import { Link } from "react-router-dom";
import axios from "axios";
import SearchProductModal from "./SearchProductModal";
import { Base_url } from "../../utils/Base_url";
import SideDrawer from "./SideDrawer";

const Navbar = ({}) => {
  const User = localStorage.getItem("user_ID") || undefined;
  const [AllCart, setAllCart] = useState([]);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const links = [
    {
      id: 1,
      name: "Home",
      Url: "/",
    },
    {
      id: 2,
      name: "Shop",
      Url: "/shop",
    },
    {
      id: 2,
      name: "Catalogues",
      Url: "/catalogues",
    },
    {
      id: 2,
      name: "Clearance",
      Url: "/clearance",
    },
    {
      id: 3,
      name: "Deals",
      Url: "/deals",
    },
    // {
    //   id:3,
    //   name:'Blog',
    //   Url:'/sustainability'
    // },
    // {
    //   id:4,
    //   name:'About Us',
    //   Url:'/'
    // },
    {
      id: 4,
      name: "Contact Us",
      Url: "/contact_us",
    },
  ];

  const [allProduct, setAllProduct] = useState([]);
  const [allWishlist, setAllWishlist] = useState([]);

  useEffect(() => {
    axios
      .get("https://fakestoreapi.com/products")
      .then((res) => {
        console.log(res);

        setAllProduct(res.data, "all products");
      })
      .catch((error) => {});

    axios
      .get(`${Base_url}/cart/getBuy?userId=${User}`)
      .then((res) => {
        console.log(res);

        setAllCart(res.data.data, "all products");
      })
      .catch((error) => {});



    axios
    .get(`${Base_url}/product/getWishlist/${User}`)
    .then((res) => {
      console.log(res);

      setAllWishlist(res?.data?.data, "all products");
    })
    .catch((error) => {});
 

  }, []);

  const iconLinks = [
    {
      href: "/for_you",
      icon: <CiHeart className="h-6 w-6" />,
      text: "Wishlist",
      span: (
        <div className=" absolute w-5 -top-3 -right-1 text-white  h-5 bg-red  rounded-full text-sm flex justify-center items-center">
          {allWishlist?.length}
        </div>
      ),
    },
    {
      // href: "/",
      icon: <IoBagHandleSharp className="h-6 w-6" />,
      text: "Cart",
      onClick: () => setIsOpen(true),
      span: (
        <div className=" absolute w-5 -top-3 -right-2 text-white  h-5  bg-red  rounded-full text-sm flex justify-center items-center">
          {AllCart?.length}
        </div>
      ),
    },
    {
      href: "/users/my_profile",
      icon: <CiUser className="h-6 w-6" />,
      text: "Account",
    },
  ];

  const [isOpen, setIsOpen] = useState(false);

  const [isOpenModal, setIsOpenModal] = useState(false);
  return (
    <>
      <TopNav />
      <SearchProductModal
        setIsModalOpen={setIsOpenModal}
        isModalOpen={isOpenModal}
      />
      <header className="mx-auto flex   container items-center justify-between px-5">
        <Link to="/" className="w-24">
          <img
            width={180}
            height={40}
            className="cursor-pointer sm:h-auto sm:w-auto"
            src={require("../../assets/images/logo.jpeg")}
            alt="company logo"
          />
        </Link>

        <form className="hidden h-11 w-2/4 rounded-md overflow-hidden items-center border md:flex">
          <CiSearch className="mx-3" size={20} />

          <input
            onClick={() => setIsOpenModal(true)}
            className="hidden w-11/12 outline-none md:block"
            type="search"
            placeholder="Search"
          />
          <button className="ml-auto text-white h-full bg-[#272727] px-4">
            Search
          </button>
        </form>

        <div className=" flex items-center gap-4">
          <div className=" gap-3 flex">
            {iconLinks.map((link, index) => (
              <Link
                key={index}
                to={link.onClick ? null : link.href}
                onClick={link.onClick || null}
                className="flex cursor-pointer flex-col items-center relative justify-center"
              >
                {link.icon}
                <p className="text-xs">{link.text}</p>

                {link?.span}
              </Link>
            ))}
          </div>
          <div className="md:hidden">
            <button onClick={() => setMobileMenuOpen(!mobileMenuOpen)}>
              <RxHamburgerMenu className="w-7 h-7" />
            </button>
          </div>
        </div>
      </header>
      {mobileMenuOpen && (
        <section
          className={`block md:hidden absolute left-0 right-0 z-40 h-screen w-full bg-white ${
            mobileMenuOpen ? "none" : "block"
          }`}
        >
          <div className="mx-auto">
           
              <form className=" h-12 w-[95%] mx-auto my-4 rounded-md overflow-hidden items-center border flex">
          <CiSearch className="mx-3" size={20} />

          <input
            onClick={() => setIsOpenModal(true)}
            className=" w-11/12 outline-none"
            type="search"
            placeholder="Search"
          />
          <button className="ml-auto text-white h-full bg-[#272727] px-4">
            Search
          </button>
        </form>
            <ul className="text-center font-medium">
              {links.map((link, index) => (
                <li key={index} className="py-2 text-black">
                  <Link className=" text-black" to={link.Url}>
                    {link.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </section>
      )}

      <SideDrawer isOpen={isOpen} setIsOpen={setIsOpen} />
    </>
  );
};

export default Navbar;
