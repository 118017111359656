import React, { useEffect, useState } from 'react'
import Navbar from '../../components/Header/Navbar'
import Footer from '../../components/footer'
import BottomHeader from '../../components/Header/BottomHeader'
import Button from '../../components/Button'
import { Link, useParams } from 'react-router-dom'
import axios from 'axios'
import { Base_url } from '../../utils/Base_url'

const Sustainability = () => {
 
  const {id} = useParams();

  const [allBlogCategory, setAllBlogCategory] = useState([]);

  const [allMainBlogs,setAllMainBlogs] = useState([]);

  useEffect(() => {
    const fetchSlider = async () => {
      try {
        const response = await axios.get(`${Base_url}/blogCategory/get/${id}`);

        console.log(response, "response");

        setAllBlogCategory(response?.data?.data);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };



    const fetchBlogs = async () => {
      try {
        const response = await axios.get(`${Base_url}/blog/get?blogCategoryId=${id}`);

        console.log(response, "response");

        setAllMainBlogs(response?.data?.data);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchSlider();
    fetchBlogs();
  }, []);

  return (
    <>
     <Navbar/>
     <BottomHeader/>
     <section className=' h-[60vh]'>
        <img src={`${allBlogCategory?.image}`} className=' h-full object-center object-cover w-full' alt='' />
     </section>

     <section className=' py-10'>
        {/* <div className=' container px-4 mx-auto'>
        <h1 className=' text-center text-3xl'>Our People Goals</h1>
        <div className=' grid sm:grid-cols-2 grid-cols-1 mt-12 gap-10'>
            <div>
            <button className=' py-3 sm:text-2xl text-xl bg-green rounded-full text-white'>Have a positive impact on your customers and communities</button>
            <p className=' text-center py-5'>We want to have a positive impact on the communities we serve. That’s why BIG W is investing in local programs to help those in need.

</p>

            </div>
            <div className=''>
            <button className=' py-6 sm:text-2xl text-xl w-full bg-green rounded-full text-white'>Be a truly inclusive workplace</button>
            <p  className=' text-center py-5'>We believe in creating a workplace that is safe and inclusive so every team member feels like they belong and can be their best self.

</p>
            </div>
        </div>

        <button className=' py-6 sm:text-2xl text-xl w-full bg-green rounded-full text-white'>Activate ethical and mutually beneficial partnerships through the whole value chain</button>
        <p  className=' text-center py-5'>We are working with our suppliers to build a rights-respecting culture where modern slavery risks are identified, managed and mitigated.

</p>
        </div> */}

       <div className=' w-8/12 mx-auto'>
          
          <div className=' grid mt-6 sm:grid-cols-2 grid-cols-1 gap-5'>
            {allMainBlogs?.map((item,index)=>{
              return (
                <div className=' w-full text-center'>
                <div className=' w-full h-72'>
                <img src={item?.image} className=' w-full h-full object-cover object-center' alt='' />
                </div>
                <h3 className=' text-black text-lg pt-2'>{item?.name}</h3>
                <p className=' text-gray-500 pt-2'>{item?.subTitle}</p>
                <Link to={`/sustainability_details/${item?.id}`} >
                <Button label={'Discover more'} className={' bg-primary py-3 mx-auto mt-4 text-white'} />
                </Link>
                
            </div>
              )
            })}
         
       
          </div>
       
       </div>

     </section>
      
     <Footer/>
    </>
  )
}

export default Sustainability