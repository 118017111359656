import React, { useState } from "react";
import Modal from "../../components/modal";
import Button from "../../components/Button";
import { MdClose } from "react-icons/md";
import Input from "../../components/Input";
import { useForm } from "react-hook-form";
import axios from "axios";
import { Base_url } from "../../utils/Base_url";
import { FaRegStar, FaStar } from "react-icons/fa";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const WriteReviews = ({
  isModalOpen,
  setIsModalOpen,
  rating,
  getData,
  setRating,
  setRatings,
}) => {
  console.log(rating);
  console.log(getData);
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const storedUser = localStorage.getItem("user_ID");

  let id;
  try {
    id = storedUser ? JSON.parse(storedUser) : {};
  } catch (error) {
    console.error("Error parsing JSON:", error);
  }

  const onSubmit = async (data) => {
    if (!storedUser) {
      navigate('/login');
    } else {

      setLoading(true);
      const parms = {
        userId: storedUser,
        star: rating,
        review: data?.review,
        productId: getData?.id,
        userId: storedUser,
      };

      try {
        const res = await axios.post(`${Base_url}/rating/create`, parms);
        if (res.data.status === "ok") {
          toast.success("Review added successfully!");
          setIsModalOpen(false);
          getData("");

          axios
            .get(`${Base_url}/rating/get?productId=${getData?.id}`)
            .then((res) => {
              console.log(res);

              setRating(res.data, "all products rating");


              axios.get(`${Base_url}/rating/get?productId=${id}`)
                .then((resp) => {
                  console.log(resp);

                  setRatings(resp.data);
                })
                .catch((error) => { });


            })
            .catch((error) => { });

        }
      } catch (error) {
        console.error(error);
        // toast.error("Please register your account first");
        //  navigate('/login')
      } finally {
        setLoading(false);
      }

    }

  };

  const [hover, setHover] = useState(null);


  return (
    <Modal
      isOpen={isModalOpen}
      onClose={() => setIsModalOpen(false)}
      className=" w-[50%]"
    >
      <div className="p-3">
        <div className="flex justify-between items-center">
          <div className=" flex items-center gap-2">
            <img src={getData?.Image1} className=" w-12 object-cover" alt="" />
            <div>
              <h1 className="capitalize  font-normal text-2xl">My Review</h1>
              <p>The Order Of Masks by {getData?.brand?.name}</p>
            </div>
          </div>

          <MdClose size={25} onClick={() => setIsModalOpen(false)} />
        </div>
        <hr />
        <form onSubmit={handleSubmit(onSubmit)} className="p-5">
          <div className="flex gap-5 flex-wrap">
            <div>
              <ul className="py-3 flex gap-2 items-center">
                {[1, 2, 3, 4, 5].map((star) => (
                  <li
                    key={star}
                    className={`w-14 h-14 flex justify-center items-center border   border-orange rounded-md cursor-pointer transition duration-300 ${star <= (hover || rating)
                        ? "   bg-orange text-white"
                        : "  text-orange"
                      }`}
                    onClick={() => {
                      setRating(star);
                    }}
                    onMouseEnter={() => setHover(star)}
                    onMouseLeave={() => setHover(null)}
                  >
                    {star <= (hover || rating) ? (
                      <FaStar size={24} />
                    ) : (
                      <FaRegStar size={24} />
                    )}
                  </li>
                ))}
              </ul>
              <p>{rating} out of 5 stars selected. Product is Average.</p>
            </div>
            <div className="w-full">
              <label className="  text-lg">Review*</label>
              <textarea
                placeholder=""
                className={`border outline-none p-3 rounded-sm w-full py-3 ${errors.review ? "border-red" : ""
                  }`}
                {...register("review", { required: "Review is required" })}
              ></textarea>
              {errors.review && (
                <p className="text-red text-sm">{errors.review.message}</p>
              )}
            </div>
          </div>
          <Button
            label={
              loading ? (
                <div className="flex items-center gap-2">
                  <div className="loader w-4 h-4 border-2 border-white border-t-transparent rounded-full animate-spin"></div>
                  Submitting...
                </div>
              ) : (
                "Submit"
              )
            }
            disabled={loading}
            className={`mt-3 py-2 ${loading ? "bg-gray-400 cursor-not-allowed" : "bg-black text-white"
              }`}
          />
        </form>
      </div>
    </Modal>
  );
};

export default WriteReviews;
