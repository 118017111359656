import axios from "axios";
import React, { useEffect, useState } from "react";
import { FaHeart, FaMinus, FaPlus, FaRegHeart } from "react-icons/fa";
import { IoMdCheckmark } from "react-icons/io";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Base_url } from "../../utils/Base_url";
import SideDrawer from "../Header/SideDrawer";

const ProductDetailsDrawer = ({ isOpen, setIsOpen, getData, setRefresh, setWishList }) => {
  const navigate = useNavigate();
  const { productData } = useSelector((state) => state.next);
  const storedUser = localStorage.getItem("user_ID") || undefined;
  const [selColor, setSelColor] = useState(null);
  const [openProduct, setOpenProduct] = useState(false);
  const [checkState, setCheckState] = useState(false);
  const [quantityState, setStateQuantity] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [cartDetails, setCartDetails] = useState({});
  const [isLiked, setIsLiked] = useState(getData?.like?.includes(storedUser));
  const [loading, setLoading] = useState(false);
  const [loadinglike, setLoadingLike] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setIsLiked(getData?.like?.includes(storedUser));
      setSelColor(null);
      setQuantity(1);
      setCheckState(false);
      setStateQuantity(false);
    }
  }, [isOpen, getData]);

  const handleLikeDislike = async () => {
    if (!storedUser) {
      toast.error('Please register your account');
      navigate('/login');
      return;
    }

    try {
      setLoadingLike(true);
      const param = {
        id: getData.id,
        userId: storedUser,
      };

      const response = await axios.post(`${Base_url}/product/wishlist`, param);

      if (response.data.status === "ok") {
        toast.success(response?.data?.message);
        setIsLiked(!isLiked);
        setWishList();
      } else if (response.data.status === "fail") {
        toast.error(response?.data?.message);
        navigate("/login");
      }
    } catch (error) {
      console.error(error);
      toast.error(error?.response?.message);
    } finally {
      setLoadingLike(false);
    }
  };

  const addFun = async () => {
    if (!storedUser) {
      toast.error("Please login to your account");
      navigate("/login");
      return;
    }

    if (!selColor && getData?.colorName?.length > 0) {
      toast.error("Please select a color before adding to cart");
      return;
    }

    try {
      setLoading(true);
      const params = {
        userId: storedUser,
        productId: getData?.id,
        categoryId: getData?.category?.id,
        subCategoryId: getData?.subCategory?.id,
        subSubCategoryId: getData?.subSubCategory?.id,
        deliveryCharge: getData?.deliveryType === "Free" ? 0 : 12,
        quantity: getData?.hasFreeProduct?quantity+1:quantity,
        isBuy: true,
        totalSellPrice: Number(getData?.sellPrice) * Number(quantity),
        totalOriginalPrice: Number(getData?.specialPrice?getData?.specialPrice:getData?.originalPrice) * Number(quantity),
        color: selColor,
      };

      const response = await axios.post(`${Base_url}/cart/create`, params);

      if (response.status === 200 || response.status === 201) {
        // toast.success("Added to Cart Successfully!");
        setStateQuantity(true);
        setOpenProduct(true);
        setIsOpen(false);
        setRefresh && setRefresh();

        const cartResponse = await axios.get(
          `${Base_url}/cart/checkProd?productId=${getData?.id}&userId=${storedUser}`
        );

        if (cartResponse?.data?.available === false) {
          setCheckState(false);
        } else {
          setCheckState(true);
          setCartDetails(cartResponse?.data?.data);
        }

        const buyResponse = await axios.get(`${Base_url}/cart/getBuy?userId=${storedUser}`);
        setRefresh(buyResponse?.data?.data || []);
      } else {
        toast.error("Failed to add to cart. Please try again.");
      }
    } catch (error) {
      if (error?.response?.data?.status === "fail") {
        toast.error(error?.response?.data?.message);
        navigate("/login");
      } else {
        toast.error("An error occurred while adding to cart.");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateQuantity = async (type, cid, quantity) => {
    setLoading(true);
    const newQuantity = type === "increment" ? quantity + 1 : quantity - 1;

    if (newQuantity < 1) {
      toast.error("Quantity cannot be less than 1");
      setLoading(false);
      return;
    }

    try {
      const response = await axios.put(`${Base_url}/cart/update/${cid}`, {
        quantity: newQuantity,
        totalSellPrice: Number(getData?.sellPrice) * Number(newQuantity),
        totalOriginalPrice: Number(getData?.specialPrice?getData?.specialPrice:getData?.originalPrice) * Number(newQuantity),
      });

      if (response.data.status === "ok") {
        setQuantity(newQuantity);
        // toast.success("Quantity updated successfully");

        const cartResponse = await axios.get(
          `${Base_url}/cart/checkProd?productId=${getData?.id}&userId=${storedUser}`
        );

        if (cartResponse?.data?.available === false) {
          setCheckState(false);
        } else {
          setCheckState(true);
          setCartDetails(cartResponse?.data?.data);
        }
      } else {
        toast.error("Failed to update quantity");
      }
    } catch (error) {
      console.error("Error updating quantity:", error);
      toast.error("An error occurred while updating quantity");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      onClick={()=>setIsOpen(false)}
      className={`fixed top-0 left-0 h-full w-full bg-[rgba(0,0,0,0.3)] shadow-lg z-50 transform ${
        isOpen ? "translate-x-0" : "-translate-x-full"
      } transition-transform duration-300 ease-in-out`}
    >
      <div onClick={(e)=>e.stopPropagation()} className="w-80 bg-white h-full">
        <div className="flex items-center justify-between p-4 border-b">
          <h2 className="text-lg font-semibold"></h2>
          <button
            onClick={() => setIsOpen(false)}
            className="text-gray-500 hover:text-gray-700"
          >
            ✕
          </button>
        </div>
        <div className="p-3">
          <div className="flex gap-2">
            <div className="w-4/12 relative">
              <div className="pt-3">
                {getData?.Image1 && (
                  <img src={getData?.Image1} className="h-full w-full" alt="" />
                )}
              </div>
              {getData?.tag !== "0" && (
                <div className="bg-black flex justify-center absolute left-0 top-0 items-center py-0.5 text-white w-16">
                  <p className="text-xs">{getData?.tag}</p>
                </div>
              )}
            </div>
            <div className="w-8/12">
              <p className="pb-2 whitespace-break-spaces">{getData?.name}</p>
              <Link
                to={`/product_details/${getData?.id}`}
                className="hover:underline text-blue-600"
              >
                View product details
              </Link>
            </div>
          </div>
          <div className="pt-8 flex pb-6 items-center gap-4">
            <span className="text-black flex items-center font-bold text-2xl">
              <sup>$</sup>{" "}
              <span className="text-5xl m-0"> { getData?.specialPrice?getData?.specialPrice:getData?.originalPrice}</span>
              <sub>AUD</sub>
            </span>
            {getData?.specialPrice?<span className="bg-yellow-500 text-black font-bold text-[11px] px-1.5 py-1 rounded">
                  SAVE ${getData?.originalPrice - getData?.specialPrice}
                </span>:null}
          </div>

          <p className="text-lg">Selected color:</p>
          <ul className="flex gap-4 py-1.5 items-center">
            {getData?.colorName?.map((i) => (
              <div key={i}>
                <li
                  className="w-10 h-10 rounded-full border flex justify-center items-center cursor-pointer"
                  style={{ backgroundColor: i?.toLowerCase() }}
                  onClick={() => setSelColor(i)}
                >
                  {selColor === i && <IoMdCheckmark size={25} className="text-gray-500" />}
                </li>
              </div>
            ))}
          </ul>

          <div className="flex pt-11 gap-3 items-center">
            {checkState ? (
              <div className="flex items-center">
                <button
                  onClick={() =>
                    handleUpdateQuantity("decrement", cartDetails?.id, cartDetails?.quantity)
                  }
                  disabled={loading}
                  className="flex justify-center text-white items-center w-14 h-10 bg-primary"
                >
                  <FaMinus />
                </button>
                <input
                  value={cartDetails?.quantity}
                  className="text-center border w-full outline-none p-2"
                  readOnly
                />
                <button
                  onClick={() =>
                    handleUpdateQuantity("increment", cartDetails?.id, cartDetails?.quantity)
                  }
                  disabled={loading}
                  className="flex justify-center text-white items-center w-14 h-10 bg-primary"
                >
                  <FaPlus />
                </button>
              </div>
            ) : (
              <button
                onClick={addFun}
                disabled={loading || getData?.Status === "Out Of Stock"}
                className={`text-white rounded-md bg-black w-full py-3 ${
                  loading || getData?.Status === "Out Of Stock"
                    ? "opacity-50 cursor-not-allowed"
                    : "hover:bg-gray-800"
                }`}
              >
                {loading ? (
                  <div className="flex items-center justify-center">
                    <svg
                      className="animate-spin h-6 w-6 text-gradient-to-r from-blue-500 to-purple-500"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8v4a4 4 0 100 8h4a8 8 0 01-8 8z"
                      ></path>
                    </svg>
                    Loading...
                  </div>
                ) : getData?.Status === "Out Of Stock" ? (
                  "Out Of Stock"
                ) : (
                  "Add to Cart"
                )}
              </button>
            )}

            <div className="cursor-pointer flex justify-center items-center border w-16 rounded-sm h-12">
              {loadinglike ? (
                <svg
                  className="animate-spin h-6 w-6 text-gradient-to-r from-blue-500 to-purple-500"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8v4a4 4 0 100 8h4a8 8 0 01-8 8z"
                  ></path>
                </svg>
              ) : isLiked ? (
                <FaHeart
                  size={25}
                  onClick={handleLikeDislike}
                  className="text-red-500"
                />
              ) : (
                <FaRegHeart
                  size={25}
                  onClick={handleLikeDislike}
                  className="text-gray-500"
                />
              )}
              <span className="text-sm font-medium">{getData?.likes?.length}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetailsDrawer;