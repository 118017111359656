import React, { useEffect, useState } from "react";
import Input from "../../../components/Input";
import Button from "../../../components/Button";
import { Link, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { Base_url } from "../../../utils/Base_url";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useDispatch } from "react-redux";
import Navbar from "../../../components/Header/Navbar";
import Footer from "../../../components/footer";
import OtpInput from "otp-input-react";
import BottomHeader from "../../../components/Header/BottomHeader";
import { setUser } from "../../../store/authSlice";
import { auth, provider } from "../../../utils/firebase.config";
import {
  getAuth,
  RecaptchaVerifier,
  signInWithPhoneNumber,
  signInWithPopup,
} from "firebase/auth";
import { BsFillShieldLockFill, BsTelephoneFill } from "react-icons/bs";
import { CgSpinner } from "react-icons/cg";
const Login = ({ setIsModalOpen }) => {
  const navigate = useNavigate();
  const [withEmail, setWithEmail] = useState(false);
  const dispatch = useDispatch();
  const [value, setValue] = useState();

  const [loading2, setLoader] = useState(false);
  console.log(value);
  useEffect(() => {
    setValue(localStorage.getItem("email"));
  });

  const handleClick = () => {
    signInWithPopup(auth, provider).then((data) => {
      console.log(data);

      const { user } = data;

      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        email: user.email,
        password: user.uid,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(`${Base_url}/user/login`, requestOptions)
        .then((response) => response.text())
        .then((result) => {
          console.log(result);
          let data = JSON.parse(result);
          console.log("resposne from signup-----", data);

          if (data.status === "ok") {
            toast.success("Login successfully!");
            localStorage.setItem("user_ID", data?.data?.id);
            dispatch(setUser(data?.data));
            navigate("/");
            setLoader(false);
          } else {
            toast.error(data.message);
            setLoader(false);
          }
        })
        .catch((error) => { });
    });
  };

  const SubmitHandler = async (values) => {
    if (values.email.value.length === 0) {
      toast.error("Please enter your email Address!");
    } else if (values.password.value.length === 0) {
      toast.error("Please enter your password!");
    } else {
      setLoader(true);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        email: values.email.value,
        password: values.password.value,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(`${Base_url}/user/login`, requestOptions)
        .then((response) => response.text())
        .then((result) => {
          console.log(result);
          let data = JSON.parse(result);
          console.log("resposne from signup-----", data);

          if (data.status === "ok") {
            toast.success("Login successfully!");
            localStorage.setItem("user_ID", data?.data?.id);
            dispatch(setUser(data?.data));
            navigate("/");
            setLoader(false);
          } else {
            toast.error(data.message);
            setLoader(false);
          }
        })
        .catch((error) => { });
    }
  };

  const handleInputChange = (e) => {
    let value = e.target.value.replace(/\D/g, ''); // Remove non-numeric characters
    if (value.length > 10) value = value.slice(0, 10); // Restrict to 10 digits
    e.target.value = value;
  };

  const [otp, setOtp] = useState("");
  const [ph, setPh] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showOTP, setShowOTP] = useState(false);
  const [user, setOtpUser] = useState(null);

  function initializeRecaptcha() {
    try {
      // Clear any existing reCAPTCHA instances completely
      if (window.recaptchaVerifier) {
        try {
          window.recaptchaVerifier.clear();
        } catch (clearError) {
          console.warn("Error clearing existing reCAPTCHA:", clearError);
        }
      }
  
      const recaptchaContainer = document.getElementById('recaptcha-container');
      if (recaptchaContainer) {
        // Completely remove and recreate the container
        const parentNode = recaptchaContainer.parentNode;
        const newContainer = document.createElement('div');
        newContainer.id = 'recaptcha-container';
        parentNode.replaceChild(newContainer, recaptchaContainer);
      }
  
      const authInstance = getAuth();
  
      window.recaptchaVerifier = new RecaptchaVerifier(
        authInstance,
        "recaptcha-container",
        {
          size: "invisible",
          callback: (response) => {
            console.log("reCAPTCHA solved successfully.");
          },
          "expired-callback": () => {
            toast.error("reCAPTCHA expired. Please try again.");
            setShowOTP(false);
            setPh(null);
          }
        }
      );
  
      return window.recaptchaVerifier.render();
    } catch (error) {
      console.error("Comprehensive reCAPTCHA initialization error:", error);
      toast.error("Phone verification setup failed. Please retry.");
      return Promise.reject(error);
    }
  }
  
  function onSignup() {
    // Completely reset and reinitialize reCAPTCHA before each attempt
    if (!ph) {
      toast.error("Please enter a valid phone number.");
      return;
    }
    else{
    initializeRecaptcha()
      .then(() => {
        
  
        setLoading(true);
        const appVerifier = window.recaptchaVerifier;
        const formattedPhone = "+61" + ph;
  
        return signInWithPhoneNumber(auth, formattedPhone, appVerifier);
        
      })
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
        setLoading(false);
        setShowOTP(true);
        toast.success("OTP sent successfully!");
      })
      .catch((error) => {
        setLoading(false);
        console.error("OTP sending failed:", error);
        
        const errorMessages = {
          'auth/invalid-phone-number': "Invalid phone number.",
          'auth/too-many-requests': "Too many verification attempts. Try later.",
          'auth/quota-exceeded': "Verification quota exceeded.",
          'auth/captcha-check-failed': "reCAPTCHA verification failed."
        };
  
        const errorMessage = errorMessages[error.code] || error.message;
        toast.error(errorMessage);
      });
    }
  }


  function onOTPVerify() {
    setLoading(true);

    if (!otp) {
      toast.error("Please enter the OTP.");
      setLoading(false);
      return;
    }

    window.confirmationResult
      .confirm(otp)
      .then((res) => {
        console.log("OTP verified successfully:", res);
        setUser(res.user);
        setLoading(false);
        toast.success("Phone number verified successfully!");

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
          phone: res?.user?.phoneNumber,
        });

        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };

        fetch(`${Base_url}/user/loginPhone`, requestOptions)
          .then((response) => response.text())
          .then((result) => {
            console.log(result);
            let data = JSON.parse(result);
            console.log("resposne from signup-----", data);
            if (data.status === "login") {
              navigate("/");
              // toast.success("Login successfully!");
              localStorage.setItem("user_ID", data?.data?.id);
              dispatch(setUser(data?.data));

              setLoader(false);
            } else {
              toast.error(data.message);
              setLoader(false);
              navigate("/register", { state: { phone: res?.user?.phoneNumber } });

            }
          })
          .catch((error) => { });
      })
      .catch((error) => {
        console.error("Invalid OTP:", error);
        setLoading(false);
        toast.error(`Invalid OTP: ${error.message}`);
      });
  }

  return (
    <div>
      <Navbar />
      <BottomHeader />

      <section className="flex items-center justify-center h-[70vh]">
        <div>
          <div id="recaptcha-container"></div>

          <div className=" max-w-xl mx-auto">
            <div className="  sm:w-96 w-auto flex shadow-xl border flex-col gap-4 rounded-lg p-6 bg-white">
              {showOTP ? (
                <>
                  <div className="bg-black  text-white w-fit mx-auto p-4 rounded-full">
                    <BsFillShieldLockFill size={30} />
                  </div>
                  <label
                    htmlFor="otp"
                    className="font-bold text-xl text-black text-center"
                  >
                    Enter your OTP
                  </label>
                  <OtpInput
                    value={otp}
                    onChange={setOtp}
                    OTPLength={6}
                    otpType="number"
                    disabled={false}
                    autoFocus
                    className="opt-container justify-center"
                  />
                  <button
                    onClick={onOTPVerify}
                    className="  bg-black w-full flex gap-1 items-center justify-center py-2.5 text-white rounded hover:bg-black transition-colors"
                  >
                    {loading && (
                      <CgSpinner size={20} className="mt-1 animate-spin" />
                    )}
                    <span>Verify OTP</span>
                  </button>
                </>
              ) : (
                <>
                  <div className="bg-black text-white w-fit mx-auto p-4 rounded-full">
                    <BsTelephoneFill size={30} />
                  </div>
                  <label
                    htmlFor=""
                    className="font-bold text-xl text-black text-center"
                  >
                    Verify your phone number
                  </label>


                  <div className="flex justify-start items-center border rounded-sm">
                    <div className="bg-gray-100 border border-l p-2">
                      <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ4e-lJY-o-I5VKuVjIABOKogGa4rXPaq_hQQ&s" style={{ width: '20px', height: '15px' }} />
                    </div>
                    <div className="flex justify-start items-center gap-3 px-3">
                      <small>+61</small>
                      <input
                        type="text"
                        maxLength={10}
                        value={ph}
                        onChange={(e) => { setPh(e.target.value) }}
                        className="w-full p-0 pt-[3.2px] m-0 text-sm phone appearance-none border-none focus:outline-none focus:ring-0"
                        onInput={handleInputChange}

                      />
                    </div>
                  </div>
                  <button
                    onClick={onSignup}
                    className="bg-black w-full flex gap-1 items-center justify-center py-2.5 text-white rounded hover:bg-black transition-colors"
                  >
                    {loading && (
                      <CgSpinner size={20} className="mt-1 animate-spin" />
                    )}
                    <span>Send code via SMS</span>
                  </button>
                </>
              )}
            </div>
          </div>

          <ToastContainer />
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default Login;
