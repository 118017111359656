import React, { useEffect, useState } from "react";
import Navbar from "../../components/Header/Navbar";
import Footer from "../../components/footer";
import BottomHeader from "../../components/Header/BottomHeader";
import { useDispatch, useSelector } from "react-redux";
import {
  decreaseQuantity,
  deleteProduct,
  increaseQuantity,
} from "../../store/nextSlice";
import { Link, useNavigate } from "react-router-dom";
import Product from "../../components/cards/Product";
import ProductSlider from "../../components/sliders/productSlider";
import axios, { all } from "axios";
import { Base_url } from "../../utils/Base_url";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { FaHeart, FaMinus, FaPlus, FaRegHeart } from "react-icons/fa";

// Example Usage
const Cart = () => {
  const dispatch = useDispatch();
  const storedUser = localStorage.getItem("user_ID") || undefined;
  const [topSearch, setTopSearch] = useState([]);
  const { data, isAuthenticated } = useSelector((state) => state.auth);
  console.log(isAuthenticated, "userdata");
  const { productData } = useSelector((state) => state.next);
  const [activeTab, setActiveTab] = useState("Home delivery");
  const [selectedStore, setSelectedStore] = useState("BIG W Palmerston");
  const [selectedPickupOption, setSelectedPickupOption] =
    useState("Service desk");
  const [promoCode, setPromoCode] = useState("");

  const handleAddCode = () => {
    if (promoCode) {
      alert(`Promo code "${promoCode}" added!`);
      setPromoCode("");
    } else {
      alert("Please enter a promo code.");
    }
  };

  console.log(productData);

  const [allProduct, setAllProduct] = useState([]);

  const [allCart, setAllCart] = useState([]);
  const [youAlso, setYouAlsoLike] = useState([]);

  console.log(youAlso,'youAlso=======================');


  const [code, setCode] = useState(null)
  const [discount, setDiscount] = useState(0)


  const checkPromo = async () => {
    axios
      .get(`${Base_url}/promo/${code}`)
      .then((res) => {
        if (res.data.status === 'ok') {
          toast.success(res.data.message)
          setDiscount(res.data.data.discount)
          localStorage.setItem('discount', res.data.data.discount)
        }
        else if (res.data.status === "fail") {
          toast.error(res.data.message)
          setDiscount(0)
          localStorage.setItem('discount', 0)
        }
      })
      .catch((error) => {
      toast.error('Invalid Promo Code!')
      setDiscount(0)
      localStorage.setItem('discount', 0)
       });
  }



  useEffect(() => {
    axios
      .get("https://fakestoreapi.com/products")
      .then((res) => {
        console.log(res);

        setAllProduct(res.data, "all products");
      })
      .catch((error) => { });

    axios
      .get(`${Base_url}/cart/getBuy?userId=${storedUser}`)
      .then((res) => {

        const cartData = res?.data?.data || [];
        setAllCart(cartData);


        const lastIndex = cartData.length > 0 ? cartData.length - 1 : null;
        const lastCategoryId = lastIndex !== null ? cartData[lastIndex]?.categoryId : null;

        if (lastCategoryId) {
          axios
            .get(`${Base_url}/product/get?limit=10&categoryIds=[${lastCategoryId}]`)
            .then((resp) => {
              console.log(resp,'you also like');
              
              setYouAlsoLike(resp?.data?.data);
            })
            .catch((error) => {
              console.error("Error fetching related products:", error)

            })
        }

      })
      .catch((error) => { });

    axios
      .get(`${Base_url}/search/get?userId=${storedUser}`)
      .then((res) => {
        console.log(res?.data?.data, "==============>>>>>>>>>sub");

        const uniqueSearches = res?.data?.data?.filter(
          (item, index, self) =>
            index ===
            self.findIndex(
              (search) =>
                search.productId === item.productId &&
                search.userId === item.userId
            )
        );

        setTopSearch(uniqueSearches);
      })
      .catch((error) => { });
  }, []);

  const totalPrice = allCart?.reduce((acc, product) => {
    return acc + product?.totalOriginalPrice;
  }, 0);

  const totalDelivery = allCart?.reduce((acc, product) => {
    return acc + product?.deliveryCharge;
  }, 0);

  console.log("Total Price:", totalPrice);

  const navigate = useNavigate();

  const renderTabContent = () => {
    switch (activeTab) {
      case "Home delivery":
        return (
          //   <div>
          //      <p className="font-medium mb-3">
          //          Postcode: 0862
          //       </p>
          //       <div className="space-y-2">
          //   {[
          //     { name: "Standard", available: "Not available", disabled: false },
          //     { name: "2 Day", available: "Not available", disabled: true },
          //     { name: "Same Day", available: "Not available", disabled: false },
          //   ].map((store) => (
          //     <label
          //       key={store.name}
          //       className={`flex items-center gap-2 p-2 border ${
          //         selectedStore === store.name
          //           ? "border-blue-500"
          //           : "border-gray-300"
          //       } rounded-lg ${
          //         store.disabled
          //           ? "opacity-50 cursor-not-allowed"
          //           : "hover:border-blue-500"
          //       }`}
          //     >
          //       <input
          //         type="radio"
          //         name="store"
          //         value={store.name}
          //         disabled={store.disabled}
          //         checked={selectedStore === store.name}
          //         onChange={() => setSelectedStore(store.name)}
          //         className="form-radio w-5 h-5 text-blue-500"
          //       />
          //       <div>
          //         <p className="font-medium">{store.name}</p>
          //         <p className="text-sm text-gray-600">{store.available}</p>
          //       </div>
          //     </label>
          //   ))}
          // </div>
          //   </div>

          <></>
        );
      case "Pick up":
        return (
          <div>
            {/* Store Selection */}
            <div className="mb-4">
              <p className="font-medium">
                BIG W Palmerston (Service desk & Direct to boot)
              </p>
              <p className="text-sm text-gray-600 mb-4">
                Stores near you that have stock.{" "}
                <a href="#" className="text-blue-500 hover:underline">
                  Change store
                </a>{" "}
                to view other locations.
              </p>

              <div className="space-y-2">
                {[
                  {
                    name: "BIG W Palmerston",
                    available: "2 of 2 items available",
                    disabled: false,
                  },
                  {
                    name: "BIG W Casuarina",
                    available: "No items available",
                    disabled: true,
                  },
                  {
                    name: "BIG W Atherton",
                    available: "2 of 2 items available",
                    disabled: false,
                  },
                ].map((store) => (
                  <label
                    key={store.name}
                    className={`flex items-center gap-2 p-2 border ${selectedStore === store.name
                        ? "border-blue-500"
                        : "border-gray-300"
                      } rounded-lg ${store.disabled
                        ? "opacity-50 cursor-not-allowed"
                        : "hover:border-blue-500"
                      }`}
                  >
                    <input
                      type="radio"
                      name="store"
                      value={store.name}
                      disabled={store.disabled}
                      checked={selectedStore === store.name}
                      onChange={() => setSelectedStore(store.name)}
                      className="form-radio w-5 h-5 text-blue-500"
                    />
                    <div>
                      <p className="font-medium">{store.name}</p>
                      <p className="text-sm text-gray-600">{store.available}</p>
                    </div>
                  </label>
                ))}
              </div>
            </div>

            {/* Pickup Options */}
            <div>
              <p className="font-medium mb-2">Pick up from</p>
              <p className="text-sm text-gray-600 mb-4">
                When your order is ready for collection, we will send an
                email/SMS.
              </p>
              <div className="space-y-2">
                {[
                  { name: "Service desk" },
                  {
                    name: "Direct to boot 9am - 5pm (11am - 5pm Sunday for WA stores)",
                  },
                ].map((option) => (
                  <label
                    key={option.name}
                    className={`flex items-center gap-2 p-2 border ${selectedPickupOption === option.name
                        ? "border-blue-500"
                        : "border-gray-300"
                      } rounded-lg hover:border-blue-500`}
                  >
                    <input
                      type="radio"
                      name="pickupOption"
                      value={option.name}
                      checked={selectedPickupOption === option.name}
                      onChange={() => setSelectedPickupOption(option.name)}
                      className="form-radio w-5 h-5 text-blue-500"
                    />
                    <p className="font-medium">{option.name}</p>
                  </label>
                ))}
              </div>
            </div>
          </div>
        );
      case "Lay-by":
        return (
          <div className=" mx-auto  border-gray-300 rounded-lg  bg-white">
            {/* Header */}
            <div className="flex justify-between items-center mb-6">
              <h1 className="text-lg font-semibold">
                BIG W Palmerston (Service desk & Direct to boot)
              </h1>
              {/* <a
                href="#"
                className="text-blue-500 font-medium underline hover:text-blue-600"
              >
                Change store
              </a> */}
            </div>

            {/* Description */}
            <p className="text-sm text-gray-600 mb-4">
              These are the stores near you that hold stock of these items. To
              check other stores, change the location.
            </p>
            <div className="space-y-4">
              {[
                {
                  name: "BIG W Palmerston",
                  available: "Not available",
                  disabled: false,
                },
                {
                  name: "BIG W Casuarina",
                  available: "Not available",
                  disabled: true,
                },
                {
                  name: "BIG W Atherton",
                  available: "Not available",
                  disabled: false,
                },
              ].map((store) => (
                <label
                  key={store.name}
                  className={`flex items-center gap-2 p-2 border ${selectedStore === store.name
                      ? "border-blue-500"
                      : "border-gray-300"
                    } rounded-lg ${store.disabled
                      ? "opacity-50 cursor-not-allowed"
                      : "hover:border-blue-500"
                    }`}
                >
                  <input
                    type="radio"
                    name="store"
                    value={store.name}
                    disabled={store.disabled}
                    checked={selectedStore === store.name}
                    onChange={() => setSelectedStore(store.name)}
                    className="form-radio w-5 h-5 text-blue-500"
                  />
                  <div>
                    <p className="font-medium">{store.name}</p>
                    <p className="text-sm text-gray-600">{store.available}</p>
                  </div>
                </label>
              ))}
            </div>

            {/* About Lay-by */}
            <div className="border-t pt-4">
              <h2 className="font-semibold text-lg mb-4">About BIG W Lay-by</h2>
              <ul className="list-disc list-inside space-y-2 text-sm text-gray-600">
                <li>
                  A one-off, non-refundable $3 set-up fee and a 10% deposit are
                  required.
                </li>
                <li>Payments are made in fortnightly installments.</li>
                <li>
                  Your order will be stored until the final payment is made.
                </li>
                <li>Lay-by can be cancelled for a $20 fee.</li>
              </ul>
              <a
                href="#"
                className="text-blue-500 font-medium underline hover:text-blue-600 mt-4 block"
              >
                View Lay-by terms & conditions
              </a>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  const removeFunction = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#A47ABF",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`${Base_url}/cart/delete/${id}`)
          .then((res) => {
            if (res.status === 200) {
              Swal.fire(
                "Deleted!",
                "Your product has been deleted.",
                "success"
              );

              axios
                .get(`${Base_url}/cart/getBuy?userId=${storedUser}`)
                .then((res) => {
                  console.log(res);

                  setAllCart(res.data.data, "all products");
                })
                .catch((error) => { });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });
  };


  const [quantity, setQuantity] = useState(1);


  const [loading, setLoading] = useState(false);

  const handleUpdateQuantity = async (type, id, quantity, sellPrice, originalPrice) => {
    setLoading(true);
    const newQuantity = type === "increment" ? quantity + 1 : quantity - 1;

    if (newQuantity < 1) {
      toast.error("Quantity cannot be less than 1");
      setLoading(false);
      return;
    }

    try {
      const response = await axios.put(`${Base_url}/cart/update/${id}`, {
        quantity: newQuantity,
        totalSellPrice: Number(sellPrice) * Number(newQuantity),
        totalOriginalPrice: Number(originalPrice) * Number(newQuantity),
      });

      if (response.data.status === "ok") {
        setQuantity(newQuantity);
        // toast.success("Quantity updated successfully");


        axios
          .get(`${Base_url}/cart/getBuy?userId=${storedUser}`)
          .then((res) => {
            console.log(res);

            setAllCart(res.data.data, "all products");
          })
          .catch((error) => { });


      } else {
        toast.error("Failed to update quantity");
      }
    } catch (error) {
      console.error("Error updating quantity:", error);
      toast.error("An error occurred while updating quantity");
    } finally {
      setLoading(false);
    }
  };





  const [isLiked, setIsLiked] = useState(null);

  const handleLikeDislike = async (item) => {
    try {
      setIsLiked(!isLiked);
      const param = {
        id: item?.product?.id,
        userId: storedUser?.toString()
      }

      const response = await axios.post(
        `${Base_url}/product/wishlist`, param
      );


      if (response.data.status === 'ok') {
        toast.success(response?.data?.message);

        axios
          .get(`${Base_url}/cart/getBuy?userId=${storedUser}`)
          .then((res) => {
            console.log(res);

            setAllCart(res.data.data, "all products");
          })
          .catch((error) => { });

      }


      if (response.data.status === 'fail') {
        toast.error(response?.data?.message);
        setIsLiked(!isLiked);
        navigate('/login')
      }
    } catch (error) {
      console.error(error);
    }
  };


  return (
    <>
      <Navbar />
      <BottomHeader />
      <section class="bg-white py-8 antialiased :bg-white md:py-16">
        <div class="mx-auto max-w-screen-2xl px-5 2xl:px-0">
          {/* <h1 className="text-xl font-semibold mb-4">Pick a delivery method</h1> */}

          <div class=" md:gap-6 lg:flex lg:items-start xl:gap-8">
            <div class="mx-auto w-full flex-none lg:max-w-2xl xl:max-w-2xl">
              {/* <div className=" mx-auto border border-gray-200 rounded-lg  sm:p-6 p-4 ">
    
   
      <div className="flex border-b mb-4">
        {["Home delivery"].map((tab) => (
          <button
            key={tab}
            onClick={() => setActiveTab(tab)}
            className={`px-4 py-2 text-sm font-medium ${
              activeTab === tab
                ? "border-b-2 border-blue-500 text-blue-500"
                : "border-b-2 border-transparent hover:border-gray-300"
            }`}
          >
            {tab}
          </button>
        ))}
      </div>

      
      <div>{renderTabContent()}</div>
    </div> */}

              <h2 class="text-xl font-semibold mb-3 text-gray-900 :text-white sm:text-2xl">
                Shopping Cart
              </h2>

              <div class="space-y-6 mt-5">
                {allCart && allCart.length > 0 ? (
                  allCart?.map((item, index) => {
                    return (
                      <div class="rounded-lg border border-gray-200 bg-white p-4 shadow-sm :border-gray-700 :bg-gray-800 md:p-6">
                        <div class="space-y-4 md:flex md:items-center md:justify-between md:gap-6 md:space-y-0">
                          <Link to={`/product_details/${item?.product?.id}`} class="shrink-0 md:order-1">
                            <img
                              class="h-20 w-20 :hidden"
                              src={item?.product?.Image1}
                              alt="imac image"
                            />
                            <img
                              class="hidden h-20 w-20 :block"
                              src="https://flowbite.s3.amazonaws.com/blocks/e-commerce/imac-front-.svg"
                              alt="imac image"
                            />
                          </Link>

                          <label for="counter-input" class="sr-only">
                            Choose quantity:
                          </label>
                          <div class="flex items-center justify-between md:order-3 md:justify-end">
                            

                             <div className=" flex w-32 items-center">
                                                            <button
                                                               onClick={() => handleUpdateQuantity("decrement", item?.id, item?.quantity, 
                                                                item?.product?.sellPrice,
                                                                item?.product?.specialPrice?item?.product?.specialPrice:item?.product?.originalPrice)}
                                                             
                                                              className=" flex justify-center   text-white items-center w-20 h-9  bg-primary"
                                                            >
                                                              <FaMinus />
                                                            </button>
                                                            <input
                                                              value={item?.quantity}
                                                              className=" text-center border w-full outline-none  h-9"
                                                            />
                                                            <button
                                                             onClick={() => handleUpdateQuantity("increment", item?.id, item?.quantity, 
                                                              item?.product?.sellPrice,
                                                              item?.product?.specialPrice?item?.product?.specialPrice:item?.product?.originalPrice)}
                                                             
                                                              className=" flex justify-center   text-white items-center w-20 h-9  bg-primary"
                                                            >
                                                              <FaPlus />
                                                            </button>
                                                          </div>
                            <div class="text-end md:order-4 md:w-32">
                              <p class="font-bold flex justify-end text-gray-900 :text-white">
                                ${item?.totalOriginalPrice}<sub className=" text-sm">AUD</sub>
                              </p>
                            </div>
                          </div>

                          <div class="w-full min-w-0 flex-1 space-y-4 md:order-2 md:max-w-md">
                            <a
                              href="#"
                              class="text-base font-medium text-gray-900 hover:underline :text-white"
                            >
                              {item?.product?.name}
                            </a>

                            {item?.product?.hasFreeProduct?<p className="text-green">You got one free product!</p>:null
                            }

                            <div class="flex items-center gap-4">
                              <button
                                onClick={() => handleLikeDislike(item)}
                                type="button"
                                className={`inline-flex gap-1 items-center text-sm ${item?.product?.like?.includes(storedUser?.toString()) ? 'text-black' : 'text-gray-500'
                                  }`}
                              >
                                {item?.product?.like?.includes(storedUser?.toString()) ? <FaHeart size={20} /> : <FaRegHeart size={20} />}
                                Add to Favorites
                              </button>

                              <button
                                onClick={() => removeFunction(item?.id)}
                                type="button"
                                class="inline-flex items-center text-sm font-medium text-red-600 hover:underline :text-red-500"
                              >
                                <svg
                                  class="me-1.5 h-5 w-5"
                                  aria-hidden="true"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                >
                                  <path
                                    stroke="currentColor"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M6 18 17.94 6M18 18 6.06 6"
                                  />
                                </svg>
                                Remove
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="flex flex-col items-center justify-center h-full p-10 text-center">
                    {/* <img
                      src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/hero/empty-cart.svg"
                      alt="No Products Found"
                      className="w-40 h-40 mb-4"
                    /> */}
                    <h2 className="text-xl font-bold text-gray-900 :text-white">
                      No Products Found
                    </h2>
                    <p className="text-sm text-gray-600 :text-gray-400">
                      It looks like your cart is empty. Start adding products
                      now!
                    </p>
                  </div>
                )}
              </div>
            </div>

            <div class="mx-auto mt-6 max-w-4xl flex-1 space-y-6 lg:mt-0 lg:w-full">
              <p class="text-xl font-semibold text-gray-900 :text-white">
                Order summary
              </p>

              <div class="space-y-4 rounded-lg border border-gray-200 bg-white p-4 shadow-sm :border-gray-700 :bg-gray-800 sm:p-6">
                <div className="   border-gray-300 ">
                  <div className="flex items-center justify-between">
                    <div className="flex items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5 text-gray-500"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M17 9V7a4 4 0 10-8 0v2M7 9a4 4 0 108 0v2m-8 0h8m-8 0v6m8-6v6"
                        />
                      </svg>
                      <h3 className="ml-2 text-lg font-medium text-gray-700">
                        Add promo or Rewards code
                      </h3>
                    </div>
                   
                  </div>
                  <div className="mt-4 flex sm:flex-row flex-col  gap-4 items-center">
                    <input
                      type="text"
                      value={code}
                      onChange={(e) => setCode(e.target.value)}
                      placeholder="Promo or Rewards code"
                      className="flex-1 p-2 w-full border border-gray-300  focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    />
                    <div className=" flex w-full sm:w-20  justify-end">
                      <button
                        onClick={() => {

                          if (!code) {
                            toast.error("Please enter a valid promo code")
                          }
                          else {
                            checkPromo()
                          }

                        }}
                        className="px-5 py-2 bg-white border border-blue-500 text-blue-500  hover:bg-blue-100 transition"
                      >
                        Add
                      </button>
                    </div>
                  </div>
                </div>

                <div class="space-y-4">
                  <div class="space-y-2">
                    <dl class="flex items-center justify-between gap-4">
                      <dt class="text-base font-normal text-gray-500 :text-gray-400">
                        Subtotal
                      </dt>
                      <dd class="text-base font-medium text-gray-900 :text-white">
                        ${totalPrice} AUD
                      </dd>
                    </dl>

                    <dl class="flex items-center justify-between gap-4">
                      <dt class="text-base font-normal text-gray-500 :text-gray-400">
                        Savings
                      </dt>
                      <dd class="text-base font-medium text-green-600">${(Number(totalPrice) * Number(discount?discount:localStorage.getItem('discount'))) / 100} AUD</dd>
                    </dl>

                    <dl class="flex items-center justify-between gap-4">
                      <dt class="text-base font-normal text-gray-500 :text-gray-400">
                        Delivery
                      </dt>
                      <dd class="text-base font-medium text-gray-900 :text-white">
                        ${totalDelivery} AUD
                      </dd>
                    </dl>

                    {/* <dl class="flex items-center justify-between gap-4">
                      <dt class="text-base font-normal text-gray-500 :text-gray-400">
                        Lay by
                      </dt>
                      <dd class="text-base font-medium text-gray-900 :text-white">
                        Free
                      </dd>
                    </dl> */}
                  </div>

                  <dl class="flex items-center justify-between gap-4 border-t border-gray-200 pt-2 :border-gray-700">
                    <dt class="text-base font-bold text-gray-900 :text-white">
                      Total
                    </dt>
                    <dd class="text-base font-bold text-gray-900 :text-white">
                      ${Number(totalPrice) + Number(totalDelivery) - Number((Number(totalPrice) * Number(discount?discount:localStorage.getItem('discount'))) / 100)} AUD
                    </dd>
                  </dl>
                </div>

                <button
                  onClick={() =>{
                     
                     if(allCart?.length===0){

                      toast.error('Cart is Empty!')
                      navigate('/shop')

                     }else{
                      navigate("/checkout")
                     }

                  

                  }}
                  class="flex w-full items-center justify-center rounded-lg bg-[#232323] px-5 py-2.5 text-sm font-medium text-white hover:bg-[#232323] focus:outline-none focus:ring-4 focus:ring-primary-300 :bg-primary-600 :hover:bg-primary-700 :focus:ring-primary-800"
                >
                  Proceed to Checkout
                </button>

                <div class="flex items-center justify-center gap-2">
                  <span class="text-sm font-normal text-gray-500 :text-gray-400">
                    {" "}
                    or{" "}
                  </span>
                  <Link
                    to="/"
                    title=""
                    class="inline-flex items-center gap-2 text-sm font-medium text-primary-700 underline hover:no-underline :text-primary-500"
                  >
                    Continue Shopping
                    <svg
                      class="h-5 w-5"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M19 12H5m14 0-4 4m4-4-4-4"
                      />
                    </svg>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {isAuthenticated ? (
        <div className=" pb-10  container mx-auto">
          <h4 className="h4">Recently Viewed</h4>
          <div className=" pt-3">
            <ProductSlider top={20}
              items={topSearch?.map((item, index) => {
                if (item?.discount > 10) {
                }
                return (
                  <>
               <div className="">
                <Product type={'slider'} item={item?.product} />
                </div>
                  </>
                );
              })}
            />
          </div>
        </div>
      ) : null}




      {youAlso?.length>0?<div className=" pb-10  container mx-auto">
        <h4 className="h4">You may also like</h4>
        <div className=" pt-3">
          <ProductSlider top={20}
            items={youAlso?.map((item, index) => {
              return (
                <>
                  <div className=" ">
                <Product type={'slider'} item={item} />
                </div>
                </>
              );
            })}
          />
        </div>
      </div>:null}


      <Footer />
    </>
  );
};

export default Cart;
