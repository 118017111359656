import React, { useEffect, useRef, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { Base_url } from "../../../utils/Base_url";
import { toast } from "react-toastify";
import Modal from "../../../components/modal";
import { MdClose } from "react-icons/md";
import Button from "../../../components/Button";
import { useSelector } from "react-redux";
const AddAddress = ({
  isModalOpen,
  setIsModalOpen,
  closeModal,
  setAddress,
  setSelectedAddress
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const storedUser = localStorage.getItem("user_ID");

  const userData = useSelector((state) => state.auth.user);

  // Google Places API Key
  const googleMapsApiKey = "AIzaSyA0ezzOFq6lTHs4i4DlmMPJpV48LAWMx7o";

  // Validation Schema
  const validationSchema = Yup.object({
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("Last name is required"),
    // addressLine1: Yup.string().required("Address is required"),
    // city: Yup.string().required("City is required"),
    // phone: Yup.string().required("Phone is required"),
    // state: Yup.string().required("State is required"),
    // zipCode: Yup.string()
    //   .matches(/^\d{5}$/, "ZIP code must be 5 digits")
    //   .required("ZIP code is required"),
    // country: Yup.string().required("Country is required"),
    default: Yup.boolean().required("Default address selection is required"),
  });

  
  const initialValues = {
    firstName: "",
    lastName: "",
    businessName: "",
    addressLine1: "",
    addressLine2: "",
    phone: userData?.phone,
    comment: "",
    default: false,
  };

  const [address3, setAddress3] = useState("");

  console.log(address3);
  
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [unitNumber, setUnitNumber] = useState("");

  const onSubmit = async (values, { resetForm }) => {
    console.log(values);
    

    if(!address3){
      toast.error("Address is required");
      
    }else{

      const params = {
        userId: storedUser,
        ...values,
        city:city,
        state:state,
        unit:unitNumber,
        country:country,
        addressLine1: address3,
      };
  
      setIsLoading(true);
      try {
        const res = await axios.post(`${Base_url}/address/create`, params);
        if (res.status === 200) {
          setIsModalOpen(false);
          resetForm();
          toast.success("Address added successfully!");
          axios
            .get(`${Base_url}/address/user/${storedUser}`)
            .then((res) => {
              console.log(res, "address");
  
              setAddress(res?.data?.data);

              const fetchedAddresses = res?.data?.data || [];
              setAddress(fetchedAddresses);
              if (fetchedAddresses.length > 0) {
                setSelectedAddress(fetchedAddresses[0]);
              }
          
              
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          setErrorMessage("Failed to save the address. Please try again.");
        }
      } catch (error) {
        setErrorMessage("An error occurred while saving the address.");
      } finally {
        setIsLoading(false);
      }

    }
    
  
  };



  const autocompleteRef = useRef(null);

  const loadGoogleMaps = () => {
    if (!window.google) {
      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyA0ezzOFq6lTHs4i4DlmMPJpV48LAWMx7o&libraries=places`;
      script.async = true;
      script.defer = true;
      script.onload = initializeAutocomplete;
      document.body.appendChild(script);
    } else {
      initializeAutocomplete();
    }
  };

  
  const initializeAutocomplete = () => {
    if (!window.google || !document.getElementById("autocompleteN")) return;

    autocompleteRef.current = new window.google.maps.places.Autocomplete(
      document.getElementById("autocompleteN"),
      { types: ["geocode"] }
    );


    console.log(document.getElementById("autocompleteN"));
    

    autocompleteRef.current.addListener("place_changed", handlePlaceSelect);
  };

  
  const handlePlaceSelect = () => {
    const place = autocompleteRef.current.getPlace();
    if (!place || !place.address_components) return;

    let cityName = "";
    let stateName = "";
    let countryName = "";
    let unit = "";

    place.address_components.forEach((component) => {
      const types = component.types;
      if (types.includes("locality")) cityName = component.long_name;
      if (types.includes("administrative_area_level_1")) stateName = component.long_name;
      if (types.includes("country")) countryName = component.long_name;
      if (types.includes("subpremise")) unit = component.long_name;
    });

    const formattedAddress = place.formatted_address || "";
    if (!unit) unit = extractUnitNumber(formattedAddress);

    // Update state with the selected address
    setAddress3(formattedAddress);
    setCity(cityName);
    setState(stateName);
    setCountry(countryName);
    setUnitNumber(unit);
  };

  const extractUnitNumber = (formattedAddress) => {
    const addressParts = formattedAddress.split(",");
    const firstPart = addressParts[0]?.trim();
    const unitMatch = firstPart.match(/^(\d+)[\s\-]/);
    return unitMatch ? unitMatch[1] : "N/A";
  };

  useEffect(() => {
    



    loadGoogleMaps();

    return () => {
      if (autocompleteRef.current) {
        window.google.maps.event.clearInstanceListeners(autocompleteRef.current);
      }
    };
  }, [loadGoogleMaps]);
  



  return (
    <>
      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        className="max-w-xl"
      >
        <div>
          <div className="p-3 flex justify-between items-center">
            <div></div>
            <h1 className="capitalize h4">Add Address</h1>
            <MdClose
              className="cursor-pointer"
              size={25}
              onClick={() => setIsModalOpen(false)}
            />
          </div>
          <hr />
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize
            onSubmit={onSubmit}

          >
            {({ isSubmitting, setFieldValue, values }) => (
              <Form className="p-5">
                <div className="flex gap-5 flex-wrap">
                  {/* Name */}

                  <div className="w-full">
                    <label htmlFor="defaultAddress">Default Address</label>
                    <div className="flex gap-2 pt-3">
                      <div className="flex gap-2">
                        <p className="text-center text-sm">Yes</p>
                        <input
                          type="radio"
                          name="default"
                          checked={values.default === true}
                          onChange={() => setFieldValue("default", true)}
                          className="w-4 h-4 mx-auto"
                        />
                      </div>
                      <div className="flex gap-2">
                        <p className="text-center text-sm">No</p>
                        <input
                          type="radio"
                          name="default"
                          checked={values.default === false}
                          onChange={() => setFieldValue("default", false)}
                          className="w-4 h-4 mx-auto"
                        />
                      </div>
                    </div>
                  </div>

                
                  <div className="w-full">
                   
                    <div className="w-full  mx-auto">
                      <label htmlFor="addressLine1">Address</label>
                      <input
                        id="autocompleteN"
                        className="border p-2 w-full rounded"
                        placeholder="Enter an address"
                      />
                    
                    </div>
                  </div>

                  <div className="sm:w-[48%] w-full">
                    <label htmlFor="firstName">First Name</label>
                    <Field
                      id="firstName"
                      name="firstName"
                      placeholder="Enter First Name"
                      className="border rounded-sm w-full p-3"
                    />
                    <ErrorMessage
                      name="firstName"
                      component="p"
                      className="text-red text-sm font-semibold"
                    />
                  </div>

                  <div className="sm:w-[48%] w-full">
                    <label htmlFor="lastName">Last Name</label>
                    <Field
                      id="lastName"
                      name="lastName"
                      placeholder="Enter Last Name"
                      className="border rounded-sm w-full p-3"
                    />
                    <ErrorMessage
                      name="lastName"
                      component="p"
                      className="text-red text-sm font-semibold"
                    />
                  </div>

                  <div className="w-full">
                    <label htmlFor="businessName">Business Name</label>
                    <Field
                      id="businessName"
                      name="businessName"
                      placeholder="Enter Business Name"
                      className="border rounded-sm w-full p-3"
                    />
                    <ErrorMessage
                      name="businessName"
                      component="p"
                      className="text-red text-sm font-semibold"
                    />
                  </div>

                  <div className="w-full">
                    <label htmlFor="phone">Phone</label>
                    <Field
                      id="phone"
                      name="phone"
                      placeholder="Enter Phone"
                      className="border rounded-sm w-full p-3"
                      disabled
                    />
                    <ErrorMessage
                      name="phone"
                      component="p"
                      className="text-red text-sm font-semibold"
                    />
                  </div>

                  {/* City */}
                  <div className="w-[60%]">
                    <label htmlFor="city">City</label>
                    <input
                      id="city"
                      name="city"
                      placeholder="Enter City"
                      className="border rounded-sm w-full p-3"
                       onChange={(e) => setCity(e.target.value)}
                      defaultValue={city}
                   
                     
                    />
                    <ErrorMessage
                      name="city"
                      component="p"
                      className="text-red text-sm font-semibold"
                    />
                  </div>

                  {/* State */}
                  <div className="w-[36%]">
                    <label htmlFor="state">State</label>
                    <input
                      id="state"
                      name="state"
                      placeholder="Enter State"
                      className="border rounded-sm w-full p-3"
                      onChange={(e) => setState(e.target.value)}
                      defaultValue={state}
                    />
                    <ErrorMessage
                      name="state"
                      component="p"
                      className="text-red text-sm font-semibold"
                    />
                  </div>

                  {/* Country */}
                  <div className="w-[60%]">
                    <label htmlFor="country">Country</label>
                    <input
                      id="country"
                      name="country"
                      onChange={(e) => setCountry(e.target.value)}
                      placeholder="Enter Country"
                      className="border rounded-sm w-full p-3"
                      defaultValue={country}
                    />
                    <ErrorMessage
                      name="country"
                      component="p"
                      className="text-red text-sm font-semibold"
                    />
                  </div>

                  {/* ZIP Code */}
                  <div className="w-[36%]">
                    <label htmlFor="unit">Unit Number</label>
                    <input
                      id="unit"
                      name="unit"
                      placeholder="Enter Unit"
                      
                      className="border rounded-sm w-full p-3"
                      defaultValue={unitNumber}
                    />
                    <ErrorMessage
                      name="unit"
                      component="p"
                      className="text-red text-sm font-semibold"
                    />
                  </div>

                  <div className=" w-full">
                    <label htmlFor="comment">Comment</label>
                    <Field
                      as="textarea"
                      id="comment"
                      name="comment"
                      placeholder="comment"
                      className="border rounded-sm w-full p-3"
                    />
                  </div>
                </div>

                {/* Error Message */}
                {errorMessage && (
                  <p className="text-red-600 mt-4">{errorMessage}</p>
                )}

                {/* Save Button */}
                <Button
                  type="submit"
                  label={isLoading ? "Saving..." : "Save Address"}
                  disabled={isSubmitting || isLoading}
                  className={`bg-black uppercase mt-4 text-white py-2 w-full ${
                    isSubmitting || isLoading
                      ? "opacity-50 cursor-not-allowed"
                      : ""
                  }`}
                />
              </Form>
            )}
          </Formik>
        </div>
      </Modal>
    </>
  );
};

export default AddAddress;
