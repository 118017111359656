import React from 'react'
import Faqs from '../../components/Faqs'

const SameDayDelivery = () => {
  return (
    <>
    <div className='   px-4 mx-auto'>

    <div className=''>
        <img src='https://s7ap1.scene7.com/is/image/bigw/Delivery_SameDay_HB_210201?$cms-max-image-threshold$&fmt=webp-alpha&wid=1075&fit=hfit%2C1&scale=0.5&qlt=88' className=' w-full' alt='' />
    </div>

    <div>
        <h2 className=' text-center py-3 text-2xl'>Same day delivery
        </h2>
        <p className=' pt-3'>For $15 per order, Same Day delivery within 1 business day is available for select areas only. Place your order before 12 noon to receive your parcel by 9pm that evening - as some orders/locations may be delivered later in the evening, please do not order for delivery to a business address if the business is closed after 6pm.

</p>

    

         <p className=' pt-3'>Passed 12 noon? If you place your order past 12 noon Monday to Thursday, you’ll get your parcel by 9pm the following business day Mon-Fri. Orders placed on Friday after 12 noon, weekends and public holidays will be shipped on the next business day.



</p>


<p className=' pt-3'>Exclusions apply, please see full list below. If a postcode and item are eligible, this delivery option will be made available at checkout.





</p>
         
      
    </div>
    <Faqs/>
    </div>
    
    
    </>
   
  )
}

export default SameDayDelivery