import axios from "axios";
import React, { useEffect, useState } from "react";
import { Base_url } from "../../../utils/Base_url";
import moment from "moment/moment";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

const OrderHistory = () => {
  const storedUser = localStorage.getItem("user_ID") || undefined;

  const [orderHistory, setOrderHistory] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [pageSize] = useState(10);

  const fetchOrderHistory = (page) => {
    axios
      .get(
        `${Base_url}/order/get?userId=${storedUser}&page=${page}&limit=${pageSize}`
      )
      .then((res) => {
        console.log(res);
        
        setOrderHistory(res.data.data);
        setTotalPages(res.data.totalPages);
      })
      .catch((error) => {
        console.error("Failed to fetch order history:", error);
      });
  };

  useEffect(() => {
    fetchOrderHistory(currentPage);
  }, [currentPage]);

  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const updateStatus = (id, newStatus, page) => {
    const params = {
      status: newStatus,
    };
  
    Swal.fire({
      title: 'Are you sure?',
      text: "You are about to update the status of this order. Do you want to proceed?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, update it!',
      cancelButtonText: 'No, cancel!',
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .put(`${Base_url}/order/update/${id}`, params)
          .then((res) => {
            if (res?.data?.status === "ok") {
              
  
              axios
                .get(
                  `${Base_url}/order/get?userId=${storedUser}&status=pending&page=${page}&limit=${pageSize}`
                )
                .then((res) => {
                  console.log(res);
                  setOrderHistory(res.data.data);
                  setTotalPages(res.data.totalPages);
                })
                .catch((error) => {
                  console.error("Failed to fetch order history:", error);
                });
                Swal.fire({
                  title: 'Order Removed',
                  text: 'Your order has been removed. You will receive a refund within 24 hours.',
                  icon: 'success',
                  confirmButtonText: 'OK'
                }).then(() => {
                  // Optionally, you can trigger a refund API call here
                  // axios.post(`${Base_url}/refund`, { orderId: id })
                  //   .then((res) => {
                  //     console.log("Refund processed:", res.data);
                  //   })
                  //   .catch((error) => {
                  //     console.error("Failed to process refund:", error);
                  //   });
                });
              
              
            } else {
              toast.error("Failed to update status. Please try again.");
            }
          })
          .catch((error) => {
            console.error("Error updating status:", error);
            toast.error("An error occurred while updating the status.");
          });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire('Cancelled', 'Your order status remains unchanged :)', 'error');
      }
    });
  };

  const updatePaypalStatus = (id,page) => {
    const params = {
      payPalId:id,
    };
  
    Swal.fire({
      title: 'Are you sure?',
      text: "You are about to update the status of this order. Do you want to proceed?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, update it!',
      cancelButtonText: 'No, cancel!',
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .post(`${Base_url}/order/refund`, params)
          .then((res) => {
            if (res?.data?.status === "ok") {
              
  
              axios
                .get(
                  `${Base_url}/order/get?userId=${storedUser}&status=pending&page=${page}&limit=${pageSize}`
                )
                .then((res) => {
                  console.log(res);
                  setOrderHistory(res.data.data);
                  setTotalPages(res.data.totalPages);
                })
                .catch((error) => {
                  console.error("Failed to fetch order history:", error);
                });
                Swal.fire({
                  title: 'Order Removed',
                  text: 'Your order has been removed. You will receive a refund within 24 hours.',
                  icon: 'success',
                  confirmButtonText: 'OK'
                }).then(() => {
                  // Optionally, you can trigger a refund API call here
                  // axios.post(`${Base_url}/refund`, { orderId: id })
                  //   .then((res) => {
                  //     console.log("Refund processed:", res.data);
                  //   })
                  //   .catch((error) => {
                  //     console.error("Failed to process refund:", error);
                  //   });
                });
              
              
            } else {
              toast.error("Failed to update status. Please try again.");
            }
          })
          .catch((error) => {
            console.error("Error updating status:", error);
            toast.error("An error occurred while updating the status.");
          });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire('Cancelled', 'Your order status remains unchanged :)', 'error');
      }
    });
  };

  return (
    <div className="container mx-auto">
      <section className="bg-white  antialiased  md:py-16">
        <div className="mx-auto max-w-screen-xl 2xl:px-0">
          <div className="mx-auto ">
            <div className="gap-4 sm:flex sm:items-center sm:justify-between">
              <h2 className="text-xl font-semibold text-gray-900 :text-white sm:text-2xl">
                My orders
              </h2>
            </div>

            <div className="mt-6 flow-root sm:mt-8">
              <div className="divide-y divide-gray-200 :divide-gray-700">
                {orderHistory?.map((item, index) => (
                  <div
                    key={index}
                    className="flex flex-wrap items-center gap-y-4 py-6"
                  >
                    <dl className="w-1/2 sm:w-1/4 lg:w-auto lg:flex-1">
                      <dt className="text-base font-medium text-gray-500 :text-gray-400">
                        Order ID:
                      </dt>
                      <dd className="mt-1.5 text-base font-semibold text-gray-900 :text-white">
                        <a href="#" className="hover:underline">
                          #{item.id}
                        </a>
                      </dd>
                    </dl>

                    <dl className="w-1/2 sm:w-1/4 lg:w-auto lg:flex-1">
                      <dt className="text-base font-medium text-gray-500 :text-gray-400">
                        Date:
                      </dt>
                      <dd className="mt-1.5 text-base font-semibold text-gray-900 :text-white">
                        {moment(item.createdAt).format("MM-DD-YYYY")}
                      </dd>
                    </dl>

                    <dl className="w-1/2 sm:w-1/4 lg:w-auto lg:flex-1">
                      <dt className="text-base font-medium text-gray-500 :text-gray-400">
                        Price:
                      </dt>
                      <dd className="mt-1.5 text-base font-semibold text-gray-900 :text-white">
                        ${item.totalBill} AUD
                      </dd>
                    </dl>

                    <dl className="w-1/2 sm:w-1/4 lg:w-auto lg:flex-1">
                      <dt className="text-base font-medium text-gray-500 :text-gray-400">
                        Status:
                      </dt>
                      <dd className="mt-1.5  capitalize text-base font-semibold text-gray-900 :text-white">
                        {item.status}
                      </dd>
                    </dl>
                    <dl className="w-1/2 sm:w-1/4 lg:w-auto lg:flex-1">
                      <dt className="text-base font-medium text-gray-500 :text-gray-400">
                        Payment Status:
                      </dt>
                      <dd className="mt-1.5 text-base font-semibold text-gray-900 :text-white">
                        {item.paymentStatus}
                      </dd>
                    </dl>
                    <div class="w-full grid sm:grid-cols-2 lg:flex lg:w-64 lg:items-center lg:justify-end gap-4">
                    {/* {item?.accountType==="paypal"?

                     (
                      item?.status==="pending"?
<button
onClick={() => updatePaypalStatus(item?.payPalId, "cancelled")}
type="button"
class="w-full rounded-lg border border-red-700 px-3 py-2 text-center text-sm font-medium text-red-700 hover:bg-red-700  focus:outline-none focus:ring-4 focus:ring-red-300 :border-red-500 :text-red-500 :hover:bg-red-600 :hover:text-white :focus:ring-red-900 lg:w-auto"
>
Cancel order
</button>:null
                     ):
                     (
                      item?.status==="pending"?
<button
onClick={() => updateStatus(item?.id, "cancelled")}
type="button"
class="w-full rounded-lg border border-red-700 px-3 py-2 text-center text-sm font-medium text-red-700 hover:bg-red-700  focus:outline-none focus:ring-4 focus:ring-red-300 :border-red-500 :text-red-500 :hover:bg-red-600 :hover:text-white :focus:ring-red-900 lg:w-auto"
>
Cancel order
</button>:null
                     )
                    
                  } */}
                      
                    
                      <Link
                        to={`/order_details/${item?.id}`}
                        class="w-full inline-flex justify-center rounded-lg  border border-gray-200 bg-white px-3 py-2 text-sm font-medium text-gray-900 hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:outline-none focus:ring-4 focus:ring-gray-100 :border-gray-600 :bg-gray-800 :text-gray-400 :hover:bg-gray-700 :hover:text-white :focus:ring-gray-700 lg:w-auto"
                      >
                        View details
                      </Link>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className="flex justify-end gap-2 items-center p-4">
              <button
                className="px-4 py-2 text-white bg-black rounded disabled:opacity-50"
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                Previous
              </button>
              <div className="flex items-center gap-4">
                <p className="font-medium">
                  Page {currentPage} of {totalPages}
                </p>
                <select
                  className="px-2 py-1 border rounded"
                  value={currentPage}
                  onChange={(e) => handlePageChange(Number(e.target.value))}
                >
                  {Array.from({ length: totalPages }, (_, index) => (
                    <option key={index + 1} value={index + 1}>
                      {index + 1}
                    </option>
                  ))}
                </select>
              </div>
              <button
                className="px-4 py-2 text-white bg-black rounded disabled:opacity-50"
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default OrderHistory;
