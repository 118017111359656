import React, { useEffect, useState } from 'react'
import Navbar from '../../components/Header/Navbar'
import Footer from '../../components/footer'
import BottomHeader from '../../components/Header/BottomHeader'
import Subscribe from '../../components/Subscribe'
import { Base_url } from '../../utils/Base_url'
import axios from 'axios'
import Product from '../../components/cards/Product'
import { Link, useParams } from 'react-router-dom'

const CatalogueProducts = () => {

  const {id} = useParams();
  const [data,setData] = useState([]);
  const [catalogue,setCatalogue] = useState({});


  useEffect(()=>{
     
   
    const fetchDeals = async () => {
      try {
        const response = await axios.get(`${Base_url}/catalogueProduct/get?catalogueCategoryId=${id}`);

        console.log(response,'response');

        setData(response?.data?.data);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };


    const fetchCatalogues = async () => {
      try {
        const response = await axios.get(`${Base_url}/catalogueProduct/get/${id}`);

        console.log(response,'response');

        setCatalogue(response?.data?.data);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchDeals();
    fetchCatalogues();

  },[])

  return (
    <>
     <Navbar/>
      <BottomHeader/>
       
       <section className='   max-w-7xl mx-auto px-3'>
    <div className=" pt-3">
              <p>
                Home /{" "}
                <Link to={"/catalogues"} className=" text-gray-500">
                  Catalogues
                </Link>{" "}
                / <Link className=" text-gray-500">{catalogue?.catalogueCategory?.name}</Link>
              </p>
            </div>
       
            <div className="grid mt-3 py-10 gap-4 grid-cols-2 md:grid-cols-3  lg:grid-cols-4">
      {data?.map((item, index) => (
        <div key={index} className="w-full flex flex-col justify-center items-center">
          <Product item={item?.product} />
        </div>
      ))}
    </div>
              
       </section>
      
      <Subscribe/>
     <Footer/>
    </>
  )
}

export default CatalogueProducts