import React, { useEffect, useState } from "react";
import BrandCard from "../cards/BrandCard";
import { FaLongArrowAltRight } from "react-icons/fa";
import axios from "axios";
import { Base_url } from "../../utils/Base_url";
import { Link } from "react-router-dom";

const PopularBrands = () => {
  const [designer, setDesigner] = useState([]);




  // const [allBlogCategory, setAllBlogCategory] = useState([]);

  useEffect(() => {
    const fetchSlider = async () => {
      try {
        const response = await axios.get(`${Base_url}/brand/get`);

        console.log(response, "response");

        setDesigner(response?.data?.data);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchSlider();
  }, []);

 

  return (
    <div className="container mx-auto py-6 px-4">
      {/* Section Header */}
      <div className="flex items-center justify-between">
        <h4 className="text-black font-medium text-xl">Top Brand</h4>
        <Link
          to={"/brands"}
          className="text-xs flex items-center gap-1 text-[#0000FF] font-extrabold"
        >
          <span>SEE ALL</span> <FaLongArrowAltRight color="#0000FF" />
        </Link>
      </div>

      {/* Brands Grid */}
      <div className="grid grid-cols-3 sm:grid-cols-4 md:grid-cols-6 lg:grid-cols-8 gap-5 my-4">
        {designer?.slice(0, 16)?.map((item, index) =>{
          return (
            <BrandCard key={index} item={item} />
          )
        })}
      </div>
    </div>
  );
};

export default PopularBrands;
