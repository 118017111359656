import React from "react";
import { Link } from "react-router-dom";

const BrandCard = ({item}) => {
  return (
    <Link   to={`/shop?product=undefined&limit=10&page=1&sortBy=&categoryIds=&subCategoryIds=&subSubCategoryIds=&brandIds=[${item?.id}]&colorName=&minPrice=&maxPrice=&priceComparison=`}  className="  border bg-gray-50 hover:bg-gray-100 cursor-pointer rounded-[12px] flex justify-center items-center px-8 py-1 h-24">
      {item?.image?
      <img
      src={item?.image}
      className=""
      alt=""
    />:null
    }
      
      <span>{item?.name}</span>
    </Link>
  );
};

export default BrandCard;
