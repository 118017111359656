import React, { useEffect, useState } from "react";
import {
  FaAngleRight,
  FaHeart,
  FaMinus,
  FaPlus,
  FaRegHeart,
  FaRegStar,
  FaStar,
} from "react-icons/fa";
import { TfiAngleLeft, TfiAngleRight } from "react-icons/tfi";
import { motion } from "framer-motion";
import { Link, useNavigate, useParams } from "react-router-dom";
import Modal from "../../components/modal";
import axios from "axios";
import Navbar from "../../components/Header/Navbar";
import Footer from "../../components/footer";
import { Base_url } from "../../utils/Base_url";
import BottomHeader from "../../components/Header/BottomHeader";
import Tabs from "../../components/Tabs";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import ProductSlider from "../../components/sliders/productSlider";
import Product from "../../components/cards/Product";
import { IoMdCheckmark, IoMdClose } from "react-icons/io";
import WriteReviews from "../Reviews/WriteReviews";
import SideDrawer from "../../components/Header/SideDrawer";
import Subscribe from "../../components/Subscribe";
import moment from "moment";
import PayPalModal from "./PayPalModal";
import AfterPayModal from "./AfterPayModal";
import ZipPayModal from "./ZipPayModal";

function StarRating({ rating }) {
  return (
    <ul className="font-medium text-amber-400 flex gap-1 list-none">
      {Array.from({ length: 5 }, (_, index) => (
        <li className="inline" key={index}>
          <FaStar color={index < rating ? "gold" : "gray"} />
        </li>
      ))}
    </ul>
  );
}

const ProductDetails = ({
  children: slides,
  autoSlide = false,
  autoSlideInterval = 3000,
}) => {
  const dispatch = useDispatch();
  const storedUser = localStorage.getItem("user_ID");
  console.log(storedUser, "user id");

  const [afterPay, setAfterPay] = useState(false);
  const [Paypal, setPaypal] = useState(false);
  const [getData, setData] = useState({});
  const [address, setAddress] = useState([]);
  const { data, isAuthenticated } = useSelector((state) => state.auth);

  const [cartDetails, setCartDetails] = useState({});
  const [checkState, setCheckState] = useState(false);
  const [zipPay, setZipPay] = useState(false);

  console.log(checkState, "checkState");

  const [curr, setCurr] = useState(0);
  const prev = () =>
    setCurr((curr) =>
      curr === 0
        ? [
            allProduct?.Image1,
            allProduct?.Image2,
            allProduct?.Image3,
            allProduct?.Image4,
          ].length - 1
        : curr - 1
    );
  const next = () =>
    setCurr((curr) =>
      curr ===
      [
        allProduct?.Image1,
        allProduct?.Image2,
        allProduct?.Image3,
        allProduct?.Image4,
      ]?.length -
        1
        ? 0
        : curr + 1
    );

  useEffect(() => {
    if (!autoSlide) return;
    const slideInterval = setInterval(next, autoSlideInterval);
    return () => clearInterval(slideInterval);
  }, []);

  const goToSlide = (index) => {
    setCurr(index);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isReviewsModal, setIsReviewsModal] = useState(false);
  const navigate = useNavigate();
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const [isOpen, setIsOpen] = useState(false);

  const [isModalSlider, setIsModalSlider] = useState(false);

  const openSlider = () => {
    setIsModalSlider(true);
  };

  const closeSlider = () => {
    setIsModalSlider(false);
  };

  const { id } = useParams();
  const [allProduct, setAllProduct] = useState({});
  const [selColor, setSelColor] = useState(null);
  const [topSearch, setTopSearch] = useState([]);
  console.log(allProduct);

  const [youAlsoLike, setYouAlsoLike] = useState([]);

  const [rating, setRating] = useState(0);
  const [hover, setHover] = useState(null);
  const [quantityState, setStateQuantity] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [selectedAddress, setSelectedAddress] = useState(null);
  console.log(selectedAddress, "selectd addres");
  const [ratings, setRatings] = useState([]);
  const [defaultAddress, setDefaultAddress] = useState([]);
  const handleAddressChange = (item) => {
    setSelectedAddress(item);
  };

  const handleProductCheck = (product) => {
    let storedProducts =
      JSON.parse(localStorage.getItem("recentlyProducts")) || [];
    console.log("Current stored products:", storedProducts);

    const existingProduct = storedProducts.find(
      (item) => item.id === product.id
    );
    console.log("Existing product:", existingProduct);

    if (!existingProduct) {
      storedProducts.push(product);
      localStorage.setItem("recentlyProducts", JSON.stringify(storedProducts));
      console.log("Product added:", product);
    }
  };

  const setRecentlyProducts = (products) => {
    if (!Array.isArray(products)) {
      console.error("Expected an array of products, but got:", products);
      return;
    }
    products.forEach((product) => {
      handleProductCheck(product);
    });
  };

  useEffect(() => {
    axios
      .get(`${Base_url}/rating/get?productId=${id}`)
      .then((res) => {
        console.log(res);

        setRatings(res.data);
      })
      .catch((error) => {});

    axios
      .get(`${Base_url}/address/default/${storedUser}`)
      .then((res) => {
        console.log(res);

        setDefaultAddress(res.data.data, "all products rating");
      })
      .catch((error) => {});

    const params = {
      id: id,
      userId: storedUser,
    };
    axios
      .post(`${Base_url}/product/getSingle`, params)
      .then((res) => {
        console.log(res);

        setAllProduct(res.data.data, "all products");
        if (res?.data?.data) {
          setRecentlyProducts(res.data.data);
        }
        axios
          .get(
            `${Base_url}/product/get?limit=10&categoryIds=[${res?.data?.data?.category?.id}]`
          )
          .then((resp) => {
            console.log(resp?.data?.data, "==============>>>>>>>>>sub");
            setYouAlsoLike(resp?.data?.data);
          })
          .catch((error) => {});
      })
      .catch((error) => {});

    axios
      .get(`${Base_url}/search/get?userId=${storedUser}`)
      .then((res) => {
        console.log(res?.data?.data, "==============>>>>>>>>>sub");

        const uniqueSearches = res?.data?.data.filter(
          (item, index, self) =>
            index ===
            self.findIndex(
              (search) =>
                search.productId === item.productId &&
                search.userId === item.userId
            )
        );

        setTopSearch(uniqueSearches);
      })
      .catch((error) => {});

    axios
      .get(`${Base_url}/cart/checkProd?productId=${id}&userId=${storedUser}`)
      .then((res) => {
        console.log(res, "==============>>>>>>>>>sub check stateus");

        if (res?.data?.available === false) {
          setCheckState(false);
        } else {
          setCheckState(true);
          setCartDetails(res?.data?.data);
        }
      })
      .catch((error) => {});

    axios
      .get(`${Base_url}/address/user/${storedUser}`)
      .then((res) => {
        console.log(res);

        const fetchedAddresses = res?.data?.data || [];
        setAddress(fetchedAddresses);
        if (fetchedAddresses.length > 0) {
          setSelectedAddress(fetchedAddresses[0]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const [isLiked, setIsLiked] = useState(
    allProduct?.like?.includes(storedUser)
  );
  const [likesCount, setLikesCount] = useState(allProduct?.like?.length);

  const [loading, setLoading] = useState(false);
  const [loadinglike, setLoadingLike] = useState(false);

  const handleLikeDislike = async () => {
    try {
      setIsLiked(!isLiked);
      setLoadingLike(true);
      const param = {
        id: allProduct.id,
        userId: storedUser?.toString(),
      };

      const response = await axios.post(`${Base_url}/product/wishlist`, param);

      if (response.data.status === "ok") {
        setLoadingLike(false);
        toast.success(response?.data?.message);
        const params = {
          id: id,
          userId: storedUser,
        };
        axios
        .post(`${Base_url}/product/getSingle`, params)
        .then((res) => {
          console.log(res);
  
          setAllProduct(res.data.data, "all products");
          if (res?.data?.data) {
            setRecentlyProducts(res.data.data);
          }
          axios
            .get(
              `${Base_url}/product/get?limit=10&categoryIds=[${res?.data?.data?.category?.id}]`
            )
            .then((resp) => {
              console.log(resp?.data?.data, "==============>>>>>>>>>sub");
              setYouAlsoLike(resp?.data?.data);
            })
            .catch((error) => {});
        })
        .catch((error) => {});
  


      }

      if (response.data.status === "fail") {
        toast.error(response?.data?.message);
        setLoadingLike(false);
        setIsLiked(!isLiked);
        navigate("/login");
      }
    } catch (error) {
      console.error(error);
      setLoadingLike(false);
    }
  };
  const addFun = async () => {
    if (!storedUser) {
      toast.error("Please login to your account");
      navigate("/login");
    } else {
      if (!selColor || allProduct?.colorName?.length === 0) {
        toast.error("Please select a color before adding to cart");
        return;
      }

      try {
        setLoading(true);
        const params = {
          userId: storedUser,
          productId: id,
          categoryId: allProduct?.category?.id,
          subCategoryId: allProduct?.subCategory?.id,
          subSubCategoryId: allProduct?.subSubCategory?.id,
          deliveryCharge: allProduct?.deliveryType === "Free" ? 0 : 12,
          quantity: allProduct?.hasFreeProduct?quantity+1:quantity,
          isBuy: true,
          totalSellPrice: Number(allProduct?.sellPrice) * Number(quantity),
          totalOriginalPrice:
            Number(allProduct?.specialPrice?allProduct?.specialPrice:allProduct?.originalPrice) * Number(quantity),
          color: selColor,
        };

        const response = await axios.post(`${Base_url}/cart/create`, params);

        if (response.status === 200 || response.status === 201) {
          // toast.success("Added to Cart Successfully!");
          setLoading(false);
          setStateQuantity(true);
          setIsOpen(true);

          axios
            .get(
              `${Base_url}/cart/checkProd?productId=${id}&userId=${storedUser}`
            )
            .then((res) => {
              console.log(res, "==============>>>>>>>>>sub check status");

              if (res?.data?.available === false) {
                setCheckState(false);
              } else {
                setCheckState(true);
                setCartDetails(res?.data?.data);
              }
            })
            .catch((error) => {
              console.error("Error fetching cart details:", error);
            });
        } else {
          setLoading(false);
          toast.error("Failed to add to cart. Please try again.");
        }
      } catch (error) {
        if (error?.response?.data?.status === "fail") {
          toast.error(error?.response?.data?.message);
          navigate("/login");
        }
        setLoading(false);
      }
    }
  };

  const images = [
    "https://fakestoreapi.com/img/81fPKd-2AYL._AC_SL1500_.jpg",
    "https://fakestoreapi.com/img/61IBBVJvSDL._AC_SY879_.jpg",
    "https://fakestoreapi.com/img/71YXzeOuslL._AC_UY879_.jpg",
  ];

  const ReviewSummary = ({ reviews }) => {
    console.log(reviews);

    const totalReviews = reviews?.data?.length;
    const averageRating =
      reviews?.data?.reduce((total, star) => total + star, 0) / totalReviews;

    console.log(averageRating);

    const ratingDistribution = Array.from({ length: 5 }, (_, i) => ({
      rating: 5 - i,
      count: reviews?.data?.filter((star) => star === 5 - i).length,
    }));

    return (
      <div className="p-4 border rounded-md  mt-4 bg-white">
        <div className=" flex justify-start items-center flex-row">
          {/* Overall Rating */}
          <div className="flex flex-col w-2/12 items-center mb-6">
            <h1 className="text-5xl font-semibold">{reviews?.averageRating}</h1>
            <div className="flex items-center">
              {Array.from({ length: 5 }, (_, i) => (
                <span key={i} className="text-orange">
                  {i < Math.round(reviews?.averageRating) ? (
                    <FaStar />
                  ) : (
                    <FaRegStar />
                  )}
                </span>
              ))}
            </div>
            <p className="text-gray-500">{totalReviews} reviews</p>
          </div>

          {/* Rating Distribution */}
          <div className="space-y-2 w-6/12">
            <div className="flex items-center">
              <span className="w-6">5★</span>
              <div className="flex-1 mx-2 bg-gray-200 rounded-md h-3 relative">
                <div
                  className="bg-orange h-3 rounded-md absolute"
                  style={{
                    width: `${(5 * reviews?.count5) / 100}%`,
                  }}
                ></div>
              </div>
              <span className="text-gray-500 text-sm">{reviews?.count5}</span>
            </div>

            <div className="flex items-center">
              <span className="w-6">4★</span>
              <div className="flex-1 mx-2 bg-gray-200 rounded-md h-3 relative">
                <div
                  className="bg-orange h-3 rounded-md absolute"
                  style={{
                    width: `${(4 * reviews?.count4) / 100}%`,
                  }}
                ></div>
              </div>
              <span className="text-gray-500 text-sm">{reviews?.count4}</span>
            </div>

            <div className="flex items-center">
              <span className="w-6">3★</span>
              <div className="flex-1 mx-2 bg-gray-200 rounded-md h-3 relative">
                <div
                  className="bg-orange h-3 rounded-md absolute"
                  style={{
                    width: `${(3 * reviews?.count3) / 100}%`,
                  }}
                ></div>
              </div>
              <span className="text-gray-500 text-sm">{reviews?.count3}</span>
            </div>

            <div className="flex items-center">
              <span className="w-6">2★</span>
              <div className="flex-1 mx-2 bg-gray-200 rounded-md h-3 relative">
                <div
                  className="bg-orange h-3 rounded-md absolute"
                  style={{
                    width: `${(2 * reviews?.count2) / 100}%`,
                  }}
                ></div>
              </div>
              <span className="text-gray-500 text-sm">{reviews?.count2}</span>
            </div>
            <div className="flex items-center">
              <span className="w-6">1★</span>
              <div className="flex-1 mx-2 bg-gray-200 rounded-md h-3 relative">
                <div
                  className="bg-orange-400 h-3 rounded-md absolute"
                  style={{
                    width: `${(1 * reviews?.count1) / 100}%`,
                  }}
                ></div>
              </div>
              <span className="text-gray-500 text-sm">{reviews?.count1}</span>
            </div>
          </div>
        </div>

        {/* Individual Reviews */}
        <div className="mt-6 space-y-6">
          {reviews?.data?.slice(0, 3).map((review, index) => (
            <div key={index} className="flex items-start gap-2">
              <div>
                <div className=" bg-orange w-11 rounded-full  flex justify-center items-center text-white text-sm h-11">
                  {review?.user?.username?.slice(0, 1)?.toUpperCase()}
                </div>
              </div>
              <div>
                <p>{review?.user?.username}</p>
                <div className="flex items-center">
                  {Array.from({ length: 5 }, (_, i) => (
                    <span
                      key={i}
                      className={
                        i < review.star ? "text-orange" : "text-gray-400"
                      }
                    >
                      {i < review.star ? <FaStar /> : <FaRegStar />}
                    </span>
                  ))}
                </div>
                <p className="text-gray-700 pt-2 text-sm">{review?.review}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const reviewsData = [
    { rating: 5, count: 213 },
    { rating: 4, count: 127 },
    { rating: 3, count: 20 },
    { rating: 2, count: 7 },
    { rating: 1, count: 3 },
  ];

  const tabData = [
    {
      title: "Product Details",
      content: (
        <>
          <div
            className=" p-5 border"
            dangerouslySetInnerHTML={{ __html: allProduct?.detail }}
          />
        </>
      ),
    },
    {
      title: "Specification",
      content: (
        <>
          <div className="w-full flex justify-between items-center p-5 border flex-wrap">
            {allProduct?.SKU ? (
              <div className="w-[48%] border-b-2 p-5 flex justify-between items-center">
                <p className="font-bold">Article Number:</p>
                <p>{allProduct?.SKU}</p>
              </div>
            ) : null}

            {allProduct?.brand?.name ? (
              <div className="w-[48%] border-b-2 p-5 flex justify-between items-center">
                <p className="font-bold">Brand Name:</p>
                <p>{allProduct?.brand?.name}</p>
              </div>
            ) : null}

            {allProduct?.weight ? (
              <div className="w-[48%] border-b-2 p-5 flex justify-between items-center">
                <p className="font-bold">Product Weight:</p>
                <p>{allProduct?.weight} kg</p>
              </div>
            ) : null}

            {allProduct?.cartonLength ? (
              <div className="w-[48%] border-b-2 p-5 flex justify-between items-center">
                <p className="font-bold">Product Length:</p>
                <p>{allProduct?.cartonLength} cm</p>
              </div>
            ) : null}

            {allProduct?.cartonWidth ? (
              <div className="w-[48%] border-b-2 p-5 flex justify-between items-center">
                <p className="font-bold">Product Width:</p>
                <p>{allProduct?.cartonWidth} cm</p>
              </div>
            ) : null}

            {allProduct?.cartonHeight ? (
              <div className="w-[48%] border-b-2 p-5 flex justify-between items-center">
                <p className="font-bold">Product Height:</p>
                <p>{allProduct?.cartonHeight} cm</p>
              </div>
            ) : null}
          </div>
        </>
      ),
    },
    {
      title: "Review",
      content: (
        <div className=" border p-5">
          <p>Reviews</p>
          <p>Review this Product</p>
          <ul className="py-3 flex gap-2 items-center">
            {[1, 2, 3, 4, 5].map((star) => (
              <li
                key={star}
                className={`w-14 h-14 flex justify-center items-center border   border-orange rounded-md cursor-pointer transition duration-300 ${
                  star <= (hover || rating)
                    ? "   bg-orange text-white"
                    : "  text-orange"
                }`}
                onClick={() => {
                  setRating(star);

                  setIsReviewsModal(true);
                }}
                onMouseEnter={() => setHover(star)}
                onMouseLeave={() => setHover(null)}
              >
                {star <= (hover || rating) ? (
                  <FaStar size={24} />
                ) : (
                  <FaRegStar size={24} />
                )}
              </li>
            ))}
          </ul>
          <p>Be the first to review this product</p>
          {ratings?.data?.length > 0 ? (
            <ReviewSummary reviews={ratings} />
          ) : null}
        </div>
      ),
    },
    {
      title: "Delivery & Returns",
      content: (
        <div className=" border p-5">
          <h1 className=" text-lg pb-1  font-medium">FREE Pick up</h1>

          <p>
            Pick up allows you to buy online and Pick up in any BIG W store 7
            days per week.
          </p>
          <p className=" pt-1">See here for more information on Pick up.</p>
          <h1 className=" text-lg py-2  font-medium">Home Delivery</h1>
          <p>
            Want to have your order delivered? See here for more information on
            our delivery options.
          </p>
          <h1 className=" text-lg py-2  font-medium">Returns</h1>
          <p>Please click here to see Shop Zone returns policies.</p>
        </div>
      ),
    },
  ];
  const defaultTab = "Product Details";

  const handleUpdateQuantity = async (type, cid, quantity) => {
    setLoading(true);
    const newQuantity = type === "increment" ? quantity + 1 : quantity - 1;

    if (newQuantity < 1) {
      // toast.error("Quantity cannot be less than 1");

      axios
      .delete(`${Base_url}/cart/delete/${cartDetails?.id}`)
      .then((res) => {
        if (res.status === 200) {
        
          axios
          .get(
            `${Base_url}/cart/checkProd?productId=${id}&userId=${storedUser}`
          )
          .then((res) => {
            console.log(res, "==============>>>>>>>>>sub check stateus");

            if (res?.data?.available === false) {
              setCheckState(false);
            } else {
              setCheckState(true);
              setCartDetails(res?.data?.data);
            }

            
          })
          .catch((error) => {});

        }
      })
      .catch((error) => {
        console.log(error);
      });


      setLoading(false);
      return;

    }

    try {
      const response = await axios.put(`${Base_url}/cart/update/${cid}`, {
        quantity: newQuantity,
        totalSellPrice: Number(allProduct?.sellPrice) * Number(newQuantity),
        totalOriginalPrice:
          Number(allProduct?.specialPrice?allProduct?.specialPrice:allProduct?.originalPrice) * Number(newQuantity),
      });

      if (response.data.status === "ok") {
        setQuantity(newQuantity);
        // toast.success("Quantity updated successfully");

        axios
          .get(
            `${Base_url}/cart/checkProd?productId=${id}&userId=${storedUser}`
          )
          .then((res) => {
            console.log(res, "==============>>>>>>>>>sub check stateus");

            if (res?.data?.available === false) {
              setCheckState(false);
            } else {
              setCheckState(true);
              setCartDetails(res?.data?.data);
            }
          })
          .catch((error) => {});
      } else {
        toast.error("Failed to update quantity");
      }
    } catch (error) {
      console.error("Error updating quantity:", error);
      toast.error("An error occurred while updating quantity");
    } finally {
      setLoading(false);
    }
  };

  const getFormattedDate = (days) => {
    const today = new Date();
    const arrivalDate = new Date(today);

    arrivalDate.setDate(today.getDate() + days);
    const options = { day: "numeric", month: "long" };
    return arrivalDate.toLocaleDateString("en-US", options);
  };

  const AddAddress = () => {
    if (!storedUser) {
      navigate("/login");
    } else {
      navigate("/users/address");
    }
  };

  const [current, setCurrent] = useState(Date());
  return (
    <div>
      <Navbar />
      <BottomHeader />
      <SideDrawer isOpen={isOpen} setIsOpen={setIsOpen} />

      <div className=" md:flex block container md:px-4 px-0 mx-auto py-4 justify-between">
        <div className=" md:w-[70%] w-[100%]">
          <div>
            <ul className="flex mb-4 gap-1 px-3 items-center overflow-x-auto whitespace-nowrap">
              <li>
                <Link
                  to={"/"}
                  className=" text-black font-medium  text-sm border-b  border-black"
                >
                  Home
                </Link>
              </li>

              <li className=" pt-1">
                <FaAngleRight className=" text-gray-400" />
              </li>
              <li>
                <Link
                  to={`/categories/${allProduct?.category?.id}`}
                  className=" text-black font-medium  text-sm border-b  border-black"
                >
                  {allProduct?.category?.name}
                </Link>
              </li>
              <li className=" pt-1">
                <FaAngleRight className=" text-gray-400" />
              </li>
              <li>
                <Link
                  to={`/sub_categories/${allProduct?.subCategory?.id}`}
                  className=" text-black font-medium  text-sm border-b  border-black"
                >
                  {allProduct?.subCategory?.name}
                </Link>
              </li>
              <li className=" pt-1">
                <FaAngleRight className=" text-gray-400" />
              </li>
              <li>
                <Link
                  to={`/sub_sub_categories/${allProduct?.subSubCategory?.id}`}
                  className=" text-black font-medium text-sm border-b  border-black"
                >
                  {allProduct?.subSubCategory?.name}
                </Link>
              </li>
            </ul>
          </div>
          <div className="overflow-hidden relative md:w-[90%] w-[100%]">
            <div
              className="flex  relative transition-transform ease-out duration-500 h-[75vh]"
              style={{ transform: `translateX(-${curr * 100}%)` }}
            >
              {[
                allProduct?.Image1,
                allProduct?.Image2,
                allProduct?.Image3,
                allProduct?.Image4,
              ]?.map((image, i) => {
                console.log(image, "slider image============>>>>>>>>>>>>>>");
                return (
                  <div key={i} className="flex-none w-full h-full">
                    <img
                      onClick={openSlider}
                      src={image}
                      alt=""
                      className="w-full cursor-pointer h-full object-contain"
                    />
                  </div>
                );
              })}
            </div>

            <ul className=" absolute top-2  left-0">
              {allProduct?.tag === "0" ? null : (
                <li className=" bg-black flex text-sm justify-center items-center text-white  w-20 h-6">
                  {allProduct?.tag}
                </li>
              )}
            </ul>

            <button
              onClick={prev}
              className=" w-12 h-16 shadow  absolute left-12 top-56 flex  justify-center items-center bg-white/80 text-gray-800 hover:bg-white"
            >
              <TfiAngleLeft size={20} className="" />
            </button>
            <button
              onClick={next}
              className=" w-12 h-16  absolute right-14 top-56 flex justify-center items-center shadow bg-white/80 text-gray-800 hover:bg-white"
            >
              <TfiAngleRight size={20} />
            </button>
            {/* </div> */}
          </div>
          <div className=" mt-5 md:block hidden">
            <div className="flex items-center justify-center gap-2">
              {[
                allProduct?.Image1,
                allProduct?.Image2,
                allProduct?.Image3,
                allProduct?.Image4,
              ]?.map((_, i) => (
                <div
                  key={i}
                  onClick={() => goToSlide(i)}
                  className={`
              transition-all w-20 p-2 h-20 border overflow-hidden bg-white 
              ${curr === i ? " w-20 h-20  border-orange " : "bg-opacity-50"}
            `}
                >
                  <img
                    src={_}
                    alt=""
                    className=" w-full h-full   object-center  "
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className=" md:w-[45%] w-[100%] p-4">
          <div className=" border-b">
            <p className=" pb-2 text-2xl">{`${allProduct?.name}`}</p>
            <p
  className={`pb-2 font-medium text-xl transition-colors duration-500 ease-in-out ${
    allProduct?.hasFreeProduct && allProduct?.hasSpecialPrice
      ? " text-blue-500 animate-bounce"
      : !allProduct?.hasFreeProduct && allProduct?.hasSpecialPrice
      ? "text-blue-500 animate-bounce"
      : "text-gray-500"
  }`}
>
  {allProduct?.hasFreeProduct && allProduct?.hasSpecialPrice ? (
    <motion.span
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.8, ease: "easeInOut" }}
    >
      (Buy One Get One Free)
    </motion.span>
  ) : !allProduct?.hasFreeProduct && allProduct?.hasHalfPrice ? (
    <motion.span
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.8, ease: "easeInOut" }}
    >
      (1/2 Price)
    </motion.span>
  ) : (
    ""
  )}
</p>
            <div className=" flex  items-center  gap-2 pb-3">
              <StarRating
                rating={
                  allProduct?.averageRating ? allProduct?.averageRating : 0
                }
              />{" "}
              ({allProduct?.averageRating ? allProduct?.averageRating : 0})
              rating
            </div>
          </div>
          <div className=" pt-5 pb-4 flex  items-center gap-4">
          
            <span className="text-black flex items-center font-bold text-2xl">
            {/* <sup>
              {allProduct?.sellPrice}
            </sup> */}
              <sup>$</sup> <span className="  text-5xl m-0">{ allProduct?.specialPrice?allProduct?.specialPrice:allProduct?.originalPrice}  </span> <sub>AUD</sub>
            </span>
            {allProduct?.hasSpecialPrice?<span className="bg-yellow-500 text-black font-bold text-xs px-2 py-1 rounded">
              SAVE ${allProduct?.originalPrice - allProduct?.specialPrice} AUD
            </span>:null}

            

          </div>
       
          <p className="text-lg">Selected color:</p>
          <ul className=" flex gap-4 py-1.5 items-center">
            {allProduct?.colorName?.map((i) => {
              return (
                <div>
                  <li
                    className={`${selColor === i?' border-gray-400':''} w-11 h-11 rounded-full border-2 border-gray-300 flex justify-center items-center cursor-pointer`}
                    style={{ backgroundColor: i?.toLowerCase() }}
                    onClick={() => {
                      setSelColor(i);
                    }}
                  >
                    {selColor === i ? (
                      <IoMdCheckmark size={25} className=" text-gray-500" />
                    ) : (
                      <small className="opacity-0">Selected</small>
                    )}
                  </li>
                </div>
              );
            })}
          </ul>

          <div className=" flex gap-3 items-center">
            {checkState === true ? (
              <>
                <div className=" flex  items-center">
                  <button
                    onClick={() =>
                      handleUpdateQuantity(
                        "decrement",
                        cartDetails?.id,
                        cartDetails?.quantity
                      )
                    }
                    disabled={loading}
                    className=" flex justify-center   text-white items-center w-14 h-10  bg-primary"
                  >
                    <FaMinus />
                  </button>
                  <input
                    value={cartDetails?.quantity}
                    className=" text-center border w-full outline-none  p-2"
                  />
                  <button
                    onClick={() =>
                      handleUpdateQuantity(
                        "increment",
                        cartDetails?.id,
                        cartDetails?.quantity
                      )
                    }
                    disabled={loading}
                    className=" flex justify-center   text-white items-center w-14 h-10  bg-primary"
                  >
                    <FaPlus />
                  </button>
                </div>
              </>
            ) : (
              <button
                onClick={addFun}
                disabled={loading || allProduct?.Status === "Out Of Stock"}
                className={`text-white rounded-md bg-black w-full py-3 ${
                  loading || allProduct?.Status === "Out Of Stock"
                    ? "opacity-50 cursor-not-allowed"
                    : "hover:bg-gray-800"
                }`}
              >
                {loading ? (
                  <div className="flex items-center justify-center">
                    <svg
                      className="animate-spin h-6 w-6 text-gradient-to-r from-blue-500 to-purple-500"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8v4a4 4 0 100 8h4a8 8 0 01-8 8z"
                      ></path>
                    </svg>
                    Loading...
                  </div>
                ) : allProduct?.Status === "Out Of Stock" ? (
                  "Out Of Stock"
                ) : (
                  "Add to Cart"
                )}
              </button>
            )}

            <div className=" cursor-pointer flex justify-center items-center border  w-16 rounded-sm h-12">
              {loadinglike ? (
                <svg
                  className="animate-spin h-6 w-6 text-gradient-to-r from-blue-500 to-purple-500"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8v4a4 4 0 100 8h4a8 8 0 01-8 8z"
                  ></path>
                </svg>
              ) : allProduct?.like?.includes(storedUser) ? (
                <FaHeart
                  size={25}
                  onClick={() => handleLikeDislike(allProduct.id)}
                  className="text-red-500"
                />
              ) : (
                <FaRegHeart
                  size={25}
                  onClick={() => handleLikeDislike(allProduct.id)}
                  className="text-gray-500"
                />
              )}

              <span className=" text-sm    font-medium">
                {allProduct?.likes?.length}
              </span>
            </div>
          </div>
          <div className=" pt-3">
            <div className=" py-3 border-b">
              <p className=" flex  font-semibold gap-1">
                {" "}
                <svg
                  width={24}
                  height={24}
                  viewBox="0 0 24 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  role="img"
                  className="Icon Icon_Icon__Lc37_ icon"
                  data-di-res-id="b043ea82-c600846d"
                  data-di-rand={1736668257088}
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.965 18.006a3.5 3.5 0 0 1-6.93 0H1v-12a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v2h3l3 4.056v5.944h-2.035a3.5 3.5 0 0 1-6.93 0h-5.07Zm12.035-2h-.337A3.5 3.5 0 0 0 17 14.042v-4.036h1.992L21 12.721v3.285Zm-6-9H3v8.05a3.5 3.5 0 0 1 5.663.95h5.674c.168-.353.393-.674.663-.95v-8.05Zm2.5 12a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3ZM7 17.506a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0Z"
                    fill="#333"
                  />
                </svg>
                {allProduct?.deliveryType === "Free"
                  ? "No Delivery Charges"
                  : "Delivery - from $12"}
              </p>

              <ul className=" pt-4  flex flex-col gap-2">
                <li>
                  <div className=" gap-3 mb-4">
                    <p className="capitalize">{defaultAddress?.addressLine1}</p>
                    <p className="capitalize pb-2">{defaultAddress?.country}</p>
                    <div className="">
                      {defaultAddress ? (
                        <Link
                          to={"/users/address"}
                          className="  cursor-pointer py-2 text-black underline font-medium"
                        >
                          Change Location
                        </Link>
                      ) : (
                        <div
                          onClick={() => AddAddress()}
                          className="  cursor-pointer  text-black underline font-medium"
                        >
                          Add Your Location
                        </div>
                      )}
                    </div>
                  </div>
                </li>
                <li className=" flex gap-1.5">
                  <IoMdCheckmark size={20} className=" text-green" />

                  <p className=" m-0">
                    {allProduct?.deliveryType} - {moment(current).format("LL")}{" "}
                    to {getFormattedDate(4)}
                  </p>
                </li>

                <li className=" flex gap-1.5">
                  <IoMdClose size={20} className=" text-red" />

                  <p className=" m-0">
                    4 Days - Arrives on or before {getFormattedDate(4)}
                  </p>
                </li>

                {/* <li className=" flex gap-1.5">
                  <IoMdClose size={20} className=" text-red" />

                  <p className=" m-0">
                    Same Day - Order by 12pm Mon-Fri for delivery between
                    6pm-9pm
                  </p>
                </li> */}
              </ul>
            </div>
            <div>
              <h4 className=" pt-2 font-medium flex gap-1.5 items-center">
                {" "}
                <svg
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  role="img"
                  className="Icon Icon_Icon__Lc37_ SellerMessage_icon__3NOT4"
                  data-di-res-id="eab204d2-97389e1c"
                  data-di-rand={1736668255993}
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M4 0a4 4 0 0 0-4 4v16a4 4 0 0 0 4 4h16a4 4 0 0 0 4-4V4a4 4 0 0 0-4-4H4ZM2 4a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v16a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V4Zm7.536 6.93c.3-.434.807-.764 1.368-.887.265-.059.845-.057 1.136.003.85.176 1.456.74 1.584 1.476l.018.102h-1.43l-.075-.144c-.15-.29-.508-.436-.877-.36-.473.1-.765.5-.802 1.1-.035.589.187 1.033.614 1.231.116.053.205.067.443.067.271 0 .311-.008.467-.094.181-.1.346-.312.346-.444 0-.071-.005-.072-.36-.072h-.36v-.846h2.106l-.017.446c-.01.259-.039.526-.068.634-.255.942-1.02 1.465-2.146 1.465-.983 0-1.727-.416-2.111-1.18-.384-.763-.315-1.803.164-2.497ZM3 14.504V10.085l1.26.014c.828.009 1.307.026 1.397.05.384.104.671.326.815.63.072.153.08.21.08.517 0 .298-.009.362-.07.478-.09.169-.277.351-.44.43l-.124.06.16.089a.882.882 0 0 1 .433.451c.092.187.096.208.083.517-.011.27-.027.35-.099.5-.11.23-.37.47-.602.556-.287.108-.538.126-1.743.126L3 14.504Zm4.246-4.389c-.01.014-.014 1.004-.01 2.2l.006 2.173h1.314v-4.382l-.647-.008c-.356-.005-.654.003-.663.017Zm7.786 2.527-.792-2.2-.125-.353.692.008.693.009.114.313c.064.172.254.697.423 1.167.169.47.313.848.32.841.006-.006.131-.419.278-.916.146-.497.3-1.02.344-1.162l.078-.259h1.026l.048.149c.026.082.111.367.19.634.298 1.03.451 1.54.464 1.552.007.007.203-.511.435-1.153l.421-1.166.68-.008c.532-.007.679 0 .679.03 0 .023-.162.491-.361 1.042l-.779 2.16-.416 1.158-.567.009-.566.008-.125-.4-.366-1.19a15.296 15.296 0 0 0-.252-.782c-.004.005-.15.474-.324 1.041-.174.568-.338 1.1-.365 1.182l-.048.15-.567-.01-.567-.008-.665-1.846Zm-9.78-1.135c.072-.26-.068-.479-.344-.538a2.197 2.197 0 0 0-.376-.034h-.217v.908h.242c.416 0 .63-.104.695-.336Zm-.015 1.82a.442.442 0 0 0-.209-.595c-.056-.03-.19-.044-.399-.044h-.314v.877h.314c.379 0 .522-.056.608-.238Z"
                    fill="#333"
                  />
                </svg>
                Sold & shipped by Shop Zone
              </h4>

              <h4 className=" font-medium pt-2 flex gap-1.5 items-center">
                {" "}
                <svg
                  width={24}
                  height={24}
                  viewBox="0 0 21 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  role="img"
                  className="Icon Icon_Icon__Lc37_ SellerMessage_icon__3NOT4"
                  data-di-res-id="eab204d2-71ec4874"
                  data-di-rand={1736668255993}
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M17.074 2.93c-3.667-3.668-9.472-3.892-13.4-.673L2.577 1.161l-1.06 4.596 4.596-1.06L5.096 3.68l.22-.166a8 8 0 1 1-3.052 4.45L.33 7.454l-.077.313a9.993 9.993 0 0 0 2.679 9.304c3.905 3.905 10.237 3.905 14.142 0 3.905-3.905 3.905-10.237 0-14.142ZM9.759 4.895a.65.65 0 0 1 .482 0l4.3 1.715a.65.65 0 0 1 .409.633v5.113a.65.65 0 0 1-.36.582l-4.298 2.142h-.003a.65.65 0 0 1-.582 0L5.41 12.939a.65.65 0 0 1-.36-.582V7.214a.65.65 0 0 1 .424-.61l4.285-1.708Zm2.786 2.318L10 8.23 7.455 7.214 10 6.2l2.545 1.014Zm1.104 4.741V8.174l-3 1.196v4.08l3-1.495Zm-4.3 1.495-3-1.495V8.173l3 1.196v4.081Z"
                    fill="#333"
                  />
                </svg>
                90 day change of mind returns.{" "}
                <Link to={"/delivery-pickup/returns"} className=" font-normal text-sm text-blue-600">
                  Some exclusions apply
                </Link>{" "}
              </h4>
            </div>
          </div>
          <div className=" flex pt-8 items-center">
            <div className=" px-4 border-r">
              <img
                src={
                  "https://www.bigw.com.au/static/afterpay/afterpay-logo.svg"
                }
                className=" w-28"
                alt=""
              />
              <p className=" m-0 text-[12px] pt-2">
                4 payments of <strong>$1.50</strong>
              </p>
              <p onClick={()=>setAfterPay(true)} className=" text-[13px] hover:underline  cursor-pointer text-blue-600 m-0">Learn more</p>
            </div>
            <div className=" px-4 border-r">
              <img
                src={"https://www.bigw.com.au/static/logos/paypal-in-four.svg"}
                className=" w-28"
                alt=""
              />
              <p className=" m-0 text-[12px] pt-2">Pay In 4 instalments</p>
              <p onClick={()=>setPaypal(true)} className=" hover:underline cursor-pointer text-[13px] text-blue-600 m-0">Learn more</p>
            </div>
            <div className=" px-4 border-r">
              <img
                src={"https://www.bigw.com.au/static/logos/zip.svg"}
                className=" w-14"
                alt=""
              />
              <p className=" m-0 text-[12px] pt-2">From $10 a week</p>
              <p onClick={()=>setZipPay(true)} className=" cursor-pointer hover:underline text-[13px] text-blue-600 m-0">Learn more</p>
            </div>
          </div>
        </div>
      </div>

      <div className=" container md:px-10 px-3 mx-auto pb-10">
        <Tabs tabs={tabData} defaultTab={defaultTab} />

        {/* <ProductSlider top={20} */}

        {/* {data.map((item, index) => {
            return (
              <>
                <Product item={item.image} />
              </>
            );
          })} */}
        {isAuthenticated ? (
          <div className="  container pt-6  mx-auto px-3">
            <div className=" pb-10  container mx-auto">
              <h4 className="h4">Recently Viewed</h4>
              <div className=" pt-3">
                <ProductSlider top={20}
                  items={topSearch?.map((item, index) => {
                    return (
                      <>
                      <div className="">
                <Product type={'slider'} item={item?.product} />
                </div>
                      </>
                    );
                  })}
                />
              </div>
            </div>
          </div>
        ) : null}

        <div className="  container pt-6  mx-auto px-3">
          <div className=" pb-10  container mx-auto">
            <h4 className="h4">You may also like</h4>
            <div className=" pt-3">
              <ProductSlider top={20}
                items={youAlsoLike?.map((item, index) => {
                  return (
                    <>
                     <div className="">
                <Product type={'slider'} item={item} />
                </div>
                    </>
                  );
                })}
              />
            </div>
          </div>
        </div>

        {/* slider modal */}

        <PayPalModal setIsModalOpen={setPaypal} isModalOpen={Paypal} />
        <AfterPayModal setIsModalOpen={setAfterPay} isModalOpen={afterPay} />
        <ZipPayModal setIsModalOpen={setZipPay} isModalOpen={zipPay} />


        <WriteReviews
          setRating={setRating}
          rating={rating}
          setRatings={setRatings}
          getData={allProduct}
          setIsModalOpen={setIsReviewsModal}
          isModalOpen={isReviewsModal}
        />

        <Modal
          className={"sm:max-w-7xl "}
          isOpen={isModalSlider}
          onClose={closeSlider}
          setIsModalOpen={() => setIsModalSlider(false)}
        >

          {/* Modal Content */}
          <div className=" relative ">
            <div className=" flex justify-between items-center">
              <div></div>

              {/* <MdClose onClick={() => setIsModalSlider(false)} size={25} /> */}
            </div>
            <div className="flex flex-col  absolute z-50  top-0 items-center justify-center gap-2">
              {[
                allProduct?.Image1,
                allProduct?.Image2,
                allProduct?.Image3,
                allProduct?.Image4,
              ]?.map((_, i) => (
                <div
                  key={i}
                  onClick={() => goToSlide(i)}
                  className={`
              transition-all w-20 h-20 p-2 overflow-hidden  bg-white 
              ${
                curr === i ? " w-14 h-14 border border-orange" : "bg-opacity-50"
              }
            `}
                >
                  <img
                    src={_}
                    alt=""
                    className=" w-full h-full   object-center  "
                  />
                </div>
              ))}
            </div>
            <div className="  mx-auto">
              <div className="overflow-hidden   w-96  relative">
                <div
                  className="flex  transition-transform ease-out duration-500"
                  style={{ transform: `translateX(-${curr * 100}%)` }}
                >
                  {[
                    allProduct?.Image1,
                    allProduct?.Image2,
                    allProduct?.Image3,
                    allProduct?.Image4,
                  ]?.map((image, i) => {
                    console.log(
                      image,
                      "slider image============>>>>>>>>>>>>>>"
                    );
                    return (
                      <div key={i} className="flex-none w-full h-full">
                        <img
                          onClick={openSlider}
                          src={image}
                          alt=""
                          className="w-full cursor-pointer h-full object-contain"
                        />
                      </div>
                    );
                  })}
                </div>
                {/* <div className="absolute inset-0 flex items-center justify-between"> */}
                <button
                  onClick={prev}
                  className=" w-12 h-16 shadow  absolute left-0 top-56 flex  justify-center items-center bg-white/80 text-gray-800 hover:bg-white"
                >
                  <TfiAngleLeft size={20} className="" />
                </button>
                <button
                  onClick={next}
                  className=" w-12 h-16  absolute right-0 top-56 flex justify-center items-center shadow bg-white/80 text-gray-800 hover:bg-white"
                >
                  <TfiAngleRight size={20} />
                </button>
                {/* </div> */}
              </div>
            </div>
          </div>

          {/* Close button */}
          {/* <div className="bg-gray-100 p-4">
            <button
              onClick={closeModal}
              className="bg-blue-500 text-white px-4 py-2 rounded"
            >
              Close Modal
            </button>
          </div> */}
        </Modal>
      </div>
      <Subscribe />
      <Footer />
    </div>
  );
};

export default ProductDetails;
