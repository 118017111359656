import React, { useEffect, useState } from "react";
import { RxHamburgerMenu } from "react-icons/rx";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { Base_url } from "../../utils/Base_url";
import { LiaAngleRightSolid } from "react-icons/lia";
import { useDispatch, useSelector } from "react-redux";
import { removeUser } from "../../store/nextSlice";
import { toast } from "react-toastify";

const BottomHeader = () => {
  const userData = useSelector((state) => state.auth.user);

  console.log(userData);
  const [desktopMenuOpen, setDesktopMenuOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [hoveredSubMenu, setHoveredSubMenu] = useState(null);
  const [categories, setCategories] = useState([]);
const  dispatch = useDispatch();
  const [brands, setBrands] = useState([]);
  const [getDetails, setGetDetails] = useState({});
  console.log(brands, "brans");

const navigate = useNavigate()

  const logout = ()=>{

    dispatch(removeUser());
    localStorage.removeItem('user_ID');
    localStorage.removeItem('user_data');
    toast.success('logout successfully!');
     navigate('/');

     window.location.reload();
  
  }
  const [subCategory, setSubCategories] = useState(null);
  const closeSubMenu = () => {
    setDesktopMenuOpen(false);
    setSelectedCategory(null);
  };

  const toggleDesktopMenu = () => {
    setDesktopMenuOpen((prev) => !prev);
  };

  const Items = [
    {
      id: 1,
      name: "Home",
      Url: "/",
    },
    {
      id: 2,
      name: "Shop",
      Url: "/shop",
    },
    {
      id: 2,
      name: "Catalogues",
      Url: "/catalogues",
    },
    {
      id: 2,
      name: "Clearance",
      Url: "/clearance",
    },
    {
      id: 3,
      name: "Deals",
      Url: "/deals",
    },
    // {
    //   id:3,
    //   name:'Blog',
    //   Url:'/sustainability'
    // },
    // {
    //   id:4,
    //   name:'About Us',
    //   Url:'/'
    // },
    {
      id: 4,
      name: "Contact Us",
      Url: "/contact_us",
    },
  ];

  useEffect(() => {
    const fetchAllAge = async () => {
      try {
        const response = await axios.get(`${Base_url}/category/getNav`);

        console.log(response);

        setCategories(response?.data?.data);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchAllAge();
  }, []);

  const FetchBrands = async (id) => {
    try {
      const response = await axios.get(`${Base_url}/category/getBrand/${id}`);

      console.log(response?.data?.data?.brand);

      setBrands(response?.data?.data?.brand);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };
   
  const [subSubCategory, setSubSubCategory] = useState([]);

  console.log(subSubCategory);

  return (
    <>
      {/* Top Nav */}
      <nav className="hidden md:block bg-[#272727]">
        <div className="container mx-auto flex items-center justify-between h-14">
          {/* Categories Button */}
          <button
            onClick={toggleDesktopMenu}
            className="ml-5 flex items-center px-4 py-2 bg-white rounded-md"
          >
            <RxHamburgerMenu className="mr-2 w-5 h-5" />
            Shop By Department
          </button>

          {/* Nav Links */}
          <div className="flex gap-8">
            {Items?.map((item, index) => (
              <Link
                key={index}
                className="text-white hover:border-b  hover:text-[#EAE5D5]"
                to={`${item?.Url}`}
              >
                {item?.name}
              </Link>
            ))}
          </div>

          {/* Auth Links */}
          {userData ? (
            <div className=" flex items-center gap-5">
              <div className="">
                <p className=" text-white text-sm">{`${userData?.firstName} ${userData?.lastName}`}</p>
                <p className=" text-gray-300 text-[12px]">{userData?.email}</p>
              </div>
              <div onClick={()=>logout()} className="text-white border cursor-pointer px-3 hover:border-b  hover:text-[#EAE5D5]">
                Logout
              </div>
            </div>
          ) : (
            <div className="flex gap-4">
              <Link
                to="/login"
                className="text-white hover:border-b  hover:text-[#EAE5D5]"
              >
                Login
              </Link>
              <span className="text-white">|</span>
              <Link
                to="/login"
                className="text-white hover:border-b  hover:text-[#EAE5D5]"
              >
                Sign Up
              </Link>
            </div>
          )}
        </div>
      </nav>

      {/* Desktop Menu */}
      {desktopMenuOpen && (
        <section className="absolute z-50 left-0 right-0 bg-[rgba(0,0,0,0.3)]  h-full ">
          <div className="container mx-auto flex">
            {/* Categories List */}
            <div className="w-1/5 overflow-y-auto  h-96 bg-white border-r">
              <ul>
                {categories.map((category, index) => (
                  <li
                    key={index}
                    className={`flex items-center gap-2 px-4 py-2 cursor-pointer ${
                      selectedCategory === category.subCategory
                        ? "bg-gray-200"
                        : "hover:bg-gray-100"
                    }`}
                    onClick={() => {
                      setSelectedCategory(category.subCategory);
                      setSubCategories(category);
                      setSubSubCategory(null);
                    }}
                    // onMouseLeave={() => {setHoveredSubMenu(null)
                    //   setSubSubCategory(null);
                    // }}
                  >
                    {/* {category.icon} */}
                    {category.name}
                    <LiaAngleRightSolid className="ml-auto" />
                  </li>
                ))}
              </ul>
            </div>

            {/* Subcategories */}
            {selectedCategory?.length > 0 ? (
              <div className=" flex bg-white h-96    w-72  overflow-y-auto  flex-col">
                <ul className=" flex flex-col ">
                   <Link
                    to={`/categories/${subCategory?.id}`}
                    className=" px-4 hover:bg-gray-100 py-2 flex justify-center items-center"
                  >
                    <h3 className=" font-medium flex  w-full text-gray-700">
                      Shop all {subCategory?.name}
                    </h3>
                  </Link>

                 {/* <Link
                    to={"#"}
                    className=" px-4 py-2 hover:bg-gray-100  flex justify-center items-center"
                  >
                    <h3 className=" font-medium flex  w-full text-gray-700">
                      Deals
                    </h3>
                  </Link>

                  <Link
                    onClick={() => setDesktopMenuOpen(false)}
                    to={`/new-all-arrivals?product=undefined&limit=10&page=1&sortBy=&brandIds=&categoryIds=[${subCategory?.id}]&subCategoryIds=&subSubCategoryIds=&colorName=&minPrice=&maxPrice=&priceComparison=`}
                    className=" px-4 hover:bg-gray-100  py-2 flex justify-center items-center"
                  >
                    <h3 className=" font-medium flex  w-full text-gray-700">
                      New Arrivals
                    </h3>
                  </Link>

                  <li
                    onClick={() => {
                      FetchBrands(subCategory?.id);
                      setSubSubCategory(null);
                    }}
                    className=" px-4 py-2 hover:bg-gray-100  cursor-pointer flex justify-center items-center"
                  >
                    <h3 className=" font-medium flex  w-full text-gray-700">
                      Shop by brand
                    </h3>
                    <LiaAngleRightSolid className="ml-auto" />
                  </li> */}
                </ul>

                {/* <h3 className=" px-4 font-medium  ">Categories</h3> */}

                {categories
                  .find((cat) => cat.subCategory === selectedCategory)
                  ?.subCategory.map((submenu, index) => (
                    <>
                      <div
                        onClick={() => {
                          setSubSubCategory(submenu?.subSubCategory);
                          setGetDetails(submenu);
                          setBrands(null);
                        }}
                        key={index}
                        className="px-4  hover:bg-gray-100  cursor-pointer py-2 flex "
                      >
                        <h3 className=" font-medium  flex  w-full text-gray-700">
                          {submenu.name}
                        </h3>

                        <LiaAngleRightSolid className="ml-auto" />
                      </div>
                    </>
                  ))}
              </div>
            ) : null}

            {subSubCategory?.length > 0 ? (
              <div className=" flex w-64 cursor-pointer bg-white h-96   overflow-y-auto  flex-col">
                <ul className="mt-2 space-y-1">
                  <Link to={`/sub_categories/${getDetails?.id}`}>
                    <h3 className=" px-2 hover:bg-gray-100  py-2">
                      Shop all {getDetails?.name}
                    </h3>
                  </Link>

                  {subSubCategory?.map((item, itemIndex) => (
                    <li key={itemIndex} className="relative group">
                      <Link
                        to={`/sub_sub_categories/${item.id}`}
                        className="block px-2 py-1 hover:bg-gray-100"
                      >
                        {item.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            ) : null}

            {brands?.length > 0 ? (
              <div className=" flex bg-white h-96    w-72  overflow-y-auto  flex-col">
                <ul className=" mt-2 space-y-1">
                  {brands?.map((item, index) => (
                    <li key={index}>
                      <Link
                        to={`/shop?product=undefined&limit=10&page=1&sortBy=&categoryIds=&subCategoryIds=&subSubCategoryIds=&brandIds=[${item?.id}]&colorName=&minPrice=&maxPrice=&priceComparison=`}
                        className="block px-2 py-1 hover:bg-gray-100"
                      >
                        {item.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            ) : null}

            {/* Close Button */}
            {/* <button
              onClick={closeSubMenu}
              className="absolute top-3 right-3 text-gray-700"
            >
              <IoMdClose className="w-6 h-6" />
            </button> */}
          </div>
        </section>
      )}
    </>
  );
};

export default BottomHeader;