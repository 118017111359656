import React, { useEffect, useState } from "react";
import Navbar from "../../components/Header/Navbar";
import Footer from "../../components/footer";
import BottomHeader from "../../components/Header/BottomHeader";
import Button from "../../components/Button";
import CategoryCard from "../../components/cards/CategoryCard";
import ProductSlider from "../../components/sliders/productSlider";
import { Base_url } from "../../utils/Base_url";
import axios from "axios";
import { Link } from "react-router-dom";

const ShopZoneMarket = () => {
  const [allCategory, setAllCategory] = useState([]);
const [halfDeal,setHalfDeal] = useState([]);
const [freeDeal,setFreeDeal] = useState([]);
  const [allDeals, setAllDeals] = useState([]);

  console.log(allDeals);

  useEffect(() => {
    axios
      .get(`${Base_url}/deal/get`)
      .then((res) => {
        console.log(res?.data?.data, "==============>>>>>>>>>sub");

        setAllCategory(res?.data?.data);
      })
      .catch((error) => {});


      axios
      .get(`${Base_url}/deal/get?type=${'half price'}`)
      .then((res) => {
        console.log(res?.data?.data, "==============>>>>>>>>>sub");

        setHalfDeal(res?.data?.data);
      })
      .catch((error) => {});

      axios
      .get(`${Base_url}/deal/get?type=${'buy one get one free'}`)
      .then((res) => {
        console.log(res?.data?.data, "==============>>>>>>>>>sub");

        setFreeDeal(res?.data?.data);
      })
      .catch((error) => {});

    axios
      .get(`${Base_url}/deal/category`)
      .then((res) => {
        console.log(res?.data?.data, "==============>>>>>>>>>sub");

        setAllDeals(res?.data?.data);
      })
      .catch((error) => {});
  }, []);


   const [banner, setBanner] = useState([]);
    useEffect(() => {
      axios
        .get(`${Base_url}/banner/get?location=deals`)
        .then((res) => {
          console.log(res?.data?.data, "==============>>>>>>>>>sub");
  
          setBanner(res?.data?.data);
        })
        .catch((error) => {});
    }, []);

  return (
    <>
      <Navbar />
      <BottomHeader />
      <section className=" pt-2">
        <div className="max-w-screen-xl  mx-auto px-4">
          <p>Home / Deals </p>

          <div className=" pt-10">
            <h1 className=" text-center pb-6 text-3xl">Boxing Day Sale</h1>
          </div>

          <div className="   my-8 py-3 mx-auto">
            <div className="   pt-4 gap-6  items-center">
              {allCategory?.length > 0 ? (
                <ProductSlider top={20}
                  items={allCategory.map((item, index) => {
                    return (
                      <>
                        <CategoryCard
                          key={index}
                          url={`/categories/${item?.id}`}
                          image={item?.image}
                          name={item?.name}
                        />
                      </>
                    );
                  })}
                />
              ) : (
                <p>Loading products...</p>
              )}
            </div>
          </div>
          {halfDeal?.length > 0 ? (
          <div className="my-8 flex   items-center mx-auto">

            <div className="   w-3/12">
            <div   className="  border bg-gray-50 hover:bg-gray-100 cursor-pointer rounded-[12px] flex justify-center items-center px-8 py-1 w-full h-80">

<div className=" w-56 h-56 border-4 bg-black border-black rounded-full flex  justify-center items-center">
   <h1 className=" font-bold text-3xl text-white text-center capitalize">1/2 Price	</h1>
</div>
 
</div>
            </div>
            <div className=" px-8  w-9/12">

          
                <ProductSlider top={20}
                  items={halfDeal.map((item, index) => {
                    return (
                      <>
                         <Link
                    to={
                      item?.productId
                        ? `/product_details/${item?.productId}`
                        : item?.link
                    }
                    key={index}
                    className="bg-white border  w-60 rounded-lg overflow-hidden"
                  >
                    <img
                      src={item.image}
                      alt={item.title}
                      className="w-full object-cover"
                    />
                    <div className="p-4">
                      <div className="flex justify-center items-center space-x-2 mb-2">
                        <h3 className="text-lg text-center font-semibold text-gray-800">
                          {item.name}
                        </h3>
                      </div>
                      {/* <p className="text-sm text-gray-600 text-center">{deal.name}</p> */}
                    </div>
                  </Link>
                      </>
                    );
                  })}
                />
             
            </div>
            
          </div>
           ) :null}

        
                  <div className=" pt-3">
                    <Link to={`${banner?.[0]?.link}`} className=" py-3  mx-auto">
                      <img
                        src={banner?.[0]?.image}
                        className=" mx-auto w-full"
                        alt=""
                      />
                    </Link>
                  </div>

                  {freeDeal?.length > 0 ? (
          <div className="my-8 flex   items-center mx-auto">

            <div className="   w-3/12">
              <div   className="  border bg-gray-50 hover:bg-gray-100 cursor-pointer rounded-[12px] flex justify-center items-center px-8 py-1 w-full h-80">

                <div className=" w-56 h-56 border-4 bg-black border-black rounded-full flex  justify-center items-center">
                   <h1 className=" font-bold text-3xl text-white text-center capitalize">buy one get one free	</h1>
                </div>
                 
              </div>
            </div>
            <div className=" px-8  w-9/12">

          
                <ProductSlider top={20}
                  items={freeDeal.map((item, index) => {
                    return (
                      <>
                         <Link
                    to={
                      item?.productId
                        ? `/product_details/${item?.productId}`
                        : item?.link
                    }
                    key={index}
                    className="bg-white border  w-60 rounded-lg overflow-hidden"
                  >
                    <img
                      src={item.image}
                      alt={item.title}
                      className="w-full object-cover"
                    />
                    <div className="p-4">
                      <div className="flex justify-center items-center space-x-2 mb-2">
                        <h3 className="text-lg text-center font-semibold text-gray-800">
                          {item.name}
                        </h3>
                      </div>
                      {/* <p className="text-sm text-gray-600 text-center">{deal.name}</p> */}
                    </div>
                  </Link>
                      </>
                    );
                  })}
                />
             
            </div>
            
          </div>
           ) :null}
        </div>
      </section>

      {allDeals?.map((item, index) => {
        return (
          item?.deal?.length > 0 && (
            <div className="max-w-screen-xl mx-auto px-4 py-8">
              <div className="flex justify-between items-center mb-6">
                <h2 className="text-2xl  font-medium text-gray-800">
                  {item?.name}
                </h2>
              </div>
              <hr className=" mb-10" />

              <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-6">
                {item?.deal?.map((deal, index) => (
                  <Link
                    to={
                      deal?.productId
                        ? `/product_details/${deal?.productId}`
                        : deal?.link
                    }
                    key={index}
                    className="bg-white border  rounded-lg overflow-hidden"
                  >
                    <img
                      src={deal.image}
                      alt={deal.title}
                      className="w-full object-cover"
                    />
                    <div className="p-4">
                      <div className="flex justify-center items-center space-x-2 mb-2">
                        <h3 className="text-lg text-center font-semibold text-gray-800">
                          {deal.name}
                        </h3>
                      </div>
                      {/* <p className="text-sm text-gray-600 text-center">{deal.name}</p> */}
                    </div>
                  </Link>
                ))}
              </div>


              
                      <div className="text-center mt-8">
                        <Link
                          to={`/categories/${item?.id}?categoryId=${item?.id}&limit=10&page=1&sortBy=&brandIds=&categoryIds=&subCategoryIds=&subSubCategoryIds=&deal=&colorName=&minPrice=&maxPrice=&priceComparison=&price=`}
                          className=" bg-primary text-white px-6 py-3 rounded hover:bg-primary"
                        >
                          Shop all {item?.name} Deals
                        </Link>
                      </div>


            </div>
          )
        );
      })}

      <Footer />
    </>
  );
};

export default ShopZoneMarket;
