import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Base_url } from "../../utils/Base_url";
import axios from "axios";
import Navbar from "../../components/Header/Navbar";
import BottomHeader from "../../components/Header/BottomHeader";
import Subscribe from "../../components/Subscribe";
import Footer from "../../components/footer";
import CategoryCard from "../../components/cards/CategoryCard";

const CatalogueCategories = () => {
  const { id } = useParams();

  const [catalogueCategories, setCatalogueCategories] = useState([]);

  const [catalogue, setCatalogue] = useState({});
  useEffect(() => {
    axios
      .get(`${Base_url}/catalogueCategory/get?catalogueId=${id}`)
      .then((res) => {
        console.log(res);

        setCatalogueCategories(res.data.data, "all products");
      })
      .catch((error) => {});

    axios
      .get(`${Base_url}/catalogue/get/${id}`)
      .then((res) => {
        console.log(res);

        setCatalogue(res.data.data, "all products");
      })
      .catch((error) => {});
  }, []);



  const [deals, setDeals] = useState([]);

  useEffect(() => {
    const fetchDeals = async () => {
      try {
        const response = await axios.get(`${Base_url}/deal/get?catalogueId=${id}`);

        console.log(response, "response");

        setDeals(response?.data?.data);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchDeals();
  }, []);


  return (
    <>
      <Navbar />
      <BottomHeader />
      <div className="max-w-screen-xl  mx-auto px-4">
        <div className=" pt-3">
          <p>
            Home /{" "}
            <Link to={"/catalogues"} className=" text-gray-500">
              Catalogues
            </Link>{" "}
            / <Link className=" text-gray-500">{catalogue?.name}</Link>{" "}
          </p>
        </div>

        <div className="w-full  overflow-x-auto py-3">
          <ul className="flex w-max justify-center items-center gap-2 m-0">
            {catalogueCategories?.map((item, index) => (
              <Link
                to={`/shop?product=undefined&limit=10&page=1&sortBy=&categoryIds=&subCategoryIds=&subSubCategoryIds=&brandIds=[${item?.id}]&colorName=&minPrice=&maxPrice=&priceComparison=`}
                key={index}
                className="  flex justify-center items-center p-2"
              >
                <p className="border px-3 py-1.5 rounded-full text-black text-sm">
                  {item?.name}
                </p>
              </Link>
            ))}
          </ul>
        </div>

        <div className=" pb-10">
          <h4 className=" text-gray-600 pt-2 font-medium text-left  text-3xl">
            Browse catalogue by category
          </h4>

          <div className="grid item1  col-span-2  mt-8  px-4 xl:grid-cols-6  grid-cols-2 gap-10">
            {catalogueCategories?.map((item, index) => (
              <Link
                to={`/catalogue-products/${item?.id}`}
                className="flex flex-col   rounded-md items-center text-center "
              >
                {item?.image ? (
                  <div className="sm:w-56 w-36 sm:h-56 h-36   items-center justify-center hover:shadow-lg transition-shadow duration-300">
                    <img
                      src={item?.image}
                      alt={item?.name}
                      className=" object-cover w-full  h-full"
                    />
                  </div>
                ) : null}
                <p className="pt-3 text-sm font-medium pb-2 text-gray-700">
                  {item?.name}
                </p>
              </Link>
            ))}
          </div>
        </div>


        
              {deals && deals?.length>0?
        
              <>
                <div className="max-w-screen-xl mx-auto ">
                      <div className="flex justify-center mt-7 px-3 items-center mb-5">
                        <h2 className="text-3xl font-semibold text-gray-800 pb-4"> {catalogue?.name} Deals </h2>
                        
                      </div>
                    </div>
              
                    <div className="max-w-screen-xl mx-auto px-4 pb-8">
                      <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-6">
                        {deals.map((deal, index) => (
                          <Link
                            to={
                              deal?.productId
                                ? `/product_details/${deal?.productId}`
                                : deal?.link
                            }
                            key={index}
                            className="bg-white border  rounded-lg overflow-hidden"
                          >
                            <img
                              src={deal.image}
                              alt={deal.title}
                              className="w-full object-cover"
                            />
                            <div className="p-4">
                              <div className="flex justify-center items-center space-x-2 mb-2">
                                <h3 className="text-lg text-center font-semibold text-gray-800">
                                  {deal.name}
                                </h3>
                              </div>
                              {/* <p className="text-sm text-gray-600 text-center">{deal.name}</p> */}
                            </div>
                          </Link>
                        ))}
                      </div>
              
                      <div className="text-center mt-8">
                        <Link
                          to="/deals"
                          className=" bg-primary text-white px-6 py-3 rounded hover:bg-primary"
                        >
                          Shop all Deals
                        </Link>
                      </div>
                    </div>
              </>:null}
          
      </div>

      <Subscribe />
      <Footer />
    </>
  );
};

export default CatalogueCategories;
