import { useEffect, useState } from "react";
import Accordion from "../Accordion/Accordion";
import Button from "../Button";
import axios from "axios";
import { Base_url } from "../../utils/Base_url";

const Faqs = () => {
  const [accordions, setAccordions] = useState([]);

  useEffect(() => {
    axios
      .get(`${Base_url}/faq/get`)
      .then((res) => {
        // Initialize all accordions as closed
        setAccordions(res?.data?.data.map((faq) => ({ ...faq, isOpen: false })));
      })
      .catch((err) => {
        console.error("Error fetching FAQs:", err);
      });
  }, []);

  const toggleAccordion = (accordionKey) => {
    // Close all accordions except the one being toggled
    const updatedAccordions = accordions.map((accordion) => ({
      ...accordion,
      isOpen: accordion.key === accordionKey ? !accordion.isOpen : false,
    }));

    setAccordions(updatedAccordions);
  };

  return (
    <div
      id="faqs"
      className="tw-overflow-x-hidden tw-relative tw-bg-no-repeat tw-w-full tw-bg-cover tw-h-auto tw-pb-20 tw-pt-10"
    >
      <div className="tw-text-center mt-7">
        <h1 className="tw-text-white tw-pt-6 pb-5 text-3xl text-center tw-font-zen-dots">
         Top FAQs
         
        </h1>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-6">
            <div className="tw-mt-12">
              {accordions.slice(0, 4).map((accordion) => (
                <Accordion
                  key={accordion.key}
                  title={accordion.question}
                  isOpen={accordion.isOpen}
                  toggleAccordion={() => toggleAccordion(accordion.key)}
                >
                  {accordion.answer}
                </Accordion>
              ))}
            </div>
          </div>
          <div className="col-lg-6 col-md-6">
            <div className="sm:tw-mt-12 tw-mt-0">
              {accordions.slice(4).map((accordion) => (
                <Accordion
                  key={accordion.key}
                  title={accordion.question}
                  isOpen={accordion.isOpen}
                  toggleAccordion={() => toggleAccordion(accordion.key)}
                >
                  {accordion.answer}
                </Accordion>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faqs;