import React from "react";
import { NavLink, Outlet } from "react-router-dom";
import Navbar from "../../components/Header/Navbar";
import Footer from "../../components/footer";
import BottomHeader from "../../components/Header/BottomHeader";

const Users = () => {

  
  return (
    <>
      <Navbar />
      <BottomHeader />
      <div className="flex flex-col container mx-auto py-10">
        <div className=" pb-8">
          <ul className="flex  border-t border-b  justify-between w-full leading-8">
         
            <NavLink
                to="/users/my_profile"
                className={({ isActive }) =>
                  isActive
                    ? " text-center py-2 text-white font-medium w-full  bg-primary"
                    : " text-center py-2 text-gray-400 font-medium w-full"
                }
              >
                Profile
              </NavLink>
              <NavLink
                to="/users/address"
                className={({ isActive }) =>
                  isActive
                    ? " text-center py-2 text-white font-medium w-full bg-primary"
                    : " text-center py-2 text-gray-400 font-medium w-full"
                }
              >
                Addresses
              </NavLink>

              {/* <NavLink
                to="/users/payments"
                className={({ isActive }) =>
                  isActive
                    ? " text-center py-2 text-white font-medium w-full bg-primary"
                    : " text-center py-2 text-gray-400 font-medium w-full"
                }
              >
                Payment
              </NavLink> */}

              <NavLink
                to="/users/order_history"
                className={({ isActive }) =>
                  isActive
                    ? " text-center py-2 text-white font-medium w-full bg-primary"
                    : " text-center py-2 text-gray-400 font-medium w-full"
                }
              >
                Order History
              </NavLink>
          </ul>
        </div>
        <div className="w-full px-4">
          <Outlet />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Users;
