import React, { useState } from 'react'
import Navbar from '../../components/Header/Navbar'
import Footer from '../../components/footer'
import BottomHeader from '../../components/Header/BottomHeader'
import Button from '../../components/Button'
import CategoryCard from '../../components/cards/CategoryCard'
import ProductSlider from '../../components/sliders/productSlider'
import { Link } from 'react-router-dom'

const Christmas = () => {
  const data = [
    {
      id:1,
      title:'Christmas Gifts for Teens',
      image:'https://s7ap1.scene7.com/is/image/bigw/W2620%20-%20Christmas_STSC3_Giftsforteens?$cms-max-image-threshold$&fmt=webp-alpha&wid=358&fit=hfit%2C1&scale=0.5&qlt=88'
    },


    {
      id:2,
      title:'Christmas Gifts for Baby',
      image:'https://s7ap1.scene7.com/is/image/bigw/W2620%20-%20Christmas_STSC1_Giftsforbaby?$cms-max-image-threshold$&fmt=webp-alpha&wid=358&fit=hfit%2C1&scale=0.5&qlt=88'
    },

    {
      id:3,
      title:'Christmas Gifts for Kids',
      image:'https://s7ap1.scene7.com/is/image/bigw/W2620%20-%20Christmas_STSC2_Giftsforkids?$cms-max-image-threshold$&fmt=webp-alpha&wid=358&fit=hfit%2C1&scale=0.5&qlt=88'
    }

    
  ]

   const [allCategory, setAllCategory] = useState([
      {
        id: 1,
        image: require("../../assets/images/category1.webp"),
        name: "Toys",
      },
      {
        id: 2,
        image:
          "https://s7ap1.scene7.com/is/image/bigw/W2629%20-%202111%20Womens%20Department-CT5?$cms-max-image-threshold$&fmt=webp-alpha&wid=211&fit=hfit%2C1&scale=0.5&qlt=88",
        name: "Womens Cloting & Accessories",
      },
      {
        id: 3,
        image:
          "https://s7ap1.scene7.com/is/image/bigw/W2616%20-%202208%20Hybrid%20Brand%20C14_mens-clothing?$cms-max-image-threshold$&fmt=webp-alpha&wid=211&fit=hfit%2C1&scale=0.5&qlt=88",
        name: "Mens Cloting & Accessories",
      },
  
      {
        id: 4,
        image:
          "https://s7ap1.scene7.com/is/image/bigw/STSC-Girls?$cms-max-image-threshold$&fmt=webp-alpha&wid=211&fit=hfit%2C1&scale=0.5&qlt=88",
        name: "Kids Cloting & Accessories",
      },
      {
        id: 5,
        image:
          "https://s7ap1.scene7.com/is/image/bigw/W2612%20-%202507_Unisexl_CT_3?$cms-max-image-threshold$&fmt=webp-alpha&wid=211&fit=hfit%2C1&scale=0.5&qlt=88",
        name: "Baby Cloting & Accessories",
      },
      {
        id: 6,
        image:
          "https://s7ap1.scene7.com/is/image/bigw/65141010609-SofaBeds_CT?$cms-max-image-threshold$&fmt=webp-alpha&wid=211&fit=hfit%2C1&scale=0.5&qlt=88",
        name: "Furniture",
      },
      {
        id: 7,
        image:
          "https://s7ap1.scene7.com/is/image/bigw/Home--Appliances-CT-2?$cms-max-image-threshold$&fmt=webp-alpha&wid=211&fit=hfit%2C1&scale=0.5&qlt=88",
        name: "Home & Appliances",
      },
      {
        id: 8,
        image:
          "https://s7ap1.scene7.com/is/image/bigw/W2619_App_CT_3_240819_Books?$cms-max-image-threshold$&fmt=webp-alpha&wid=211&fit=hfit%2C1&scale=0.5&qlt=88",
        name: "Books",
      },
      {
        id: 9,
        image:
          "https://s7ap1.scene7.com/is/image/bigw/W2603ToySaleCatalogueLaunchCT5TVs?$cms-max-image-threshold$&fmt=webp-alpha&wid=211&fit=hfit%2C1&scale=0.5&qlt=88",
        name: "Tech & Audio",
      },
      {
        id: 10,
        image:
          "https://s7ap1.scene7.com/is/content/bigw/W2619_App_CT_4_240819_Gaming?$cms-max-image-threshold$&fmt=webp-alpha&wid=211&fit=hfit%2C1&scale=0.5&qlt=88",
        name: "Gaming",
      },
      {
        id: 11,
        image:
          "https://s7ap1.scene7.com/is/image/bigw/W2619_App_CT_6_240819_Bikes?$cms-max-image-threshold$&fmt=webp-alpha&wid=211&fit=hfit%2C1&scale=0.5&qlt=88",
        name: "Sports",
      },
      {
        id: 12,
        image:
          "https://s7ap1.scene7.com/is/image/bigw/W2605_1106_Homepage_CT_2_240606_Health?$cms-max-image-threshold$&fmt=webp-alpha&wid=211&fit=hfit%2C1&scale=0.5&qlt=88",
        name: "Health & Beauty",
      },
    ]);

  return (
    <>
     <Navbar/>
     <BottomHeader/>
      <section className=' pt-2'>
     
      <div className="max-w-screen-xl  mx-auto px-4">
      <p>Home / Christmas      </p>

      <div className=' pt-10'>
        
      <h1 className=' text-center pb-6 text-3xl'>Christmas</h1>
        <img src='https://s7ap1.scene7.com/is/image/bigw/CAM_264911_Gifts_SHB?$cms-max-image-threshold$&fmt=webp-alpha&wid=1075&fit=hfit%2C1&scale=0.5&qlt=88' className=' mx-auto w-full'  alt='' />
        <p className=' text-lg text-primary pt-4 text-center'>Make this Christmas unforgettable and fill your home with more Christmas magic for less at BIG W. Celebrate the festive season with Christmas trees, decorations, dazzlings lights, and unique gifts that bring joy to everyone. </p>
        <hr/>
      </div>


      <div className="   my-8 py-3 mx-auto">
        <div className=" flex  items-center justify-between">
          <h4 className=" font-medium text-2xl text-black">Trees & Decorations</h4>
        </div>
        <div className="   pt-4 gap-6  items-center">
          {allCategory?.length > 0 ? (
            <ProductSlider top={20}
              items={allCategory.map((item, index) => {
                return (
                  <>
                    <CategoryCard
                      key={index}
                      url={`/categories/${item?.id}`}
                      image={item?.image}
                      name={item?.name}
                    />
                  </>
                );
              })}
            />
          ) : (
            <p>Loading products...</p>
          )}
        </div>
      </div>

      <div className="   my-8 py-3 mx-auto">
        <div className=" flex  items-center justify-between">
          <h4 className=" font-medium text-2xl text-black">Food & Entertaining</h4>
        </div>
        <div className="   pt-4 gap-6  items-center">
          {allCategory?.length > 0 ? (
            <ProductSlider top={20}
              items={allCategory.map((item, index) => {
                return (
                  <>
                    <CategoryCard
                      key={index}
                      url={`/categories/${item?.id}`}
                      image={item?.image}
                      name={item?.name}
                    />
                  </>
                );
              })}
            />
          ) : (
            <p>Loading products...</p>
          )}
        </div>
      </div>
      <div className="   my-8 py-3 mx-auto">
        <div className=" flex  items-center justify-between">
          <h4 className=" font-medium text-2xl text-black">Clothing & Accessories</h4>
        </div>
        <div className="   pt-4 gap-6  items-center">
          {allCategory?.length > 0 ? (
            <ProductSlider top={20}
              items={allCategory.map((item, index) => {
                return (
                  <>
                    <CategoryCard
                      key={index}
                      url={`/categories/${item?.id}`}
                      image={item?.image}
                      name={item?.name}
                    />
                  </>
                );
              })}
            />
          ) : (
            <p>Loading products...</p>
          )}
        </div>
      </div>

      <div className=' py-10'>

        <h1 className=' text-center pb-6 text-3xl'>Christmas Gift Ideas</h1>
        <div className="grid grid-cols-1  sm:grid-cols-2 md:grid-cols-3 gap-6">
        {data.map((catalogue, index) => (
          <div
            key={index}
            className="bg-white  overflow-hidden "
          >
            <img
              src={catalogue.image}
              alt={catalogue.title}
              className="w-full  object-cover"
            />
            <div className="p-4">
              <h2 className="text-lg font-semibold text-gray-800">
                {catalogue.title}
              </h2>
              
            </div>
          </div>
        ))}
      </div>
      </div>


      <div className="   py-10 mx-auto">
      <div className="  items-center  text-center pb-4 justify-between">
        <h4 className=" text-black font-medium text-3xl">Christmas Collections</h4>
        <p className=' pt-3'>Find your holiday season style and be inspired with our fresh, new Christmas themes.

</p>
      </div>

      <div className="grid mt-4 item1 col-span-2  xl:grid-cols-2  grid-cols-1 gap-5">
        <div className=" relative">
          <img src={'https://s7ap1.scene7.com/is/image/bigw/W2620%20-%20Christmas_HBP1_NostalgicNoel?$cms-max-image-threshold$&fmt=webp-alpha&wid=538&fit=hfit%2C1&scale=0.5&qlt=88'} className=' w-full' alt="" />

          <div className="  p-3 flex  flex-col justify-between items-center">

            <h4 className=' text-xl pt-3'>Nostalgic Noel</h4>
            <p className=" text-md text-center py-3">
            Classic Christmas charm with a painterly twist, offering a delightful mix of traditional and modern festive decor to create a heartwarming holiday ambiance.


            </p>

          
            
          </div>
        </div>

        <div className=" relative">
          <img src={'https://s7ap1.scene7.com/is/image/bigw/W2620%20-%20Christmas_HBP2_jollyHolly?$cms-max-image-threshold$&fmt=webp-alpha&wid=538&fit=hfit%2C1&scale=0.5&qlt=88'} className=' w-full' alt="" />

          <div className="  p-3 flex  flex-col justify-between items-center">

            <h4 className=' text-xl pt-3'>Jolly Holly</h4>
            <p className=" text-md text-center py-3">
            Playful, pastel-bright Christmas collection inspired by '90s nostalgia featuring quirky food-themed decorations for a fun candyland-like Christmas experience.



            </p>

          
            
          </div>
        </div>

        <div className=" relative">
          <img src={'https://s7ap1.scene7.com/is/image/bigw/W2620%20-%20Christmas_HBP1_NostalgicNoel?$cms-max-image-threshold$&fmt=webp-alpha&wid=538&fit=hfit%2C1&scale=0.5&qlt=88'} className=' w-full' alt="" />

          <div className="  p-3 flex  flex-col justify-between items-center">

            <h4 className=' text-xl pt-3'>Nostalgic Noel</h4>
            <p className=" text-md text-center py-3">
            Classic Christmas charm with a painterly twist, offering a delightful mix of traditional and modern festive decor to create a heartwarming holiday ambiance.


            </p>

          
            
          </div>
        </div>
        <div className=" relative">
          <img src={'https://s7ap1.scene7.com/is/image/bigw/W2620%20-%20Christmas_HBP1_NostalgicNoel?$cms-max-image-threshold$&fmt=webp-alpha&wid=538&fit=hfit%2C1&scale=0.5&qlt=88'} className=' w-full' alt="" />

          <div className="  p-3 flex  flex-col justify-between items-center">

            <h4 className=' text-xl pt-3'>Nostalgic Noel</h4>
            <p className=" text-md text-center py-3">
            Classic Christmas charm with a painterly twist, offering a delightful mix of traditional and modern festive decor to create a heartwarming holiday ambiance.


            </p>

          
            
          </div>
        </div>
        

       
      </div>
    </div>
      
    </div>
      </section>

      

     <Footer/>
    </>
  )
}

export default Christmas