import React, { useState } from "react";
import Navbar from "../../components/Header/Navbar";
import BottomHeader from "../../components/Header/BottomHeader";
import Footer from "../../components/footer";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { Base_url } from "../../utils/Base_url";

const ContactUs = () => {



  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      fullName: "",
      email: "",
      phone: "",
      comment: "",
    },
    validationSchema: Yup.object({
      fullName: Yup.string().required("Full name is required"),
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
      phone: Yup.string()
        .matches(/^[0-9]{10}$/, "Phone number must be 10 digits")
        .required("Phone number is required"),
      comment: Yup.string().required("Comment is required"),
    }),
    onSubmit: async (values) => {
      setLoading(true);

      try {
        
        const response = await fetch(`${Base_url}/contact/create`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(values),
        });
        if (response.ok) {
          toast.success("Message sent successfully!");
          formik.resetForm();
          setLoading(false);
        } else {
          alert("Failed to send message. Please try again.");
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        console.error("Error:", error);
        alert("An error occurred. Please try again.");
      }
    },
  });


  return (
    <>
    <Navbar/>
    <BottomHeader/>
    <section className="bg-white ">
  <div className="container px-6 py-12 mx-auto">
    <div className="text-center">
      <p className="font-medium text-blue-500 :text-blue-400">Contact us</p>
      <h1 className="mt-2 text-2xl font-semibold text-gray-800 md:text-3xl :text-white">
        Get in touch
      </h1>
      <p className="mt-3 text-gray-500 :text-gray-400">
        Our friendly team is always here to chat.
      </p>
    </div>
    <div className="grid grid-cols-1 gap-12 mt-10 md:grid-cols-2 lg:grid-cols-3">
      <div className="flex flex-col items-center justify-center text-center">
        <span className="p-3 text-blue-500 rounded-full bg-blue-100/80 :bg-gray-800">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
            />
          </svg>
        </span>
        <h2 className="mt-4 text-lg font-medium text-gray-800 :text-white">
          Email
        </h2>
        <p className="mt-2 text-gray-500 :text-gray-400">
          Our friendly team is here to help.
        </p>
        <p className="mt-2 text-blue-500 :text-blue-400">
          hello@merakiui.com
        </p>
      </div>
      <div className="flex flex-col items-center justify-center text-center">
        <span className="p-3 text-blue-500 rounded-full bg-blue-100/80 :bg-gray-800">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
            />
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
            />
          </svg>
        </span>
        <h2 className="mt-4 text-lg font-medium text-gray-800 :text-white">
          Office
        </h2>
        <p className="mt-2 text-gray-500 :text-gray-400">
          Come say hello at our office HQ.
        </p>
        <p className="mt-2 text-blue-500 :text-blue-400">
          100 Smith Street Collingwood VIC 3066 AU
        </p>
      </div>
      <div className="flex flex-col items-center justify-center text-center">
        <span className="p-3 text-blue-500 rounded-full bg-blue-100/80 :bg-gray-800">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
            />
          </svg>
        </span>
        <h2 className="mt-4 text-lg font-medium text-gray-800 :text-white">
          Phone
        </h2>
        <p className="mt-2 text-gray-500 :text-gray-400">
          Mon-Fri from 8am to 5pm.
        </p>
        <p className="mt-2 text-blue-500 :text-blue-400">
          +1 (555) 000-0000
        </p>
      </div>
    </div>
  </div>
</section>

   

<section className="bg-white ">
  <div className="py-8 lg:py-16 px-4 mx-auto max-w-screen-md">
    <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-center text-gray-900 :text-white">
      Contact Us
    </h2>
    <p className="mb-8 lg:mb-16 font-light text-center text-gray-500 :text-gray-400 sm:text-xl">
      Got a technical issue? Want to send feedback about a beta feature? Need
      details about our Business plan? Let us know.
    </p>
    <form onSubmit={formik.handleSubmit} className="space-y-8">
      <div>
        <label
          htmlFor="fullName"
          className="block mb-2 text-sm font-medium text-gray-900 :text-gray-300"
        >
          Full Name
        </label>
        <input
          type="text"
          id="fullName"
          name="fullName"
          className={`shadow-sm  border mb-2 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 :bg-gray-700 :border-gray-600 :placeholder-gray-400 :text-white :focus:ring-primary-500 :focus:border-primary-500 ${
            formik.touched.fullName && formik.errors.fullName ? "border-red" : ""
          }`}
          placeholder="Your full name"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.fullName}
        />
        {formik.touched.fullName && formik.errors.fullName && (
          <p className="text-red text-sm">{formik.errors.fullName}</p>
        )}
      </div>

      <div>
        <label
          htmlFor="email"
          className="block mb-2 text-sm font-medium text-gray-900 :text-gray-300"
        >
          Your Email
        </label>
        <input
          type="email"
          id="email"
          name="email"
          className={`shadow-sm mb-2  border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 :bg-gray-700 :border-gray-600 :placeholder-gray-400 :text-white :focus:ring-primary-500 :focus:border-primary-500 ${
            formik.touched.email && formik.errors.email ? "border-red" : ""
          }`}
          placeholder="name@domain.com"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.email}
        />
        {formik.touched.email && formik.errors.email && (
          <p className="text-red text-sm">{formik.errors.email}</p>
        )}
      </div>

      <div>
        <label
          htmlFor="phone"
          className="block mb-2 text-sm font-medium text-gray-900 :text-gray-300"
        >
          Phone Number
        </label>
        <input
          type="text"
          id="phone"
          name="phone"
          className={`shadow-sm border mb-2 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 :bg-gray-700 :border-gray-600 :placeholder-gray-400 :text-white :focus:ring-primary-500 :focus:border-primary-500 ${
            formik.touched.phone && formik.errors.phone ? "border-red" : ""
          }`}
          placeholder="1234567890"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.phone}
        />
        {formik.touched.phone && formik.errors.phone && (
          <p className="text-red text-sm">{formik.errors.phone}</p>
        )}
      </div>

      <div className="sm:col-span-2">
        <label
          htmlFor="comment"
          className="block mb-2 text-sm font-medium text-gray-900 :text-gray-400"
        >
          Your Message
        </label>
        <textarea
          id="comment"
          name="comment"
          rows={6}
          className={`block p-2.5 w-full text-sm mb-2 text-gray-900  rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500 :bg-gray-700 :border-gray-600 :placeholder-gray-400 :text-white :focus:ring-primary-500 :focus:border-primary-500 ${
            formik.touched.comment && formik.errors.comment ? "border-red" : ""
          }`}
          placeholder="Leave a comment..."
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.comment}
        />
        {formik.touched.comment && formik.errors.comment && (
          <p className="text-red text-sm">{formik.errors.comment}</p>
        )}
      </div>

      <button
        type="submit"
        className={`py-3 px-5 text-sm font-medium text-center text-white rounded-lg bg-primary sm:w-fit focus:ring-4 focus:outline-none focus:ring-primary-300 :bg-primary-600 :hover:bg-primary-700 :focus:ring-primary-800 ${
          loading ? "cursor-not-allowed opacity-70" : "hover:bg-primary-800"
        }`}
        disabled={loading}
      >
        {loading ? (
          <span className="flex items-center">
            <svg
              className="w-5 h-5 mr-2 text-white animate-spin"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8v8H4z"
              ></path>
            </svg>
            Loading...
          </span>
        ) : (
          "Send Message"
        )}
      </button>
    </form>
  </div>
</section>


    <Footer/>
    </>
    
  );
};

export default ContactUs;
