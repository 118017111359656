import React, { useEffect, useState } from "react";
import Input from "../../../components/Input";
import Button from "../../../components/Button";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { auth, provider } from "../../../utils/firebase.config";
import { signInWithPopup } from "firebase/auth";
import { FcGoogle } from "react-icons/fc";
import { toast } from "react-toastify";
import { Base_url } from "../../../utils/Base_url";
import { useDispatch } from "react-redux";
import Navbar from "../../../components/Header/Navbar";
import Footer from "../../../components/footer";
import BottomHeader from "../../../components/Header/BottomHeader";
import { setUser } from "../../../store/authSlice";
const Register = () => {

  const location = useLocation();
  const phone = location.state?.phone;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [value, setValue] = useState();
  const handleClick = () => {
    signInWithPopup(auth, provider).then((data) => {

      console.log(data);
      
      const { user } = data;

      setLoading(true);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        firstName: user.displayName.split(" ")[0],
        lastName: user.displayName.split(" ").slice(-1)[0],
        username:user.displayName,
        email: user.email,
        phone:12345,
        password: user.uid,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(`${Base_url}/user/create`, requestOptions)
        .then((response) => response.text())
        .then((result) => {
          console.log(result);
          let data = JSON.parse(result);
          console.log("resposne from signup-----", data);

          if (data.status === "ok") {
            toast.success("Register successfully!");
            setLoading(false);
            navigate("/login");
          } else {
            toast.error(data.message);
            setLoading(false);
          }
        })
        .catch((error) => {
          setLoading(false);
        });
    });
  };

  const [loading, setLoading] = useState(false);
  console.log(value);
  useEffect(() => {
    setValue(localStorage.getItem("email"));
  });

  const SubmitHandler = async (values) => {
    if (values.password.value.length === 0) {
      toast.error("Please enter your password!");
    } else {
      setLoading(true);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        firstName: values.firstName.value,
        phone: values.phone.value,
        lastName: values.lastName.value,
        username: values.username.value,
        email: values.email.value,
        phone: values.phone.value,
        password: values.password.value,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(`${Base_url}/user/create`, requestOptions)
        .then((response) => response.text())
        .then((result) => {
          console.log(result);
          let data = JSON.parse(result);
          console.log("resposne from signup-----", data);

          if (data.status === "ok") {
            toast.success("Register successfully!");
            setLoading(false);
            localStorage.setItem("user_ID", data?.data?.id);
            dispatch(setUser(data?.data));
            navigate("/");
          } else {
            toast.error(data.message);
            setLoading(false);
          }
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  };

  return (
    <>
      <div>
        <Navbar />
        <BottomHeader />

        <div className=" rounded-lg max-w-md mx-auto my-8 shadow-xl text-center">
          <div className=" py-10 px-8">
            <h1 className="h2  font-medium">Create an Account</h1>
            <p className=" pt-4  pb-8 text-left">
              By creating an account on Shop Zone you'll be able to buy, sell,
              comment, and more.
            </p>
{/* 
            <Button
              onClick={() => handleClick()}
              Icons={<FcGoogle size={20} className="mr-2" />}
              label={"Continue with Google"}
              className={
                " border    rounded-sm mx-auto w-full text-md    text-gray-400 py-2"
              }
            />
            <hr className=" my-5" /> */}

            <form
              onSubmit={(e) => {
                e.preventDefault();
                SubmitHandler(e.target);
              }}
            >
              <div className=" text-left mt-5">
                <label className=" text-left font-semibold mb-3">
                  First Name
                </label>
                <Input
                  name={"firstName"}
                  placeholder={"Enter First Name"}
                  className={"border w-full rounded-sm py-2"}
                />
              </div>

              <div className=" text-left mt-5">
                <label className=" text-left font-semibold mb-3">
                  Last Name
                </label>
                <Input
                  name={"lastName"}
                  placeholder={"Enter First Name"}
                  className={"border w-full rounded-sm py-2"}
                />
              </div>
              <div className=" text-left mt-5">
                <label className=" text-left font-semibold mb-3">
                  Username
                </label>
                <Input
                  name={"username"}
                  placeholder={"Enter username"}
                  className={"border w-full rounded-sm py-2"}
                />
              </div>
              <div className=" text-left mt-5">
                <label className=" text-left font-semibold mb-3">
                  Email Address
                </label>
                <Input
                  name={"email"}
                  type={"email"}
                  placeholder={"Enter Email"}
                  className={"border w-full rounded-sm py-2"}
                />
              </div>

              <div className=" text-left mt-5">
                <label className=" text-left font-semibold mb-3">Phone</label>
                <Input
                  name={"phone"}
                  type={"text"}
                  placeholder={"Enter Phone"}
                  className={"border w-full rounded-sm py-2"}
                  defaultValue={phone}
                />
              </div>

              <div className=" text-left mt-5">
                <label className=" text-left font-semibold mb-3">
                  Password
                </label>
                <Input
                  name={"password"}
                  placeholder={"Enter Password"}
                  className={"border w-full rounded-sm py-2"}
                />
              </div>

              <button
                type="submit"
                disabled={loading}
                className={`uppercase border w-full py-2 mt-5 text-white text-sm ${
                  loading ? "bg-gray-500 cursor-not-allowed" : "bg-black"
                }`}
              >
                {loading ? (
                  <div className="flex items-center justify-center">
                    <svg
                      className="animate-spin h-5 w-5 mr-2 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                    Signing up...
                  </div>
                ) : (
                  "Sign up"
                )}
              </button>
            </form>

            <div>
              {/* <p className=" text-left mt-3">
                Already have an accouunt?
                <Link to={"/login"} className=" border-b">
                  Log in
                </Link>
              </p>
              <p className=" text-sm mt-7">
                By creating an account, I accept Shop Zone
                <span className=" text-black font-semibold">
                  Terms of Service.
                </span>
                For Shop Zone Privacy Policy,
                <span className="text-black font-semibold">click here.</span>
              </p> */}
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};

export default Register;
