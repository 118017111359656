import axios from 'axios';
import React, { useState } from 'react'
import { Base_url } from '../utils/Base_url';
import { toast } from 'react-toastify';

const Subscribe = () => {

 const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!email.trim() || !/\S+@\S+\.\S+/.test(email)) {
      toast.error("Please enter a valid email address.");
      return;
    }

    setIsLoading(true);

    try {
      const response = await axios.post(`${Base_url}/subscribe/create`, {
        email,
      });

      if (response.status === 200) {
        toast.success("Subscription successful! Thank you.");
        setEmail("");
      } else {
        toast.error("Subscription failed. Please try again.");
      }
    } catch (error) {
      toast.error(
        error.response?.data?.message || "An error occurred. Please try again."
      );
    } finally {
      setIsLoading(false);
    }
  };


  return (
      
    <div className="flex sm:flex-row flex-col gap-6   items-center justify-center bg-gray-50 py-10 border-t border-gray-200">
    <div>
    <h2 className="text-xl font-medium text-gray-800">
      Join the Shop Zone 
      <span className="font-bold italic"> Inner Circle</span>
    </h2>
    <p className="text-sm text-black mt-1">
      and get $10 AUD off your first online order.*
    </p>
    </div>

    <form  onSubmit={handleSubmit} className=' flex gap-2'>
    <input
                                type="email"
                                className=" border placeholder:text-black border-[#161C28] bg-transparent    w-full text-[#161C28] px-4 py-2 rounded"    placeholder="Email"
                                name="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                style={{
                                  backgroundImage: 'url("data:image/png',
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: 20,
                                  backgroundPosition: "97% center",
                                  cursor: "auto",
                                }}
                                data-temp-mail-org={0}
                                required
                              />
                              <button type='submit' className=" border border-[#161C28]  font-medium w-32 text-[#161C28] px-4 py-2 rounded hover:bg-[#161C28] hover:text-white">
      Subscribe
    </button>
    </form>
    
    
  </div>
  )
}

export default Subscribe