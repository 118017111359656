import React, { useEffect, useRef, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { Base_url } from "../../../utils/Base_url";
import { toast } from "react-toastify";
import Modal from "../../../components/modal";
import { MdClose } from "react-icons/md";
import Button from "../../../components/Button";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";

const EditAddress = ({ isModalOpen, setIsModalOpen, closeModal,getData,setAddress }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const storedUser = localStorage.getItem("user_ID");


  console.log(getData);
  

  // Google Places API Key
  const googleMapsApiKey = "AIzaSyB2I4n7I5XDIpt1Xo03y7gXVQVK9safwd0";

  // Validation Schema
  const validationSchema = Yup.object({
    // firstName: Yup.string().required("First name is required"),
    // lastName: Yup.string().required("Last name is required"),
    // addressLine1: Yup.string().required("Address is required"),
    // city: Yup.string().required("City is required"),
    // phone: Yup.string().required("Phone is required"),
    // state: Yup.string().required("State is required"),
    // zipCode: Yup.string()
    //   .matches(/^\d{5}$/, "ZIP code must be 5 digits")
    //   .required("ZIP code is required"),
    // country: Yup.string().required("Country is required"),
  });

   // Formik Initial Values
   const initialValues = getData ? {
    firstName: getData.firstName || "",
    lastName: getData.lastName || "",
    businessName: getData.businessName || "",
    // addressLine1: getData.addressLine1 || "",
    addressLine2: getData.addressLine2 || "",
    phone: getData.phone || "",
    // city: getData.city || "",
    // state: getData.state || "",
    // unit: getData.unit || "",
    // country: getData.country || "",
    comment:getData.comment || "",
    default: getData.default || null,
  } : {
    firstName: "",
    lastName: "",
    businessName: "",
    // addressLine1: "",
    addressLine2: "",
    phone: "",
    // city: "",
    // state: "",
    // unit: "",
    comment:"",
    // country: "",
    default: null,
  };


 
const [address3, setAddress3] = useState("");
  
  const [city, setCity] = useState(getData?.city);
  const [state, setState] = useState(getData?.state);
  const [country, setCountry] = useState(getData?.country);
  const [unitNumber, setUnitNumber] = useState(getData?.unit);
 console.log(address3,'address');


 useEffect(() => {
  console.log(getData);
  setAddress3(getData?.addressLine1);
  setCity(getData?.city);
  setState(getData?.state);
  setCountry(getData?.country);
  setUnitNumber(getData?.unit);
},[getData])

 
  // Submit Handler
  const onSubmit = async (values, { resetForm }) => {
    const params = {
      userId: storedUser,
      addressLine1: address3,
      ...values,
      city:city,
      country:country,
      unit:unitNumber,
      state:state
    };

    setIsLoading(true);
    try {
      const res = await axios.put(`${Base_url}/address/update/${getData?.id}`, params);
      console.log(res);
      
      if (res.status === 200) {
        setIsModalOpen(false);
        resetForm();
        toast.success("Address update successfully!");
        axios
        .get(`${Base_url}/address/user/${storedUser}`)
        .then((res) => {
          console.log(res, "address");
  
          setAddress(res?.data?.data);
        })
        .catch((error) => {
          console.log(error);
        });
      } else {
        setErrorMessage("Failed to save the address. Please try again.");
      }
    } catch (error) {
      setErrorMessage("An error occurred while saving the address.");
    } finally {
      setIsLoading(false);
    }
  };

  
    const autocompleteRef = useRef(null);
  
    const loadGoogleMaps = () => {
      if (!window.google) {
        const script = document.createElement("script");
        script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyA0ezzOFq6lTHs4i4DlmMPJpV48LAWMx7o&libraries=places`;
        script.async = true;
        script.defer = true;
        script.onload = initializeAutocomplete;
        document.body.appendChild(script);
      } else {
        initializeAutocomplete();
      }
    };
  
    
    const initializeAutocomplete = () => {
      if (!window.google || !document.getElementById("autocomplete")) return;
  
      autocompleteRef.current = new window.google.maps.places.Autocomplete(
        document.getElementById("autocomplete"),
        { types: ["geocode"] }
      );
  
  
      console.log(document.getElementById("autocomplete"));
      
  
      autocompleteRef.current.addListener("place_changed", handlePlaceSelect);
    };
  
    
    const handlePlaceSelect = () => {
      const place = autocompleteRef.current.getPlace();
      if (!place || !place.address_components) return;
  
      let cityName = "";
      let stateName = "";
      let countryName = "";
      let unit = "";
  
      place.address_components.forEach((component) => {
        const types = component.types;
        if (types.includes("locality")) cityName = component.long_name;
        if (types.includes("administrative_area_level_1")) stateName = component.long_name;
        if (types.includes("country")) countryName = component.long_name;
        if (types.includes("subpremise")) unit = component.long_name;
      });
  
      const formattedAddress = place.formatted_address || "";
      if (!unit) unit = extractUnitNumber(formattedAddress);
  
      // Update state with the selected address
      setAddress3(formattedAddress);
      setCity(cityName);
      setState(stateName);
      setCountry(countryName);
      setUnitNumber(unit);
    };
  
    const extractUnitNumber = (formattedAddress) => {
      const addressParts = formattedAddress.split(",");
      const firstPart = addressParts[0]?.trim();
      const unitMatch = firstPart.match(/^(\d+)[\s\-]/);
      return unitMatch ? unitMatch[1] : "N/A";
    };
  
    useEffect(() => {
      
  
  
  
      loadGoogleMaps();
  
      return () => {
        if (autocompleteRef.current) {
          window.google.maps.event.clearInstanceListeners(autocompleteRef.current);
        }
      };
    }, [loadGoogleMaps]);
    
  

  return (
    <>
      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        className="max-w-xl"
      >
        <div>
          <div className="p-3 flex justify-between items-center">
            <div></div>
            <h1 className="capitalize h4">Edit Address</h1>
            <MdClose
              className="cursor-pointer"
              size={25}
              onClick={() => setIsModalOpen(false)}
            />
          </div>
          <hr />
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ isSubmitting, setFieldValue,values }) => (
              <Form className="p-5">
                 <div className="w-full pb-3">
                    <label htmlFor="defaultAddress">Default Address</label>
                    <div className="flex gap-2 pt-3">
                      <div className="flex gap-2">
                        <p className="text-center text-sm">Yes</p>
                        <input
                          type="radio"
                          name="default"
                          checked={values.default === true}
                          onChange={() => setFieldValue("default", true)}
                          className="w-4 h-4 mx-auto"
                        />
                      </div>
                      <div className="flex gap-2">
                        <p className="text-center text-sm">No</p>
                        <input
                          type="radio"
                          name="default"
                          checked={values.default === false}
                          onChange={() => setFieldValue("default", false)}
                          className="w-4 h-4 mx-auto"
                          
                        />
                      </div>
                    </div>
                  </div>
                <div className="flex gap-5 flex-wrap">
                  {/* Name */}
                  <div className="w-full">
                   
                   <div className="w-full  mx-auto">
                     <label htmlFor="addressLine1">Address</label>
                     <input
                       id="autocomplete"
                       className="border p-2 w-full rounded"
                       placeholder="Enter an address"
                       defaultValue={getData?.addressLine1}
                     />
                   
                   </div>
                 </div>

                  <div className="sm:w-[48%] w-full">
                    <label htmlFor="firstName">First Name</label>
                    <Field
                      id="firstName"
                      name="firstName"
                      placeholder="Enter First Name"
                      className="border rounded-sm w-full p-3"
                    />
                    <ErrorMessage
                      name="firstName"
                      component="p"
                      className="text-red text-sm font-semibold"
                    />
                  </div>

                  <div className="sm:w-[48%] w-full">
                    <label htmlFor="lastName">Last Name</label>
                    <Field
                      id="lastName"
                      name="lastName"
                      placeholder="Enter Last Name"
                      className="border rounded-sm w-full p-3"
                    />
                    <ErrorMessage
                      name="lastName"
                      component="p"
                      className="text-red text-sm font-semibold"
                    />
                  </div>

                  <div className="w-full">
                    <label htmlFor="businessName">Business Name</label>
                    <Field
                      id="businessName"
                      name="businessName"
                      placeholder="Enter Business Name"
                      className="border rounded-sm w-full p-3"
                    />
                    <ErrorMessage
                      name="businessName"
                      component="p"
                      className="text-red text-sm font-semibold"
                    />
                  </div>

                  <div className="w-full">
                    <label htmlFor="phone">Phone</label>
                    <Field
                      id="phone"
                      name="phone"
                      placeholder="Enter Phone"
                      className="border rounded-sm w-full p-3"
                      disabled
                    />
                    <ErrorMessage
                      name="phone"
                      component="p"
                      className="text-red text-sm font-semibold"
                    />
                  </div>

                  {/* Google Places Autocomplete */}
                 

                  {/* Address Line 2 */}
                  {/* <div className="w-full">
                    <label htmlFor="addressLine2">Address Line 2</label>
                    <GooglePlacesAutocomplete
                      apiKey={googleMapsApiKey}
                      selectProps={{
                        placeholder: "Search your address",
                        onChange: (address) =>
                          handleAddress2Select(address, setFieldValue),
                        styles: {
                          control: (provided) => ({
                            ...provided,
                            border: "1px solid #d1d5db",
                            borderRadius: "0.375rem",
                            padding: "0.5rem",
                          }),
                        },
                      }}
                    />
                    <ErrorMessage
                      name="addressLine2"
                      component="p"
                      className="text-red text-sm font-semibold"
                    />
                  </div> */}

                  {/* City */}
                  <div className="w-[60%]">
                    <label htmlFor="city">City</label>
                    <Field
                      id="city"
                      name="city"
                      placeholder="Enter City"
                      onChange={(e) => setCity(e.target.value)}
                      className="border rounded-sm w-full p-3"
                      value={city || getData?.city || ""}
                    />
                    <ErrorMessage
                      name="city"
                      component="p"
                      className="text-red text-sm font-semibold"
                    />
                  </div>

                  {/* State */}
                  <div className="w-[36%]">
                    <label htmlFor="state">State</label>
                    <Field
                      id="state"
                      name="state"
                      placeholder="Enter State"
                      onChange={(e) => setState(e.target.value)}
                      className="border rounded-sm w-full p-3"
                      value={state || getData?.state || ""}
                    />
                    <ErrorMessage
                      name="state"
                      component="p"
                      className="text-red text-sm font-semibold"
                    />
                  </div>

                  {/* Country */}
                  <div className="w-[60%]">
                    <label htmlFor="country">Country</label>
                    <Field
                      id="country"
                      name="country"
                      placeholder="Enter Country"
                      onChange={(e) => setCountry(e.target.value)}
                      className="border rounded-sm w-full p-3"
                      value={country || getData?.country || ""}
                    />
                    <ErrorMessage
                      name="country"
                      component="p"
                      className="text-red text-sm font-semibold"
                    />
                  </div>

                  {/* ZIP Code */}
                  <div className="w-[36%]">
                    <label htmlFor="unit">Unit Number</label>
                    <Field
                      id="zipCode"
                      name="unit"
                      placeholder="Enter Unit Number"
                      onChange={(e) => setUnitNumber(e.target.value)}
                      className="border rounded-sm w-full p-3"
                      value={unitNumber || getData?.unit || ""}
                    />
                    <ErrorMessage
                      name="unit"
                      component="p"
                      className="text-red text-sm font-semibold"
                    />
                  </div>
                      <div className=" w-full">
                                      <label htmlFor="comment">Comment</label>
                                      <Field
                                           as="textarea"
                  
                                        id="comment"
                                        name="comment"
                                        placeholder="comment"
                                        className="border rounded-sm w-full p-3"
                                      />
                                     
                                    </div>
                </div>

                {/* Error Message */}
                {errorMessage && (
                  <p className="text-red-600 mt-4">{errorMessage}</p>
                )}

                {/* Save Button */}
                <Button
                  type="submit"
                  label={isLoading ? "Saving..." : "Update Address"}
                  disabled={isSubmitting || isLoading}
                  className={`bg-black uppercase mt-4 text-white py-2 w-full ${
                    isSubmitting || isLoading
                      ? "opacity-50 cursor-not-allowed"
                      : ""
                  }`}
                />
              </Form>
            )}
          </Formik>
        </div>
      </Modal>
    </>
  );
};

export default EditAddress;
