import React from "react";
import { MdClose } from "react-icons/md";
import Modal from "../../components/modal"; // Ensure this is your custom modal component

const PayPalModal = ({ isModalOpen, setIsModalOpen }) => {
  return (
    <Modal
      isOpen={isModalOpen}
      onClose={() => setIsModalOpen(false)}
      className="max-w-md rounded-lg"
    >
      <div className="bg-white rounded-lg shadow-lg">
        {/* Header with close button */}
        <div className="p-4 flex justify-between items-center border-b border-gray-200">
          <h2 className="text-xl font-semibold text-gray-900">
            Pay in 4 interest-free payments
          </h2>
          <MdClose
            className="cursor-pointer text-gray-600 hover:text-gray-800"
            size={24}
            onClick={() => setIsModalOpen(false)}
          />
        </div>

        {/* Main content */}
        <div className=" pb-6 ">
          {/* Subtitle */}
          <div className=" px-4">
          <p className="text-gray-600 text-sm mt-4 mb-6">
            Available for purchases of $30 to $2,000. No sign-up fees or late fees.
          </p>

          </div>
          {/* Payment schedule with circular progress bars */}
          <div className="flex justify-between px-4">
            {/* Today */}
            <div className="text-center">
              <div className="relative w-16 h-16">
                <svg
                  className="w-full h-full"
                  viewBox="0 0 36 36"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  {/* Background circle */}
                  <circle
                    cx="18"
                    cy="18"
                    r="15.9155"
                    fill="none"
                    className="stroke-gray-200"
                    strokeWidth="2"
                  />
                  {/* Progress circle */}
                  <circle
                    cx="18"
                    cy="18"
                    r="15.9155"
                    fill="none"
                    className="stroke-blue-500"
                    strokeWidth="2"
                    strokeDasharray="100"
                    strokeDashoffset="75" // 25% progress
                  />
                </svg>
                <span className="absolute inset-0 flex items-center justify-center text-sm font-semibold text-gray-900">
                  25%
                </span>
              </div>
              <p className="font-semibold text-gray-900 mt-2">Today</p>
            </div>

            {/* 2 weeks */}
            <div className="text-center">
              <div className="relative w-16 h-16">
                <svg
                  className="w-full h-full"
                  viewBox="0 0 36 36"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  {/* Background circle */}
                  <circle
                    cx="18"
                    cy="18"
                    r="15.9155"
                    fill="none"
                    className="stroke-gray-200"
                    strokeWidth="2"
                  />
                  {/* Progress circle */}
                  <circle
                    cx="18"
                    cy="18"
                    r="15.9155"
                    fill="none"
                    className="stroke-blue-500"
                    strokeWidth="2"
                    strokeDasharray="100"
                    strokeDashoffset="75" // 25% progress
                  />
                </svg>
                <span className="absolute inset-0 flex items-center justify-center text-sm font-semibold text-gray-900">
                  25%
                </span>
              </div>
              <p className="font-semibold text-gray-900 mt-2">2 weeks</p>
            </div>

            {/* 4 weeks */}
            <div className="text-center">
              <div className="relative w-16 h-16">
                <svg
                  className="w-full h-full"
                  viewBox="0 0 36 36"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  {/* Background circle */}
                  <circle
                    cx="18"
                    cy="18"
                    r="15.9155"
                    fill="none"
                    className="stroke-gray-200"
                    strokeWidth="2"
                  />
                  {/* Progress circle */}
                  <circle
                    cx="18"
                    cy="18"
                    r="15.9155"
                    fill="none"
                    className="stroke-blue-500"
                    strokeWidth="2"
                    strokeDasharray="100"
                    strokeDashoffset="100" // 0% progress
                  />
                </svg>
                <span className="absolute inset-0 flex items-center justify-center text-sm font-semibold text-gray-900">
                  0%
                </span>
              </div>
              <p className="font-semibold text-gray-900 mt-2">4 weeks</p>
            </div>

            {/* 6 weeks */}
            <div className="text-center">
              <div className="relative w-16 h-16">
                <svg
                  className="w-full h-full"
                  viewBox="0 0 36 36"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  {/* Background circle */}
                  <circle
                    cx="18"
                    cy="18"
                    r="15.9155"
                    fill="none"
                    className="stroke-gray-200"
                    strokeWidth="2"
                  />
                  {/* Progress circle */}
                  <circle
                    cx="18"
                    cy="18"
                    r="15.9155"
                    fill="none"
                    className="stroke-blue-500"
                    strokeWidth="2"
                    strokeDasharray="100"
                    strokeDashoffset="100" // 0% progress
                  />
                </svg>
                <span className="absolute inset-0 flex items-center justify-center text-sm font-semibold text-gray-900">
                  0%
                </span>
              </div>
              <p className="font-semibold text-gray-900 mt-2">6 weeks</p>
            </div>
          </div>

          {/* How it works section */}
          <div className=" p-4" >
            <h3 className="font-semibold text-gray-900 mb-3">How it works:</h3>
            <ol className="list-decimal pl-5 space-y-2">
              <li className="text-gray-600 text-sm">
                Choose PayPal at checkout to pay later with Pay in 4.
              </li>
              <li className="text-gray-600 text-sm">
                Complete your purchase with an initial 25% payment.
              </li>
              <li className="text-gray-600 text-sm">
                Remaining payments are taken automatically.
              </li>
            </ol>
          </div>

          {/* Footer note */}
          <div className=" bg-gray-100 p-4">
          <p className="text-xs text-gray-500 italic">PayPal Pay in 4 is for eligible purchases between $30–$2,000 AUD. It’s available to Australian residents with an Australian PayPal account. Eligibility is subject to your PayPal account status and a credit assessment, which may require a credit check. Pay in 4 is a continuing credit contract offered under thePayPal Pay in 4 Facility Agreementthat may allow you up to $2,000 in credit for eligible purchases, so consider whether you can afford the repayments as use of the product may impact your credit score. Please read theTMDbefore deciding to use PayPal Pay in 4.</p>

          </div>
        </div>
      </div>
    </Modal>
  );
};

export default PayPalModal;